/* eslint-disable no-useless-escape */

export const expressions = {
  islowercase: /(?=.*[a-z])/,
  isuppercase: /(?=.*[A-Z])/,
  isnumber: /(?=.*[0-9])/,
  issymbol: /(?=.*[$@$!%*?&#.$($)$-$_])/,
  limit: /^.{8,}$/,
  number: /^[0-9]{1,100}$/,
  text: /^[\s\S]{1,1500}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,100}$/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  user: /^[A-Za-zÀ-ÖØ-öø-ÿĀ-ž0-9\s]{1,100}$/,
  name: /^[A-Za-zÀ-ÖØ-öø-ÿĀ-ž0-9\s\'\.\-\,\#\°\?\s\`\~\!\¡\@\#\$\%\^\&\*\(\)\_\-\+\=\{\}\[\]\|\/\:\;\'\"\\\<\>\?\¿\v\°\?\¿!@#$%^&*()+=[\]{};:'"<>|\\/~_\/]{1,100}$/,
  phone: /^\+?[0-9]{1,4}[ -]*\(?[0-9]{1,4}\)?[ -]*[0-9\- ]{4,10}$/,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
  website: /^((([A-Za-z]{3,9}:(?:\/\/)?)?(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/,
  location: /^[a-zA-ZÀ-ÿ]+(?:[\s-][a-zA-ZÀ-ÿ]+)*$/,
  street: /^[A-Za-zÀ-ÿ0-9'\.\-\s\,]{1,100}$/,
  streetnumber: /^[a-zA-Z]{0,3}\s?\d{1,5}[a-zA-Z]*[-\s\w]*$/,
  zipcode: /^[a-zA-Z0-9]{2,5}([- ]?[a-zA-Z0-9]{2,5})?$/,
  vat: /^[a-zA-Z]{2}[a-zA-Z0-9!@#$%^&*()_.+-=]{8,13}$/,
  registernumber: /^\d{10,15}$/,
  tax: /^(?:\d{1,2}(?:\.\d{1,3})?|[a-z]+)$/,
  hexadecimal: /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/,
  iban: /^\s*[A-Z]\s?\d\s?\d\s?(?:\d\s?){7}|(?:[0-9A-Z]\s?){15,25}\s*$/,
  bban: /^(?:[ ]?[0-9]){1,20}$/,
  bic: /^([A-Z]{4}\s?[A-Z]{2}\s?[A-Z0-9]{2}\s?([A-Z0-9]{0,3})?)$/,
  qrIban: /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){2,5}(?:[ ]?[A-Z0-9]{1,8})?$/,
  membership: /^[0-9]{2,8}$/,
  reference:  /^\d{8,12},\s*[A-Za-z\s]+,\s*[A-Z]{2,22}$/,
  currency: /^-?[0-9]+(\.[0-9]{1,2})?$/,
  quantity: /^\d+(\.\d{1,2})?$/,
  percentage: /^\d{1,2}(\.\d{1,3})?$/,
}
