import axios from 'axios';
import Cookies from 'universal-cookie';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { User } from '../models/user.model';
import { Token } from '../services/token.service';
import { Message } from '../models/message.model';

const cookies = new Cookies()
export class UserDL {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getUser(api_key: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/users`
    let user: User | null = null
    let token = Token.getToken()
    let error: Error = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.get(url, token).then(response => { user = response.data.user })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (user) {
      this.dataResponse = user
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putAccountUser(api_key: string, avatar: any, first_name: string, last_name: string, phone: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/users`
    let user: User | null = null
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append('first_name',first_name)
    data.append('last_name',last_name)
    data.append('phone',phone)
    data.append('avatar',avatar)

    await axios.put(url, data, token)
    .then(response => { messageResponse = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated profile') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putAccountEmail(api_key: string, email: string, password: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/users/email`
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    let data = {
      email,
      password
    }

    await axios.put(url, data, token)
    .then(response => { messageResponse = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated account') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putAccountPassword(api_key: string, password: string, new_password: string, new_password_confirm: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/users/password`
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    let data = {
      current_password: password,
      password: new_password,
      password_confirmation: new_password_confirm
    }

    await axios.put(url, data, token)
    .then(response => { messageResponse = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated account') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
