import React from 'react';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Quote } from '../../../../models/quote.model';
import { Order } from '../../../../models/order.model';
import { Invoice } from '../../../../models/invoice.model';
import { Requirement } from '../../../../models/requirement.model';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { discountDescription } from '../../../../libraries/discount.library';

interface SaleViewRequirementsPageProps {
  record: any | Quote | Order | Invoice
};

const SaleViewRequirements: React.FunctionComponent<SaleViewRequirementsPageProps> = ({record}) => {
  const {lang} = StateLanguage()

  function services(record: Quote | Order | Invoice): Requirement[] {
    return record.requirements.filter((item: any) => (item.type === 'service'))
  }

  function products(record: Quote | Order | Invoice): Requirement[] {
    return record.requirements.filter((item: any) => (item.type === 'product'))
  }

  return (
    <div className="card card-flush mb-5">
      <div className="card-header">
        <div className="card-title">
          <h4>{lang.labels.requirements}</h4>
        </div>
        <div className="card-toolbar">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder" role="tablist">
            <li className="nav-item" role="presentation">
              <a href="#tab-services" className="nav-link text-active-primary active" data-bs-toggle="tab" role="tab">{lang.labels.services}</a>
            </li>
            <li className="nav-item" role="presentation">
              <a href="#tab-products" className="nav-link text-active-primary" data-bs-toggle="tab" role="tab">{lang.labels.products}</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="tab-content">
          <div id="tab-services" className="tab-pane fade show active" role="tabpanel">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed gy-2 mb-1">
                  <thead className="border-bottom">
                    <tr className="text-muted text-uppercase fw-bolder fs-7">
                      <th className="min-w-200px text-nowrap text-start">{lang.labels.description}</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.quantity}</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.unitPrice} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.rebate} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.taxRate} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.priceGross} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.priceNet} ({record.currency.symbol})</th>
                    </tr>
                  </thead>
                  { services(record).length > 0
                    ?
                    <>
                      { services(record).map (( (item, index) => { return (
                        <tbody key={index} className="text-gray-700 fw-bold">
                          <tr>
                            <td colSpan={7}>
                              <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-3 me-3">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z" fill="black"/>
                                    <path opacity="0.3" d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z" fill="black"/>
                                  </svg>
                                </span>
                                <span className="text-uppercase me-3">{lang.labels.requirement}:</span>
                                {item.description}
                              </div>
                            </td>
                          </tr>
                          { item.contents.map (( (sub_item, sub_index) => { return (
                            <tr key={sub_index}>
                              <td className="text-start">{sub_item.detail}</td>
                              <td className="text-nowrap text-end">{sub_item.quantity} {sub_item.unit.symbol}</td>
                              <td className="text-nowrap text-end">{moneyFormat(sub_item.price_unit)}</td>
                              <td className="text-nowrap text-end">
                                <div className="d-flex align-items-center justify-content-end">
                                  {moneyFormat(sub_item.rebate_amount)}
                                  <span className="badge badge-sm badge-light w-75px fs-9 ms-2">{discountDescription(lang, record.currency, sub_item.rebate)}</span>
                                </div>
                              </td>
                              <td className="text-nowrap text-end">
                                <div className="d-flex align-items-center justify-content-end">
                                  {moneyFormat(sub_item.tax_amount)}
                                  <span className="badge badge-sm badge-light w-75px fs-9 ms-2">{sub_item.tax_rate} %</span>
                                </div>
                              </td>
                              <td className="text-nowrap text-end">{moneyFormat(sub_item.price_gross)}</td>
                              <td className="text-nowrap text-end">{moneyFormat(sub_item.price_net)}</td>
                            </tr>
                          )}))}
                          <tr className="row-dashed">
                            <td colSpan={7}>
                              <div className="d-flex justify-content-end">
                                <div className="text-uppercase fs-7 me-10">{lang.labels.priceTotal}:</div>
                                <div className="text-end fw-bolder">{record.currency.symbol} {moneyFormat(item.price_total)}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}))}
                    </>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={7} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
          <div id="tab-products" className="tab-pane fade" role="tabpanel">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed gy-2 mb-1">
                  <thead className="border-bottom">
                    <tr className="text-muted text-uppercase fw-bolder fs-7">
                      <th className="min-w-200px text-nowrap text-start">{lang.labels.description}</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.quantity}</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.unitPrice} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.rebate} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.taxRate} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.priceGross} ({record.currency.symbol})</th>
                      <th className="min-w-100px text-nowrap text-end">{lang.labels.priceNet} ({record.currency.symbol})</th>
                    </tr>
                  </thead>
                  { products(record).length > 0
                    ?
                    <>
                      { products(record).map (( (item, index) => { return (
                        <tbody key={index} className="text-gray-700 fw-bold">
                          <tr>
                            <td colSpan={7}>
                              <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-3 me-3">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z" fill="black"/>
                                    <path opacity="0.3" d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z" fill="black"/>
                                  </svg>
                                </span>
                                <span className="text-uppercase me-3">{lang.labels.requirement}:</span>
                                {item.description}
                              </div>
                            </td>
                          </tr>
                          { item.contents.map (( (sub_item, sub_index) => { return (
                            <tr key={sub_index}>
                              <td className="text-start">{sub_item.detail}</td>
                              <td className="text-nowrap text-end">{sub_item.quantity} {sub_item.unit.symbol}</td>
                              <td className="text-nowrap text-end">{moneyFormat(sub_item.price_unit)}</td>
                              <td className="text-nowrap text-end">
                                <div className="d-flex align-items-center justify-content-end">
                                  {moneyFormat(sub_item.rebate_amount)}
                                  <span className="badge badge-sm badge-light w-85px fs-9 ms-2">{discountDescription(lang, record.currency, sub_item.rebate)}</span>
                                </div>
                              </td>
                              <td className="text-nowrap text-end">
                                <div className="d-flex align-items-center justify-content-end">
                                  {moneyFormat(sub_item.tax_amount)}
                                  <span className="badge badge-sm badge-light w-75px fs-9 ms-2">{sub_item.tax_rate} %</span>
                                </div>
                              </td>
                              <td className="text-nowrap text-end">{moneyFormat(sub_item.price_gross)}</td>
                              <td className="text-nowrap text-end">{moneyFormat(sub_item.price_net)}</td>
                            </tr>
                          )}))}
                          <tr className="row-dashed">
                            <td colSpan={7}>
                              <div className="d-flex justify-content-end">
                                <div className="text-uppercase fs-7 me-10">{lang.labels.priceTotal}:</div>
                                <div className="text-end fw-bolder">{record.currency.symbol} {moneyFormat(item.price_total)}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}))}
                    </>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={7} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SaleViewRequirements;
