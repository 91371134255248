import React, { Dispatch, SetStateAction, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { CategoryDA } from '../../services/category.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { Legend } from '../../styles/legend.style';
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendInvalidEmptyIcon, legendValidIcon, legendValidRequired, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { languageDownloadValue } from '../../libraries/language.download.library';

interface AppModalCategoryCreateProps {
  companyForUser: Company | undefined | null,
  category: {value: string, valid: boolean} | null,
  categories: Category[] | undefined | null,
  setCategory: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null
  setCategories: Dispatch<SetStateAction<Category[] | undefined | null>>
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryCreate: React.FunctionComponent<AppModalCategoryCreateProps> = ({companyForUser, category, categories, setCategory, setCategories}) => {
  const {lang} = StateLanguage()

  const expressions = {
    //eslint-disable-next-line
    name: /^[A-Za-zÀ-ÿ0-9\s]{1,100}$/,
    //eslint-disable-next-line
    description: /^[A-Za-zÀ-ÿ0-9\'\.\-\,\#\°?\s!@#$%^&*()+=[\]{};:'"<>|\\/~_/]{3,300}$/
  }

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [description, setDescription] = useState({value: '', valid: false})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})

  const submitCategoryCreate = async () => {
    setLoadIndicator('on')

    let languageNamesInvalid: number = 0
    Object.keys(names.valid).forEach((item) => { if (!item) { languageNamesInvalid += 1 }})

    if (companyForUser && categories && name.valid && description.valid && languageNamesInvalid === 0) {
      await CategoryDA.postCategory(companyForUser.id, name.value, description.value, names.value).then( (response) => {
        if (response.status === 201) {
          categoryResponse = response.data
          setCategories([categoryResponse, ...categories])

          if (category && setCategory) {
            setCategory({...category, value: categoryResponse.id, valid: true})
          }

          Swal.fire({
            title: 'Successfully created category!',
            text: 'Updating company categories, returning to page',
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('modal-category-create-input-name', 'modal-category-create-container-validate-name-required')
        }
        if (description.value.length === 0) {
          legendInvalid('modal-category-create-container-validate-description-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeDescription = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setDescription({...description, value: event.target.value})
  }

  const handleChangeNames = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNames(names => ({...names, value: {...names.value, [item]: event.target.value}}))
  }

  const validateName = () => {
    if (expressions) {
      if (expressions.name.test(name.value)) {
        setName({...name, valid: true})
        legendValidRequiredIcon('modal-category-create-input-name', 'modal-category-create-container-validate-name-valid', 'modal-category-create-container-validate-name-required')
      } else {
        setName({...name, valid: false})
        legendInvalidEmptyIcon(name.value, 'modal-category-create-input-name', 'modal-category-create-container-validate-name-valid', 'modal-category-create-container-validate-name-required')
      }
    }
  }

  const validateDescription = () => {
    if (expressions) {
      if (expressions.description.test(description.value)) {
        setDescription({...description, valid: true})
        legendValidRequired('modal-category-create-container-validate-description-valid', 'modal-category-create-container-validate-description-required')
      } else {
        setDescription({...description, valid: false})
        legendInvalidEmpty(description.value, 'modal-category-create-container-validate-description-valid', 'modal-category-create-container-validate-description-required')
      }
    }
  }

  const validateNames = (index: number, item: string) => {
    if (expressions && names.value[item as keyof typeof names.value].length > 0) {
      if (expressions.name.test(names.value[item as keyof typeof names.value])) {
        setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
        legendValidIcon('modal-category-create-input-language-name-' + index, 'modal-category-create-container-validate-language-name-valid-' + index)
      } else {
        setNames(names => ({...names, valid: {...names.valid, [item]: false}}))
        legendInvalidIcon('modal-category-create-input-language-name-' + index, 'modal-category-create-container-validate-language-name-valid-' + index)
      }
    } else {
      setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
      legendValidInvalidIconRestart('modal-category-create-input-language-name-' + index, 'modal-category-create-container-validate-language-name-valid-' + index)
    }
  }

  const executeHideModalCategoryCreate = () => {
    modalHide('modal-category-create')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-category-create-input-name', 'modal-category-create-container-validate-name-valid')
    legendValidInvalidIconRestart('modal-category-create-input-name', 'modal-category-create-container-validate-name-required')
    legendValidInvalidRestart('modal-category-create-container-validate-description-valid')
    legendValidInvalidRestart('modal-category-create-container-validate-description-required')

    for (var indexName in Object.keys(names.valid)) {
      legendValidInvalidIconRestart('modal-category-create-input-language-name-' + indexName, 'modal-category-create-container-validate-language-name-valid-' + indexName)
    }

    setName({...name, value: '', valid: false})
    setDescription({...description, value: '', valid: false})
    setNames({...names, value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
  }

  return (
    <div id="modal-category-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Create New Category</h2>
          </div>
          <div className="modal-body mx-5 mx-xl-10 py-0">
            <div className="form d-flex flex-column flex-lg-row">
              <div className="d-flex flex-column order-2 order-lg-1 w-100 w-lg-350px">
                <div className="card card-flush">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>Name in other languages</h2>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <label className="form-label">Enter in language you want</label>
                    { Object.keys(names.value).map (( (item, index) => { return (
                      <div key={index}>
                        <input id={"modal-category-create-input-language-name-" + index} className="form-control my-2" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item.toUpperCase())} value={names.value[item as keyof typeof names.value]} onChange={(event) => handleChangeNames(item, event)} onKeyUp={() => validateNames(index, item)} onBlur={() => validateNames(index, item)} />
                        <Legend property={names.valid[item as keyof typeof names.valid].toString()}>
                          <div id={"modal-category-create-container-validate-language-name-valid-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="language-name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                        </Legend>
                      </div>
                    )}))}
                    <div className="text-muted fs-7 mt-5">If you wish, you can assign the name of the category in languages that you think are necessary.</div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-row-fluid order-1 order-lg-2 ">
                <div className="card card-flush">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>General</h2>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="mb-10 fv-row fv-plugins-icon-container">
                      <label className="required form-label">Category Name</label>
                        <input id="modal-category-create-input-name" className="form-control form-control-solid mb-2" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                        <Legend property={name.valid.toString()}>
                          <div id="modal-category-create-container-validate-name-valid" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                          <div id="modal-category-create-container-validate-name-required" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="name" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                          </div>
                        </Legend>
                      <div className="text-muted fs-7">A category name is required and recommended to be unique.</div>
                    </div>
                    <div>
                      <label className="required form-label">Description</label>
                      <TextareaAutosize className="form-control form-control-solid" minRows={3} maxRows={5} name="description" autoComplete="off" value={description.value} onChange={handleChangeDescription} onKeyUp={validateDescription} onBlur={validateDescription} />
                      <Legend property={description.valid.toString()}>
                        <div id="modal-category-create-container-validate-description-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="description" data-validator="text">The value is not a valid description</div>
                        </div>
                        <div id="modal-category-create-container-validate-description-required" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="description" data-validator="notEmpty">Description is required</div>
                        </div>
                      </Legend>
                      <div className="text-muted fs-7">Set a description to the category for better visibility.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light me-3" type="reset" onClick={executeHideModalCategoryCreate}>Cancel</button>
              <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryCreate}>
                <span className="indicator-label">Create</span>
                <span className="indicator-progress">
                  Please wait... 
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryCreate;
