import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { format } from 'date-fns';
import TextareaAutosize from 'react-textarea-autosize';

import { UseOutletContextPurchase } from '../Purchase';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Currency } from '../../../../models/currency.model';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { Contact } from '../../../../models/contact.model';
import { Legend } from '../../../../styles/legend.style';
import { legendInvalid, legendValid } from '../../../../tools/legend.data.entry.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { evaluateLegendValidateRequired } from '../../../../scripts/validate.legend.script';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { contactSelected, treasuryAccountSelected } from '../../../../libraries/selected.item.list.library';
import { expressions } from '../../../../libraries/regular.expressions.library';

import AppModalContactCreate from '../../../../components/modal/ModalContactCreate';

interface PurchaseRecordGeneratePageProps {
  purchase: string,
  record: any | ProviderOrder | ProviderInvoice,
  provider: {value: string, object: any, valid: boolean},
  concerning: {value: string, valid: boolean},
  date: {value: string, valid: boolean},
  currency: {value: string, object: any, valid: boolean},
  treasuryAccount: {value: string, valid: boolean},
  setProvider: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setConcerning: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDate: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setTreasuryAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>
};

const PurchaseRecordGenerate: React.FunctionComponent<PurchaseRecordGeneratePageProps> = ({purchase, record, provider, concerning, date, currency, treasuryAccount, setProvider, setConcerning, setDate, setCurrency, setTreasuryAccount}) => {
  const {userLoggedIn, companyForUser, settingsForCompany, contactsForCompany, treasuryAccountsForCompany, setContactsForCompany} = UseOutletContextPurchase()
  const {lang} = StateLanguage()

  const labels: {[key: string]: {title: string, desc: string, reference: string, date: string}} = {
    order: {title: lang.labels.newProviderOrder, desc: lang.labels.pleaseModifyAddAndOrDeleteFieldsFromProviderOrder, reference: lang.labels.providerOrderReference, date: lang.labels.providerOrderDate},
    invoice: {title: lang.labels.newProvederInvoice, desc: lang.labels.pleaseModifyAddOrDeleteFieldsFromProviderInvoice, reference: lang.labels.providerInvoiceReference, date: lang.labels.providerInvoiceDate}
  }

  const [searchContact, setSearchContact] = useState<{text: string, list: Contact[]}>({text: '', list: []})

  const handleChangeProvider = (item: Contact) => {
    setProvider({...provider, value: item.data.id, object: item, valid: true})
    legendValid('container-validate-provider-required')
  }

  const handleChangeConcerning = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setConcerning({...concerning, value: event.target.value})
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('container-validate-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('container-validate-date-required')
    }
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('container-validate-currency-required')
  }

  const handleChangeTreasuryAccount = (item: string) => {
    setTreasuryAccount({...treasuryAccount, value: item, valid: true})
    legendValid('container-validate-treasury-account-required')
  }

  const handleChangeSearchContact = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: Contact[] = []

    if (contactsForCompany) {
      if (text.length > 0) {
        for (let contact of contactsForCompany) {
          let name: string = (contact.type === 'person') ? (`${contact.data.first_name} ${contact.data.last_name}`).toLowerCase() : contact.data.name.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            list.push(contact)
          }
        }
      } else {
        list = contactsForCompany
      }
    }

    setSearchContact({text: text, list: list})
  }

  const validateConcerning = () => {
    evaluateLegendValidateRequired(expressions.text, concerning, setConcerning, 'container-validate-concerning-valid', 'container-validate-concerning-required')
  }

  const executeShowModalContactCreate = () => {
    modalShow('modal-contact-create')
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function uploadDropdown() {
    if (contactsForCompany) {
      setSearchContact({text: '', list: contactsForCompany})
    } else {
      setSearchContact({text: '', list: []})
    }
  }

  return (
    <>
      { companyForUser && settingsForCompany && contactsForCompany && treasuryAccountsForCompany &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h2>
                {labels[purchase].title}
                <span className="text-lowercase ms-2">({lang.labels.reference})</span>
              </h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex flex-wrap mb-5">
              <div className="flex-equal me-5">
                <table className="table align-middle gy-1 m-0">
                  <tbody className="fw-bold">
                    <tr>
                      <td className="w-200px text-gray-500">{labels[purchase].reference}:</td>
                      <td className="min-w-200px text-gray-800">
                        {purchase === "order" && record.provider_order_number}
                        {purchase === "invoice" && record.provider_invoice_number}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-200px text-gray-500">{lang.labels.company}:</td>
                      <td className="min-w-200px text-gray-800">{record.company.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex-equal">
                <table className="table align-middle gy-1 m-0">
                  <tbody className="fw-bold">
                    { purchase === "invoice" &&
                      <tr>
                        <td className="w-200px text-gray-500">{lang.labels.providerOrderNumber}:</td>
                        <td className="min-w-200px text-gray-800">{record.provider_order.id.length > 0 ? record.provider_order.provider_order_number : lang.labels.none}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="fw-bold fs-6 mb-5">{labels[purchase].desc}</div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.provider}</label>
              <div className="fv-row">
                <div className="d-flex align-self-center">
                  <div className="flex-grow-1 me-3">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {provider.valid ? `${contactSelected(lang, contactsForCompany, provider.value)}` : `${lang.labels.selectOption}`}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-search select2-search--dropdown">
                            <input className="select2-search__field" type="text" name="contact" value={searchContact.text} onChange={handleChangeSearchContact} />
                          </span>
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { searchContact.list.length > 0
                                ?
                                <>
                                  { searchContact.list.map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${provider.value === item.data.id && "select2-results__option--selected"}`} role="option" aria-selected={provider.value === item.data.id}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => handleChangeProvider(item)}>{item.type === "person" ? `${item.data.first_name} ${item.data.last_name}` : `${item.data.name}`}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </>
                                :
                                <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                              }
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="flex-shrink-0">
                    <button className="btn btn-sm btn-light-primary h-100" type="button" onClick={executeShowModalContactCreate}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                          </svg>
                        </span>
                        {lang.labels.addContact}
                      </div>
                    </button>
                  </div>
                </div>
                <div className="form-text">{lang.labels.searchContact}</div>
                <Legend property={provider.valid.toString()}>
                  <div id="container-validate-provider-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="provider" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                  </div>
                </Legend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.concerning}</label>
              <div className="fv-row">
                <TextareaAutosize className="form-control form-control-solid" name="concerning" minRows={2} autoComplete="off" value={concerning.value} onChange={handleChangeConcerning} onKeyUp={validateConcerning} onBlur={validateConcerning} />
                <Legend property={concerning.valid.toString()}>
                  <div id="container-validate-concerning-valid" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="concerning" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                  </div>
                  <div id="container-validate-concerning-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="concerning" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                  </div>
                </Legend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{labels[purchase].date}</label>
              <div className="fv-row">
                <input className="form-control form-control-solid" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                <Legend property={date.valid.toString()}>
                  <div id="container-validate-date-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="date" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                  </div>
                </Legend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.currency}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {currency.valid ? `${currencyValue(currency.value)}` : `${lang.labels.selectOption}`}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { currencyList().map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <Legend property={currency.valid.toString()}>
                  <div id="container-validate-currency-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="currency" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                  </div>
                </Legend>
              </div>
            </div>
            <div className="row">
              <label className="form-label required">{lang.labels.treasuryAccount}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {treasuryAccount.valid ? `${treasuryAccountSelected(lang, treasuryAccountsForCompany, treasuryAccount.value)}` : `${lang.labels.selectOption}`}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text fs-7">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { treasuryAccountsForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasuryAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasuryAccount.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => handleChangeTreasuryAccount(item.id)}>{item.name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <Legend property={treasuryAccount.valid.toString()}>
                  <div id="container-validate-treasury-account-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="treasury-account" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                  </div>
                </Legend>
              </div>
            </div>
          </div>
        </div>
      }
      <AppModalContactCreate userLoggedIn={userLoggedIn} companyForUser={companyForUser} contactsForCompany={contactsForCompany} contact={provider} languageFile={null} qrTtypeFile={null} setContactsForCompany={setContactsForCompany} setContact={setProvider} setLanguageFile={null} setQrTtypeFile={null}></AppModalContactCreate>
    </>
  )
};

export default PurchaseRecordGenerate;
