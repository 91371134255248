import axios from 'axios';
import { Token } from '../services/token.service';
import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';

export class DashboardDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getDashboardInsight(id_company: string, time_interval_range: string, time_interval_current_start_date: string, time_interval_current_end_date: string, time_interval_compare_start_date: string, time_interval_compare_end_date: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/dashboard?time_interval_range=${time_interval_range}&time_interval_current_start_date=${time_interval_current_start_date}&time_interval_current_end_date=${time_interval_current_end_date}&time_interval_compare_start_date=${time_interval_compare_start_date}&time_interval_compare_end_date=${time_interval_compare_end_date}`
    let token = Token.getToken()
    let dashboardInsightForCompany: any | null = {
      id_company: id_company,
      time_interval: {
        range: '',
        current:{
          start_date: '',
          end_date: ''
        },
        compare:{
          start_date: '',
          end_date: ''
        }
      },
      data: null
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse  = {
      id: 0,
      message: ""
    }
    await axios.get(url , token).then(response => { dashboardInsightForCompany = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })
    
    if (dashboardInsightForCompany.id_company === id_company) {

      this.dataResponse = dashboardInsightForCompany
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getDashboardComparsion(id_company: string, range_current: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/dashboard/comparison?range_current=${range_current}`
    let token = Token.getToken()
    let dashboardComparsionForCompany: any | null = {
      id_company: id_company,
      range: {
        list: null,
        current: ''
      },
      data: null
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.get(url, token).then(response => { dashboardComparsionForCompany = response.data })
    .catch(error => { alert(error) })

    if (dashboardComparsionForCompany.id_company === id_company) {
      this.dataResponse = dashboardComparsionForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getDashboardAnalysis(id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/dashboard/analysis`
    let token = Token.getToken()
    let dashboardAnalysisForCompany: any | null = {
      id_company: id_company,
      data: null
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.get(url, token).then(response => { dashboardAnalysisForCompany = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (dashboardAnalysisForCompany.id_company === id_company) {
      this.dataResponse = dashboardAnalysisForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
