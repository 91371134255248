import { Dispatch, SetStateAction } from "react"
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendInvalidEmptyIcon, legendValid, legendValidIcon, legendValidRequired, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart, legendInvalidRequired } from "../tools/legend.data.entry.tool"

function evaluateLegendValidateEmpty(regex: RegExp, subject: any, setSubject: Dispatch<SetStateAction<any>>, container: string) {
  if (subject.value.length === 0) {
    setSubject({...subject, valid: true})
    legendValidInvalidRestart(container)
  } else {
    if (regex.test(subject.value)) {
      setSubject({...subject, valid: true})
      legendValid(container)
    } else {
      setSubject({...subject, valid: false})
      legendInvalid(container)
    }
  }
}

function evaluateLegendValidateEmptyIcon(regex: RegExp, subject: any, setSubject: Dispatch<SetStateAction<any>>, identifier: string, container: string) {
  if (subject.value.length === 0) {
    setSubject({...subject, valid: true})
    legendValidInvalidIconRestart(identifier, container)
  } else {
    if (regex.test(subject.value)) {
      setSubject({...subject, valid: true})
      legendValidIcon(identifier, container)
    } else {
      setSubject({...subject, valid: false})
      legendInvalidIcon(identifier, container)
    }
  }
}

function evaluateLegendValidateRequired(regex: RegExp, subject: any, setSubject: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(subject.value)) {
    setSubject({...subject, valid: true})
    legendValidRequired(container_1, container_2)
  } else {
    setSubject({...subject, valid: false})
    legendInvalidEmpty(subject.value, container_1, container_2)
  }
}

function evaluateLegendValidateRequiredIcon(regex: RegExp, subject: any, setSubject: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(subject.value)) {
    setSubject({...subject, valid: true})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    setSubject({...subject, valid: false})
    legendInvalidEmptyIcon(subject.value, identifier, container_1, container_2)
  }
}

function evaluateLegendValidateEmptyIconObject(regex: RegExp, attribute: string, subject: any, setSubject: Dispatch<SetStateAction<any>>, identifier: string, container: string) {
  if (subject.value.length === 0) {
    setSubject({...subject, [attribute]: true})
    legendValidInvalidIconRestart(identifier, container)
  } else {
    if (regex.test(subject.value)) {
      setSubject({...subject, [attribute]: true})
      legendValidIcon(identifier, container)
    } else {
      setSubject({...subject, [attribute]: false})
      legendInvalidIcon(identifier, container)
    }
  }
}

function evaluateLegendValidateRequiredIconObject(regex: RegExp, attribute: string, subject: any, setSubject: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(subject.value)) {
    setSubject({...subject, [attribute]: true})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    setSubject({...subject, [attribute]: false})
    legendInvalidEmptyIcon(subject.value, identifier, container_1, container_2)
  }
}

function evaluateLegendValidateRequiredList(regex: RegExp, index: number, subject: any, setSubject: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(subject.value[index])) {
    let tempSubjects = subject.valid.map((temp_item: boolean, temp_index: number) => {
      if (index === temp_index) {
        return true
      } else {
        return temp_item
      }
    })
    setSubject({...subject, valid: tempSubjects})
    legendValidRequired(container_1, container_2)
  } else {
    let tempSubjects = subject.valid.map((temp_item: boolean, temp_index: number) => {
      if (index === temp_index) {
        return false
      } else {
        return temp_item
      }
    })
    setSubject({...subject, valid: tempSubjects})
    legendInvalidRequired(container_1, container_2)
  }
}

function evaluateLegendValidateEmptyObjectList(regex: RegExp, index: number, attribute: string, subject: any, setSubject: Dispatch<SetStateAction<any>>, container: string) {
  if (subject.value[index][attribute].length === 0) {
    let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = true
      }
      return temp_item
    })
    setSubject({...subject, valid: tempSubjects})
    legendValidInvalidRestart(container)
  } else {
    if (regex.test(subject.value[index][attribute])) {
      let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
        if (index === temp_index) {
          temp_item[attribute] = true
        }
        return temp_item
      })
      setSubject({...subject, valid: tempSubjects})
      legendValid(container)
    } else {
      let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
        if (index === temp_index) {
          temp_item[attribute] = false
        }
        return temp_item
      })
      setSubject({...subject, valid: tempSubjects})
      legendInvalid(container)
    }
  }
}

function evaluateLegendValidateEmptyIconObjectList(regex: RegExp, index: number, attribute: string, subject: any, setSubject: Dispatch<SetStateAction<any>>, identifier: string, container: string) {
  if (subject.value[index][attribute].length === 0) {
    let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = true
      }
      return temp_item
    })
    setSubject({...subject, valid: tempSubjects})
    legendValidInvalidIconRestart(identifier, container)
  } else {
    if (regex.test(subject.value[index][attribute])) {
      let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
        if (index === temp_index) {
          temp_item[attribute] = true
        }
        return temp_item
      })
      setSubject({...subject, valid: tempSubjects})
      legendValidIcon(identifier, container)
    } else {
      let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
        if (index === temp_index) {
          temp_item[attribute] = false
        }
        return temp_item
      })
      setSubject({...subject, valid: tempSubjects})
      legendInvalidIcon(identifier, container)
    }
  }
}

function evaluateLegendValidateRequiredIconObjectList(regex: RegExp, index: number, attribute: string, subject: any, setSubject: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(subject.value[index][attribute])) {
    let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = true
      }
      return temp_item
    })
    setSubject({...subject, valid: tempSubjects})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = false
      }
      return temp_item
    })
    setSubject({...subject, valid: tempSubjects})
    legendInvalidEmptyIcon(subject.value[index][attribute], identifier, container_1, container_2)
  }
}

function evaluateLegendValidateRequiredObjectMap(regex: RegExp, index: number, sub_index: number, attribute: string, subject: any, setSubject: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(subject.value[index].contents[sub_index][attribute])) {
    let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item.contents[sub_index][attribute] = true
      }
      return temp_item
    })
    setSubject({...subject, valid: tempSubjects})
    legendValidRequired(container_1, container_2)
  } else {
    let tempSubjects = subject.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item.contents[sub_index][attribute] = false
      }
      return temp_item
    })
    setSubject({...subject, valid: tempSubjects})
    legendInvalidEmpty(subject.value[index].contents[sub_index][attribute], container_1, container_2)
  }
}

export { evaluateLegendValidateEmpty, evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequired, evaluateLegendValidateEmptyIconObject, evaluateLegendValidateRequiredIcon, evaluateLegendValidateEmptyObjectList, evaluateLegendValidateRequiredIconObject, evaluateLegendValidateEmptyIconObjectList, evaluateLegendValidateRequiredList, evaluateLegendValidateRequiredIconObjectList, evaluateLegendValidateRequiredObjectMap }
