import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCategory } from './Category';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { CategoryDA } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Error } from '../../../../models/error.model';
import { Container } from '../../../../styles/container.style';
import { Message } from '../../../../models/message.model';
import { modalShow } from '../../../../tools/modal.tool';

import AppModalCategoryCreate from '../../../../components/modal/ModalCategoryCreate';
import AppModalCategoryUpdate from '../../../../components/modal/ModalCategoryUpdate';

export interface CategoryListPageProps {};

let errorResponse: Error, messageResponse: Message, categoryResponse: Category, categoriesResponse: any;

const CategoryListPage: React.FunctionComponent<CategoryListPageProps> = props => {
  const {setRoute, companyForUser} = UseOutletContextCategory()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [filter, setFilter] = useState({search: '', options: {direction: '', order: ''}})
  const [checked, setChecked] = useState({count: 0, button: false})
  const [categories, setCategories] = useState<Category[] | undefined | null>(null)
  const [category, setCategory] = useState<Category | undefined | null>(null)
  const [categoryValue, setCategoryValue] = useState({name: '', description: '', names: {en: '', fr: '', it: '', de: ''}})
  const [categoryValid, setCategoryValid] = useState({name: false, description: false, names: {en: true, fr: true, it: true, de: true}})

  const loadCategories = async (id_company: string, callback: any) => {
    await CategoryDA.getCategories(id_company, filter.search, filter.options.direction, filter.options.order).then( (response) => {
      if (response.status === 200) {
        categoriesResponse = response.data
        setCategories(categoriesResponse.categories)
        setFilter({...filter, options: {direction: categoriesResponse.filter.order_direction, order: categoriesResponse.filter.order_by}})
        callback()
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitCategoryUpdate = async (item: string) => {
    setCategory(null)

    if (companyForUser) {
      await CategoryDA.getCategory(companyForUser.id, item).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data

          setCategory(categoryResponse)
          setCategoryValue({name: categoryResponse.display_name, description: categoryResponse.description, names: categoryResponse.names})
          setCategoryValid({name: true, description: true, names: {en: true, fr: true, it: true, de: true}})

          modalShow('modal-category-update')
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      }) 
    }
  }

  const submitCategoryDelete = async (item: string) => {
    if (companyForUser) {
      Swal.fire({
        title: 'You want to delete this category?',
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await CategoryDA.deleteCategories(companyForUser.id, [item]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadCategories(companyForUser.id, () => {
                Swal.fire({
                  title: `${messageResponse.message}!`,
                  text: 'Updating company category list, returning to page.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitCategoryDeleteList = () => {
    if (companyForUser) {
      Swal.fire({
        title: 'You want to delete selected categories?',
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          let listCategoriesOfSelected : string[] = []

          Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).forEach( (node) => {
            let element = node as HTMLInputElement
            listCategoriesOfSelected.push(element.value)
          })

          await CategoryDA.deleteCategories(companyForUser.id, listCategoriesOfSelected).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              if (document.querySelector('input[name="categories-all"]:checked')) {
                let element = document.querySelector('input[name="categories-all"]:checked') as HTMLInputElement
                element.checked = false
              }
              if (Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length > 0) {
                Array.from(document.querySelectorAll('input[name="categories-list"]')).forEach( (node) => {
                  let checkbox = node as HTMLInputElement
                  checkbox.checked = false
                })
              }

              loadCategories(companyForUser.id, () => {
                Swal.fire({
                  title: `${messageResponse.message}!`,
                  text: 'Updating company category list, returning to page.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (document.querySelector('input[name="categories-all"]:checked')) {
      let element = document.querySelector('input[name="categories-all"]:checked') as HTMLInputElement
      element.checked = false
    }

    if (Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length > 0) {
      Array.from(document.querySelectorAll('input[name="categories-list"]')).forEach( (node) => {
        let element = node as HTMLInputElement
        element.checked = false
      })
    }

    setChecked({...checked, count: 0, button: false})

    filter.search = event.target.value
    filter.options = {direction: '', order: ''}

    if (companyForUser) {
      loadCategories(companyForUser.id, () => {})
    }
  }

  const executeCategoryOrder = (item: string) => {
    if (companyForUser) {
      if (item === filter.options.order) {
        switch (filter.options.direction) {
          case 'asc':
            filter.options.direction = 'desc'
            break;
          case 'desc':
            filter.options.direction = 'asc'
            break;
        }
      } else {
        filter.options.order = item
        filter.options.direction = 'asc'
      }
      loadCategories(companyForUser.id, () => {})
    }
  }

  const executeSelectAllCategories = () => {
    if (!document.querySelector('input[name="categories-all"]:checked')) {
      Array.from(document.querySelectorAll('input[name="categories-list"]')).forEach( (node) => {
        let checkbox = node as HTMLInputElement
        checkbox.checked = false
      })

      setChecked({...checked, count: Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length, button: false})
    } else {
      Array.from(document.querySelectorAll('input[name="categories-list"]')).forEach( (node) => {
        let checkbox = node as HTMLInputElement
        checkbox.checked = true
      })

      if (categories && categories.length > 0) {
        setChecked({...checked, count: Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length, button: true})
      }
    }
  }

  const executeSelectCategory = () => {
    let element = document.querySelector('input[name="categories-all"]') as HTMLInputElement

    if (categories && categories.length === Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length) {
      element.checked = true
    } else {
      element.checked = false
    }

    if (0 < Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length) {
      setChecked({...checked, count: Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length, button: true})
    } else {
      setChecked({...checked, count: Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length, button: false})
    }
  }

  const executeShowModalCategoryCreate = () => {
    modalShow('modal-category-create')
  }

  function quantityProducts(quantity: number): string {
    return (quantity > 0) ? 'success' : 'danger'
  }

  useEffect( () => {
    if (companyForUser) {
      filter.search = ''
      filter.options.direction = ''
      filter.options.order = ''
      checked.count = 0
      checked.button = false

      if (document.querySelector('input[name="categories-all"]:checked')) {
        let element = document.querySelector('input[name="categories-all"]:checked') as HTMLInputElement
        element.checked = false
      }
      if (Array.from(document.querySelectorAll('input[name="categories-list"]:checked')).length > 0) {
        Array.from(document.querySelectorAll('input[name="categories-list"]')).forEach( (node) => {
          let checkbox = node as HTMLInputElement
          checkbox.checked = false
        })
      }

      setCategories(null)
      loadCategories(companyForUser.id, () => {})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: 'Categories', branch: 'List Categories'}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { categories
        ?
        <div className="card card-flush">
          <div className="card-header align-items-center pt-5">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px ps-14" type="text" placeholder="Search category..." value={filter.search} onChange={handleChangeSearch}/>
              </div>
            </div>
            <div className="card-toolbar">
              <Container property={(!checked.button).toString()}>
                <div className="d-flex justify-content-end gap-5">
                  <button className="btn btn-primary" type="button" onClick={executeShowModalCategoryCreate}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      Add Category
                    </div>
                  </button>
                </div>
              </Container>
              <Container property={(checked.button).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="fw-bolder me-5">
                    <span className="me-2">{checked.count}</span>
                    {lang.labels.selected}
                  </div>
                  <button className="btn btn-danger" type="button" onClick={submitCategoryDeleteList}>{lang.labels.deleteSelected}</button>
                </div>
              </Container>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5">
                  <thead>
                    <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th className="w-10px pe-2 sorting_disabled">
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                          <input className="form-check-input" type="checkbox" name="categories-all" onClick={executeSelectAllCategories} />
                        </div>
                      </th>
                      <th className="min-w-350px sorting cursor-pointer text-nowrap" onClick={() => executeCategoryOrder("category")}>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Category</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.options.order === "category" && filter.options.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.options.order === "category" && filter.options.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="w-150px sorting cursor-pointer text-nowrap" onClick={() => executeCategoryOrder("products")}>
                        <div className="d-flex align-items-center">
                          <span className="me-2">QTY products</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.options.order === "products" && filter.options.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.options.order === "products" && filter.options.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="w-100px sorting_disabled text-end">Actions</th>
                    </tr>
                  </thead>
                  { categories.length > 0
                    ?
                    <tbody className="fw-semibold text-gray-600">
                      { categories.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed">
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input className="form-check-input" type="checkbox" name="categories-list" value={item.id} onClick={executeSelectCategory} />
                            </div>
                          </td>
                          <td>
                            <Link to={"/app/inventory/category/view/" + item.id} className="text-capitalize text-gray-900 text-hover-primary fs-5 fw-bold mb-1">{item.display_name}</Link>
                            <div className="text-muted fs-7 fw-bold">{item.description}</div>
                          </td>
                          <td className={`text-${quantityProducts(item.products.length)} fw-bolder px-5`}>{item.products.length}</td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-light btn-active-light-primary btn-sm">
                                <div className="d-flex align-items-center">
                                  {lang.labels.actions}
                                  <span className="svg-icon svg-icon-5 ms-1 me-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                                    </svg>
                                  </span>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary w-auto py-4 fs-7 fw-bold" align={"end"}>
                                <Dropdown.Item bsPrefix="menu-item px-3">
                                  <p className="menu-link px-3 fw-bold m-0" onClick={() => submitCategoryUpdate(item.id)}>{lang.labels.edit}</p>
                                </Dropdown.Item>
                                <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => submitCategoryDelete(item.id)}>
                                  <p className="menu-link px-3 fw-bold m-0">{lang.labels.delete}</p>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={6} className="text-center">
                          {lang.labels.noRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalCategoryCreate companyForUser={companyForUser} category={null} categories={categories} setCategory={null} setCategories={setCategories}></AppModalCategoryCreate>
      <AppModalCategoryUpdate companyForUser={companyForUser} category={category} categoryValue={categoryValue} categoryValid={categoryValid} categories={categories} setCategory={setCategory} setCategoryValue={setCategoryValue} setCategoryValid={setCategoryValid} setCategories={setCategories}></AppModalCategoryUpdate>
    </div>
  )
};

export default CategoryListPage;
