import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { Order } from '../models/order.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { Unit } from '../models/unit.model';
import { Token } from '../services/token.service';

export class OrderDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getOrders(id_company: string, start_date: string, end_date: string, contact_id: string, status: string, expiration: string, order_direction: string, order_by: string, group_by: string): Promise<ApiResponse> {

    let currentDate = new Date()
    const startOfDay = new Date(currentDate)
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999);

    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/orders?start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&order_direction=${order_direction}&group_by=${group_by}&order_by=${order_by}&status=${status}&expiration=${expiration}&startOfDay=${startOfDay}&endOfDay=${endOfDay}`

    let token = Token.getToken()
    let ordersForCompany
    let filter = {
      start_date: '',
      end_date: '',
      contact_id: '',
      validity: '',
      status: '',
      order_direction: '',
      order_by: '',
      group_by: ''
    }
    if (group_by.length === 0) {
      ordersForCompany = {
        id_company: '',
        filter,
        orders: []
      }
    } else {
      ordersForCompany = {
        id_company: '',
        filter,
        groups: []
      }
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { ordersForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (ordersForCompany && ordersForCompany.id_company === id_company) {
      this.dataResponse = ordersForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getOrder(id_company: string, id_order: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/orders/${id_order}`
    let token = Token.getToken()
    let order: Order | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { order = response.data.order })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (order!!.id === id_order) {
      this.dataResponse = order
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postOrder(id_company: string, id_customer: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, street: string, number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/orders`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataOrder = {
      concerning,
      signatures,
      company: company_data,
      currency,
      valid_until,
      delivery_address: {
        street,
        number,
        additional,
        administrative_area,
        city,
        zip_code,
        country
      },
      customer: id_customer,
      requirements,
      bank_account: id_treasury_account,
      language,
      reference,
      created_date: date
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataOrder, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully created order') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postOrderReference(id_company: string, id_quote: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/orders/${id_quote}`
    let token = Token.getToken()
    let order: Order | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.post(url, undefined, token).then(response => { order = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (order!!.quote.id === id_quote) {
      this.dataResponse = order

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putOrder(id_company: string, id_order: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, street: string, number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], signatures: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/orders/${id_order}`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataOrder = {
      concerning,
      signatures,
      currency,
      valid_until,
      delivery_address: {
        street,
        number,
        additional,
        administrative_area,
        city,
        zip_code,
        country
      },
      requirements,
      bank_account: id_treasury_account,
      language,
      created_date: date
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.put(url, dataOrder, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated order') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putOrderStatus(id_company: string, id_order: string, new_status: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/orders/${id_order}/status`
    let token = Token.getToken()
    let order: Order | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    const dataStatus = { status: new_status }
    await axios.put(url, dataStatus, token).then(response => { order = response.data.order })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (order!!.id === id_order) {
      this.dataResponse = order

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async sendOrder(id_company: string, id_order: string): Promise<ApiResponse> {
    let order: Order | null = null

    let error: Error | null = {
      code: 1026,
      message: 'Invalid id of identificador'
    }

    await axios.get('../../../../../database/order.data.json').then(response => { order = response.data }).catch(error => { alert(error) })

    if (order!!.id === id_order) {
      order!!.status = 'pending'

      this.dataResponse = order

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
