import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { ResourceUUID } from '../models/resource.uuid.model';
import { Token } from '../services/token.service';

export class UuidDL {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getUuid(api_key: string, uuid: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/uuid/${uuid}`
    let token = Token.getToken()
    let resourceUUID: ResourceUUID | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { resourceUUID = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (resourceUUID) {
      this.dataResponse = resourceUUID
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
