import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Template } from '../models/template.model';
import { Token } from '../services/token.service';

export class TemplateDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getTemplates(id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/templates`
    let token = Token.getToken()
    let templates: Template[] | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse  = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { templates = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (templates) {
      this.dataResponse = templates

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getQrTemplate(id_company: string, id_invoice: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/qr`
    let token = Token.getToken()
    let qrTemplate: any | null = {
      id_invoice: '',
      data: null
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse  = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { qrTemplate = response.data })
    .catch(errorR => { 
      errorResponse = errorR.response.data.error 
    })

    if (qrTemplate.id_invoice === id_invoice && qrTemplate.data) {
      this.dataResponse = qrTemplate
      this.dataResponse = qrTemplate

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
