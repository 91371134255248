import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextInventory } from '../Inventory';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { CategoryDA } from '../../../../services/category.service';
import { Error } from '../../../../models/error.model';
import { Service } from '../../../../models/service.model';
import { Company } from '../../../../models/company.model';
import { Settings } from '../../../../models/settings.model';
import { Category } from '../../../../models/category.model';

export interface ProductPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  categoriesForCompany: Category[] | undefined | null,
  setCategoriesForCompany: Dispatch<SetStateAction<Category[] | undefined | null>>,
  loadProductsForCompany: Function
};

let errorResponse: Error, categoriesResponse: any;

const ProductPage: React.FunctionComponent<ProductPageProps> = props => {
  const {setRoute, serviceForUser, companyForUser, settingsForCompany, loadProductsForCompany} = UseOutletContextInventory()
  const {lang} = StateLanguage()

  const [categoriesForCompany, setCategoriesForCompany] = useState<Category[] | undefined | null>(null)

  const loadCategories = async (id_company: string) => {
    await CategoryDA.getCategories(id_company, '', '', '').then( (response) => {
      if (response.status === 200) {
        categoriesResponse = response.data
        setCategoriesForCompany(categoriesResponse.categories)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    if (companyForUser) {
      setCategoriesForCompany(null)
      loadCategories(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <Outlet context={{setRoute, serviceForUser, companyForUser, settingsForCompany, categoriesForCompany, setCategoriesForCompany, loadProductsForCompany}}></Outlet>
  );
}

export function UseOutletContextProduct() {
  return useOutletContext<ContexType>()
};

export default ProductPage;
