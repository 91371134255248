import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { Service } from '../../../models/service.model';
import { Company }  from '../../../models/company.model';
import { Settings } from '../../../models/settings.model';

export interface InventoryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  loadProductsForCompany: Function
};

const InventoryPage: React.FunctionComponent<InventoryPageProps> = props => {
  const {setRoute, serviceForUser, companyForUser, settingsForCompany, loadProductsForCompany} = UseOutletContext()

  return (
    <Outlet context={{setRoute, serviceForUser, companyForUser, settingsForCompany, loadProductsForCompany}}></Outlet>
  );
}

export function UseOutletContextInventory() {
  return useOutletContext<ContexType>()
};

export default InventoryPage;
