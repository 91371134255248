import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { AuthenticationDL } from '../../services/authentication.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { Legend } from '../../styles/legend.style';
import { legendInvalidIcon } from '../../tools/legend.data.entry.tool';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

export interface RecoverPageProps {};

let errorResponse: Error, messageResponse: Message;

const RecoverPage: React.FunctionComponent<RecoverPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [email, setEmail] = useState({value: '', valid: false})

  const submitRecover = async (event: React.ChangeEvent <HTMLFormElement>) => {
    event.preventDefault()
    setLoadIndicator('on')

    if (email.valid) {
      await AuthenticationDL.recover(email.value).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.messageWillBeSentYourEmailToRecoverAccount,
            icon: 'success',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/auth/login')
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (email.value.length === 0) {
          legendInvalidIcon('input-email', 'container-validate-email-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const validateEmail = () => {
    evaluateLegendValidateRequiredIcon(expressions.email, email, setEmail, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
  }

  useEffect( () => {
    setMounted(true)

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 mx-auto">
      <form className="form w-100" noValidate onSubmit={submitRecover}>
        <div className="text-center mb-10">
          <h1 className="text-dark text-capitalize mb-3">{lang.labels.forgotPassword}</h1>
          <div className="text-gray-400 fw-bold fs-4">{lang.labels.enterYourEmailToResetYourPassword}</div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">{lang.labels.email}</label>
          <input id="input-email" className="form-control form-control-lg form-control-solid" type="email" name="email" autoComplete="off" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
          <Legend property={email.valid.toString()}>
            <div id="container-validate-email-valid" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="email" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
            </div>
            <div id="container-validate-email-required" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="email" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
            </div>
          </Legend>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          <button className="btn btn-lg btn-primary me-4" type="submit" data-kt-indicator={loadIndicator}>
            <span className="indicator-label fw-bolder">{lang.labels.submit}</span>
            <span className="indicator-progress">
              {lang.labels.pleaseWait}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          <Link to="/auth/login" className="btn btn-lg btn-light-primary fw-bolder">{lang.labels.cancel}</Link>
        </div>
      </form>
    </div>
  )
};

export default RecoverPage;
