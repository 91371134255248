import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContextPurchase } from '../Purchase';
import { User } from '../../../../models/user.model';
import { Service } from '../../../../models/service.model';
import { Company } from '../../../../models/company.model';
import { Settings } from '../../../../models/settings.model';
import { Contact }  from '../../../../models/contact.model';
import { TreasuryAccount } from '../../../../models/treasury.account.model';

export interface ProviderInvoicePageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  contactsForCompany: Contact[] | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | null | undefined,
  setSettingsForCompany: Dispatch<SetStateAction<Settings | null | undefined>>,
  setContactsForCompany: Dispatch<SetStateAction<Contact[] | undefined | null>>
};

const ProviderInvoicePage: React.FunctionComponent<ProviderInvoicePageProps> = props => {
  const {setRoute, userLoggedIn, serviceForUser, companyForUser, settingsForCompany, contactsForCompany, treasuryAccountsForCompany, setSettingsForCompany, setContactsForCompany} = UseOutletContextPurchase()

  return (
    <Outlet context={{setRoute, userLoggedIn, serviceForUser, companyForUser, settingsForCompany, contactsForCompany, treasuryAccountsForCompany, setSettingsForCompany, setContactsForCompany}}></Outlet>
  );
}

export function UseOutletContextProviderInvoice() {
  return useOutletContext<ContexType>()
};

export default ProviderInvoicePage;
