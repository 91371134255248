import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Slider from 'rc-slider';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Settings } from '../../models/settings.model';
import { Unit } from '../../models/unit.model';
import { Legend } from '../../styles/legend.style';
import { legendInvalidIcon, legendInvalidEmptyIcon, legendValidIcon, legendValidRequiredIcon, legendInvalid, legendValid, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { uploadTooltip } from '../../tools/tooltip.tool';
import { extractBase64 } from '../../scripts/extract.base.64.script';
import { measureUnitSelected } from '../../libraries/selected.item.list.library';

import imgDefault from '../../assets/images/default.png';

interface ModalProductVariantAddProps {
  settingsForCompany: Settings | null | undefined,
  variantValue: {description: string, product_variant_code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: Unit, discount:{type: string, value: string, quantity: string}, photos: {view: string, file: any}[]},
  variantValid: {description: boolean, product_variant_code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, gallery: boolean, discount:{type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[]},
  variantsValue: {description: string, product_variant_code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: Unit, discount:{type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[],
  variantsValid: {description: boolean, product_variant_code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, gallery: boolean, discount:{type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[]}[],
  setVariantValue: Dispatch<SetStateAction<{description: string, product_variant_code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: Unit, discount:{type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}>>,
  setVariantValid: Dispatch<SetStateAction<{description: boolean, product_variant_code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, gallery: boolean, discount:{type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[]}>>,
  setVariantsValue: Dispatch<SetStateAction<{description: string, product_variant_code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: Unit, discount:{type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[]>>,
  setVariantsValid: Dispatch<SetStateAction<{description: boolean, product_variant_code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, gallery: boolean, discount:{type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[]}[]>>
};

const ModalProductVariantAdd: React.FunctionComponent<ModalProductVariantAddProps> = ({settingsForCompany, variantValue, variantValid, variantsValue, variantsValid, setVariantValue, setVariantValid, setVariantsValue, setVariantsValid}) => {
  const {lang} = StateLanguage()

  const expressions = {
    //eslint-disable-next-line
    name: /^[A-Za-zÀ-ÿ0-9\s]{1,100}$/,
    //eslint-disable-next-line
    tag: /^[A-Za-zÀ-ÿ0-9\'\.\-\,\#\°?\s!@#$%^&*()+=[\]{};:'"<>|\\/~_/]{0,100}$/,
    //eslint-disable-next-line
    text: /^[\s\S]{1,2000}$/,
    //eslint-disable-next-line
    code: /^[A-Za-z0-9\'\.\-\,\#\°?\s!@#$%^&*()+=[\]{};:'"<>|\\/~_/]{0,100}$/,
    //eslint-disable-next-line
    sku: /^[A-Za-z0-9]{8,22}$/,
    //eslint-disable-next-line
    codebar: /^\d{6,22}$/,
    //eslint-disable-next-line
    size: /^[A-Za-z0-9\s-]+$/,
    //eslint-disable-next-line
    color: /^[a-zA-Z]+$/,
    //eslint-disable-next-line
    currency: /^-?[0-9]+(\.[0-9]{1,2})?$/,
    //eslint-disable-next-line
    quantity: /^\d+(\.\d{1,2})?$/
  }

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitProductVariantAdd = async () => {
    setLoadIndicator('on')

    if (validateAttributes(variantValid)) {
      setVariantsValue([...variantsValue, variantValue])
      setVariantsValid([...variantsValid, variantValid])
      setLoadIndicator('off')

      executeHideModalProductVariantAdd()
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (variantValue.description.length === 0) {
          legendInvalidIcon('modal-product-variant-add-input-description', 'modal-product-variant-add-container-validate-description-required')
        }
        if (variantValue.price.length === 0) {
          legendInvalidIcon('modal-product-variant-add-input-price', 'modal-product-variant-add-container-validate-price-required')
        }
        if (variantValue.discount.type !== 'none') {
          if (variantValue.discount.value.length === 0) {
            legendInvalidIcon('modal-product-variant-add-input-discount-value', 'modal-product-variant-add-container-validate-discount-value-required')
          }
          if (variantValue.discount.quantity.length === 0) {
            legendInvalidIcon('modal-product-variant-add-input-discount-quantity', 'modal-product-variant-add-container-validate-discount-quantity-required')
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeDescription = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, description: event.target.value})
  }

  const handleChangeCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, product_variant_code: event.target.value})
  }

  const handleChangeSku = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, sku: event.target.value})
  }

  const handleChangeCodebar = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, codebar: event.target.value})
  }

  const handleChangeSize = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, size: event.target.value})
  }

  const handleChangeColor = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, color: event.target.value})
  }

  const handleChangeUnit = (item: Unit) => {
    setVariantValue({...variantValue, unit: item})
    setVariantValid({...variantValid, unit: true})
  }

  const handleChangeTaxRate = (item: string) => {
    setVariantValue({...variantValue, tax_rate: item})
    setVariantValid({...variantValid, tax_rate: true})
  }

  const handleChangePrice = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, price: event.target.value})
  }

  const handleChangeDiscountType = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, discount: {type: event.target.value, value: (event.target.value === 'percentage') ? '0' : '', quantity: (event.target.value === 'none') ? '' : variantValue.discount.quantity}})
    setVariantValid({...variantValid, discount: {type: true, value: (event.target.value === 'none') ? true : false, quantity: (event.target.value === 'none') ? true : false}})
  }

  const handleChangeSlideDiscountValue = (item: number | number[]) => {
    setVariantValue({...variantValue, discount: {...variantValue.discount, value: item.toString()}})
    setVariantValid({...variantValid, discount: {...variantValid.discount, value: true}})
  }

  const handleChangeInputDiscountValue = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, discount: {...variantValue.discount, value: event.target.value}})
  }

  const handleChangeInputDiscountQuantity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantValue({...variantValue, discount: {...variantValue.discount, quantity: event.target.value}})
  }

  const handleChangeFiles = async (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
    let archiveValueList: {view: string, file: any}[] = []
    let archiveValidList: {file: boolean, message: string}[] = []
    let archivesCapture: any = event.target.files
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576
    let images: Promise<any>[] = []

    for (let archiveCapture of archivesCapture) {
      if (!variantValue.photos.some((existingArchive) => existingArchive.file.name === archiveCapture.name)) {
        if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
          archiveValueList.push({view: '', file: archiveCapture})
          archiveValidList.push({file: false, message: lang.labels.invalidFileType})
        } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
          archiveValueList.push({view: '', file: archiveCapture})
          archiveValidList.push({file: false, message: lang.labels.fileSizeNotAllowed})
        } else {
          images.push(
            extractBase64(archiveCapture).then((image: any) => {
              archiveValueList.push({view: image.base, file: archiveCapture});
              archiveValidList.push({file: true, message: lang.labels.valid});
            })
          )
        }
      }
    }

    await Promise.all(images)

    setVariantValue({...variantValue, photos: [...variantValue.photos, ...archiveValueList]})
    setVariantValid({...variantValid, photos: [...variantValid.photos, ...archiveValidList], gallery: ([...variantValid.photos, ...archiveValidList].length > 10) ? false : true})
    cleanInputFile()

    if ([...variantValid.photos, ...archiveValidList].length > 10) {
      legendInvalid('modal-product-variant-add-container-validate-gallery-valid')
    } else {
      legendValid('modal-product-variant-add-container-validate-gallery-valid')
    }
  }

  const validateDescription = () => {
    if (expressions) {
      if (expressions.text.test(variantValue.description)) {
        setVariantValid({...variantValid, description: true})
        legendValidRequiredIcon('modal-product-variant-add-input-description', 'modal-product-variant-add-container-validate-description-valid', 'modal-product-variant-add-container-validate-description-required')
      } else {
        setVariantValid({...variantValid, description: false})
        legendInvalidEmptyIcon(variantValue.description, 'modal-product-variant-add-input-description', 'modal-product-variant-add-container-validate-description-valid', 'modal-product-variant-add-container-validate-description-required')
      }
    }
  }

  const validateCode = () => {
    if (expressions && variantValue.product_variant_code.length > 0) {
      if (expressions.code.test(variantValue.product_variant_code)) {
        setVariantValid({...variantValid, product_variant_code: true})
        legendValidIcon('modal-product-variant-add-input-variant-code', 'modal-product-variant-add-container-validate-variant-code-valid')
      } else {
        setVariantValid({...variantValid, product_variant_code: false})
        legendInvalidIcon('modal-product-variant-add-input-variant-code', 'modal-product-variant-add-container-validate-variant-code-valid')
      }
    } else if (variantValue.product_variant_code.length === 0) {
      setVariantValid({...variantValid, product_variant_code: true})
      legendValidInvalidIconRestart('modal-product-variant-add-input-variant-code', 'modal-product-variant-add-container-validate-variant-code-valid')
    }
  }

  const validateSku = () => {
    if (expressions && variantValue.sku.length > 0) {
      if (expressions.sku.test(variantValue.sku)) {
        setVariantValid({...variantValid, sku: true})
        legendValidIcon('modal-product-variant-add-input-sku', 'modal-product-variant-add-container-validate-sku-valid')
      } else {
        setVariantValid({...variantValid, sku: false})
        legendInvalidIcon('modal-product-variant-add-input-sku', 'modal-product-variant-add-container-validate-sku-valid')
      }
    } else if (variantValue.sku.length === 0) {
      setVariantValid({...variantValid, sku: true})
      legendValidInvalidIconRestart('modal-product-variant-add-input-sku', 'modal-product-variant-add-container-validate-sku-valid')
    }
  }

  const validateCodebar = () => {
    if (expressions && variantValue.codebar.length > 0) {
      if (expressions.codebar.test(variantValue.codebar)) {
        setVariantValid({...variantValid, codebar: true})
        legendValidIcon('modal-product-variant-add-input-codebar', 'modal-product-variant-add-container-validate-codebar-valid')
      } else {
        setVariantValid({...variantValid, codebar: false})
        legendInvalidIcon('modal-product-variant-add-input-codebar', 'modal-product-variant-add-container-validate-codebar-valid')
      }
    } else if (variantValue.codebar.length === 0) {
      setVariantValid({...variantValid, codebar: true})
      legendValidInvalidIconRestart('modal-product-variant-add-input-codebar', 'modal-product-variant-add-container-validate-codebar-valid')
    }
  }

  const validateSize = () => {
    if (expressions && variantValue.size.length > 0) {
      if (expressions.size.test(variantValue.size)) {
        setVariantValid({...variantValid, size: true})
        legendValidIcon('modal-product-variant-add-input-size', 'modal-product-variant-add-container-validate-size-valid')
      } else {
        setVariantValid({...variantValid, size: false})
        legendInvalidIcon('modal-product-variant-add-input-size', 'modal-product-variant-add-container-validate-size-valid')
      }
    } else if (variantValue.size.length === 0) {
      setVariantValid({...variantValid, size: true})
      legendValidInvalidIconRestart('modal-product-variant-add-input-size', 'modal-product-variant-add-container-validate-size-valid')
    }
  }

  const validateColor = () => {
    if (expressions && variantValue.color.length > 0) {
      if (expressions.color.test(variantValue.color)) {
        setVariantValid({...variantValid, color: true})
        legendValidIcon('modal-product-variant-add-input-color', 'modal-product-variant-add-container-validate-color-valid')
      } else {
        setVariantValid({...variantValid, color: false})
        legendInvalidIcon('modal-product-variant-add-input-color', 'modal-product-variant-add-container-validate-color-valid')
      }
    } else if (variantValue.color.length === 0) {
      setVariantValid({...variantValid, color: true})
      legendValidInvalidIconRestart('modal-product-variant-add-input-color', 'modal-product-variant-add-container-validate-color-valid')
    }
  }

  const validatePrice = () => {
    if (expressions) {
      if (expressions.currency.test(variantValue.price)) {
        setVariantValid({...variantValid, price: true})
        legendValidRequiredIcon('modal-product-variant-add-input-price', 'modal-product-variant-add-container-validate-price-valid', 'modal-product-variant-add-container-validate-price-required')
      } else {
        setVariantValid({...variantValid, price: false})
        legendInvalidEmptyIcon(variantValue.price, 'modal-product-variant-add-input-price', 'modal-product-variant-add-container-validate-price-valid', 'modal-product-variant-add-container-validate-price-required')
      }
    }
  }

  const validateDiscountValue = () => {
    if (expressions) {
      if (expressions.currency.test(variantValue.discount.value)) {
        setVariantValid({...variantValid, discount: {...variantValid.discount, value: true}})
        legendValidRequiredIcon('modal-product-variant-add-input-discount-value', 'modal-product-variant-add-container-validate-discount-value-valid', 'modal-product-variant-add-container-validate-discount-value-required')
      } else {
        setVariantValid({...variantValid, discount: {...variantValid.discount, value: false}})
        legendInvalidEmptyIcon(variantValue.discount.value, 'modal-product-variant-add-input-discount-value', 'modal-product-variant-add-container-validate-discount-value-valid', 'modal-product-variant-add-container-validate-discount-value-required')
      }
    }
  }

  const validateDiscountQuantity = () => {
    if (expressions) {
      if (expressions.quantity.test(variantValue.discount.quantity)) {
        setVariantValid({...variantValid, discount: {...variantValid.discount, quantity: true}})
        legendValidRequiredIcon('modal-product-variant-add-input-discount-quantity', 'modal-product-variant-add-container-validate-discount-quantity-valid', 'modal-product-variant-add-container-validate-discount-quantity-required')
      } else {
        setVariantValid({...variantValid, discount: {...variantValid.discount, quantity: false}})
        legendInvalidEmptyIcon(variantValue.discount.quantity, 'modal-product-variant-add-input-discount-quantity', 'modal-product-variant-add-container-validate-discount-quantity-valid', 'modal-product-variant-add-container-validate-discount-quantity-required')
      }
    }
  }

  const executeRemoveFile = (index: number) => {
    let tempVariantsValue = variantValue.photos.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempVariantsValid = variantValid.photos.filter((_temp_item, temp_index) => (temp_index !== index))

    setVariantValue({...variantValue, photos: tempVariantsValue})
    setVariantValid({...variantValid, photos: tempVariantsValid, gallery: ((tempVariantsValid.length > 10) ? false : true)})
    cleanInputFile()

    if (tempVariantsValid.length > 10) {
      legendInvalid('modal-product-variant-add-container-validate-gallery-valid')
    } else {
      legendValid('modal-product-variant-add-container-validate-gallery-valid')
    }
  }

  const executeHideModalProductVariantAdd = () => {
    modalHide('modal-product-variant-add')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function validateAttributes(item: any) {
    for (let key in item) {
      if (typeof item[key] === 'object') {
        if (Array.isArray(item[key])) {
          for (let i = 0; i < item[key].length; i++) {
            if (!validateAttributes(item[key][i])) {
              return false
            }
          }
        } else {
          if (!validateAttributes(item[key])) {
            return false
          }
        }
      } else {
        if (!item[key]) {
          return false
        }
      }
    }
    return true
  }

  function unitsProductForCompany(units: Unit[]): Unit[] {
    return units.filter((item: Unit) => (item.type === 'product'))
  }

  function cleanInputFile() {
    Array.from(document.querySelectorAll('input[name="photos"]')).forEach( (node)  => {
      let element = node as HTMLInputElement
      element.value = ''
    })
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-product-variant-add-input-description', 'modal-product-variant-add-container-validate-description-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-description', 'modal-product-variant-add-container-validate-description-required')
    legendValidInvalidIconRestart('modal-product-variant-edit-input-code', 'modal-product-variant-edit-container-validate-code-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-sku', 'modal-product-variant-add-container-validate-sku-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-codebar', 'modal-product-variant-add-container-validate-codebar-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-size', 'modal-product-variant-add-container-validate-size-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-color', 'modal-product-variant-add-container-validate-color-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-price', 'modal-product-variant-add-container-validate-price-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-price', 'modal-product-variant-add-container-validate-price-required')
    legendValidInvalidIconRestart('modal-product-variant-add-input-discount-value', 'modal-product-variant-add-container-validate-discount-value-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-discount-value', 'modal-product-variant-add-container-validate-discount-value-required')
    legendValidInvalidIconRestart('modal-product-variant-add-input-discount-quantity', 'modal-product-variant-add-container-validate-discount-quantity-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-discount-quantity', 'modal-product-variant-add-container-validate-discount-quantity-required')
    legendValid('modal-product-variant-add-container-validate-gallery-valid')

    setVariantValue({description: '', product_variant_code: '', sku: '', codebar: '', size: '', color: '', tax_rate: '', price: '', unit: {id: '', type: '', name: '', symbol: ''}, discount: {type: 'none', value: '', quantity: ''}, photos: []})
    setVariantValid({description: false, product_variant_code: true, sku: true, codebar: true, size: true, color: true, tax_rate: true, price: false, unit: true, gallery: true, discount: {type: true, value: true, quantity: true}, photos: []})
    uploadTooltip()
  }

  return (
    <div id="modal-product-variant-add" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Add New Product Variant</h2>
          </div>
          <div className="modal-body mx-5 mx-xl-10">
            { settingsForCompany
              ?
              <div className="form" onLoad={uploadTooltip}>
                <div className="mb-5">
                  <div className="d-flex align-items-center mb-2">
                    <label className="form-label m-0">Code</label>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title="Unique product code assigned by the company"><FaExclamationCircle /></i>
                  </div>
                  <input id="modal-product-variant-edit-input-variant-code" className="form-control form-control-solid mb-2" type="text" name="code" value={variantValue.product_variant_code} onChange={handleChangeCode} onKeyUp={validateCode} onBlur={validateCode} />
                  <Legend property={variantValid.product_variant_code.toString()}>
                    <div id="modal-product-variant-edit-container-validate-variant-code-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="code" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                  </Legend>
                </div>
                <div className="mb-5">
                  <label className="form-label required">Description</label>
                  <input id="modal-product-variant-edit-input-description" className="form-control form-control-solid mb-2" type="text" name="description" value={variantValue.description} onChange={handleChangeDescription} onKeyUp={validateDescription} onBlur={validateDescription} />
                  <Legend property={variantValid.description.toString()}>
                    <div id="modal-product-variant-edit-container-validate-description-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="description" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                    <div id="modal-product-variant-edit-container-validate-description-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="description" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                    </div>
                  </Legend>
                </div>
                <div className="d-flex flex-wrap gap-5 mb-5">
                  <div className="fv-row w-100 flex-md-root">
                    <label className="form-label">SKU</label>
                    <input id="modal-product-variant-edit-input-sku" className="form-control form-control-solid mb-2" type="text" name="sku" value={variantValue.sku} onChange={handleChangeSku} onKeyUp={validateSku} onBlur={validateSku} />
                    <Legend property={variantValid.sku.toString()}>
                      <div id="modal-product-variant-edit-container-validate-sku-valid" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="sku" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                    </Legend>
                  </div>
                  <div className="fv-row w-100 flex-md-root">
                    <label className="form-label">Codebar</label>
                    <input id="modal-product-variant-edit-input-codebar" className="form-control form-control-solid mb-2" type="text" name="codebar" value={variantValue.codebar} onChange={handleChangeCodebar} onKeyUp={validateCodebar} onBlur={validateCodebar} />
                    <Legend property={variantValid.codebar.toString()}>
                      <div id="modal-product-variant-edit-container-validate-codebar-valid" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="codebar" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                    </Legend>
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-5 mb-5">
                  <div className="fv-row w-100 flex-md-root">
                    <label className="form-label">Size</label>
                    <input id="modal-product-variant-edit-input-size" className="form-control form-control-solid mb-2" type="text" name="size" value={variantValue.size} onChange={handleChangeSize} onKeyUp={validateSize} onBlur={validateSize} />
                    <Legend property={variantValid.size.toString()}>
                      <div id="modal-product-variant-edit-container-validate-size-valid" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="size" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                    </Legend>
                  </div>
                  <div className="fv-row w-100 flex-md-root">
                    <label className="form-label">Color</label>
                    <input id="modal-product-variant-edit-input-color" className="form-control form-control-solid mb-2" type="text" name="color" value={variantValue.color} onChange={handleChangeColor} onKeyUp={validateColor} onBlur={validateColor} />
                    <Legend property={variantValid.color.toString()}>
                      <div id="modal-product-variant-edit-container-validate-color-valid" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="color" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                    </Legend>
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-5 mb-5">
                  <div className="fv-row w-100 flex-md-root">
                    <label className="form-label">{lang.labels.unitMeasure}</label>
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid bg-light" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {variantValue.unit.id.length > 0 ? `${measureUnitSelected(lang, settingsForCompany.measure_units, variantValue.unit.type, variantValue.unit.id)}` : `${"Select..."}`}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              {/* <li className="select2-results__option select2-results__option--selectable">
                                <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => executeShowModalSettingsCreateUnit(item.type, index, sub_index)}>
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-2 me-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                    {lang.labels.addUnit}
                                  </div>
                                </Dropdown.Item>
                              </li> */}
                              <li className={`select2-results__option select2-results__option--selectable ${unitsProductForCompany(settingsForCompany.measure_units).length > 0 && "d-none"}`}>
                                <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2">{lang.labels.selectOption}</Dropdown.Item>
                              </li>
                              { unitsProductForCompany(settingsForCompany.measure_units).map (( (other_item, other_index) => { return (
                                <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === variantValue.unit.id && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === variantValue.unit.id}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => handleChangeUnit(other_item)}>{other_item.name}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="fv-row w-100 flex-md-root">
                    <label className="form-label">{lang.labels.taxRate}</label>
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid bg-light" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder text-capitalize">
                                {variantValue.tax_rate.length > 0 ? `${variantValue.tax_rate}` : `${"Select..."}`}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              {/* <li className="select2-results__option select2-results__option--selectable d-none">
                                <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => executeShowModalSettingsCreateTax(index, sub_index)}>
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-2 me-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                    {lang.labels.addTax}
                                  </div>
                                </Dropdown.Item>
                              </li> */}
                              { settingsForCompany.tax_fees.map (( (other_item, other_index) => { return (
                                <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.toString() === variantValue.tax_rate && "select2-results__option--selected"}`} role="option" aria-selected={other_item.toString() === variantValue.tax_rate}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2 text-capitalize" onClick={() => handleChangeTaxRate(other_item.toString())}>{other_item}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="mb-5">
                  <label className="form-label required">Price</label>
                  <input id="modal-product-variant-edit-input-price" className="form-control form-control-solid mb-2" type="text"  name="price" value={variantValue.price} onChange={handleChangePrice} onKeyUp={validatePrice} onBlur={validatePrice} />
                  <Legend property={variantValid.price.toString()}>
                    <div id="modal-product-variant-edit-container-validate-price-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="price" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                    <div id="modal-product-variant-edit-container-validate-price-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="price" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                    </div>
                  </Legend>
                </div>
                <div className="mb-10">
                  <div className="fv-row mb-5">
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-label m-0 required">Discount type</label>
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title="Select a discount type that will be applied to this product"><FaExclamationCircle /></i>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-5">
                      <div className="col">
                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${variantValue.discount.type === "none" && "active"}`}>
                          <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                            <input className="form-check-input" type="radio" value="none" checked={variantValue.discount.type === "none"}  onChange={handleChangeDiscountType} />
                          </span>
                          <span className="ms-5 fs-4 fw-bold text-gray-800 d-block">No Discount</span>
                        </label>
                      </div>
                      <div className="col">
                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${variantValue.discount.type === "percentage" && "active"}`}>
                          <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                            <input className="form-check-input" type="radio" value="percentage" checked={variantValue.discount.type === "percentage"}  onChange={handleChangeDiscountType} />
                          </span>
                          <span className="ms-5 fs-4 fw-bold text-gray-800 d-block">Percentage %</span>
                        </label>
                      </div>
                      <div className="col">
                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${variantValue.discount.type === "fixed" && "active"}`}>
                          <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                            <input className="form-check-input" type="radio" value="fixed" checked={variantValue.discount.type === "fixed"}  onChange={handleChangeDiscountType} />
                          </span>
                          <span className="ms-5 fs-4 fw-bold text-gray-800 d-block">Fixed Price</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  { variantValue.discount.type === "percentage" &&
                    <div className={`fv-row mb-8 ${variantValue.discount.type !== "percentage" && "d-none"}`}>
                      <label className="form-label required">Set discount percentage</label>
                      <div className="d-flex flex-column text-center">
                        <div className="d-flex align-items-start justify-content-center">
                          <span className="fw-bolder fs-2x">{variantValue.discount.value}</span>
                          <span className="fw-bold fs-4 mt-1 ms-2">%</span>
                        </div>
                        <Slider value={Number(variantValue.discount.value)} defaultValue={Number(variantValue.discount.value)} onChange={handleChangeSlideDiscountValue} onChangeComplete={handleChangeSlideDiscountValue} step={1} min={0} max={100} styles={{track: {height: 10, backgroundColor: "gray"}, rail: {height: 10, backgroundColor: "silver"}, handle: {height: 22, width: 22, opacity: 100, borderColor: "blue", backgroundColor: "black"}}} />
                      </div>
                    </div>
                  }
                  { variantValue.discount.type === "fixed" &&
                    <div className={`fv-row mb-5 ${variantValue.discount.type !== "fixed" && "d-none"}`}>
                      <label className="form-label required">Fixed discounted price</label>
                      <input id="modal-product-variant-edit-input-discount-value" className="form-control form-control-solid mb-2" type="text"  name="discount-value" value={variantValue.discount.value} onChange={handleChangeInputDiscountValue} onKeyUp={validateDiscountValue} onBlur={validateDiscountValue} />
                      <Legend property={variantValid.discount.value.toString()}>
                        <div id="modal-product-variant-edit-container-validate-discount-value-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="discount-value" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                        </div>
                        <div id="modal-product-variant-edit-container-validate-discount-value-required" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="discount-value" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                        </div>
                      </Legend>
                    </div>
                  }
                  { (variantValue.discount.type === "percentage" || variantValue.discount.type === "fixed") &&
                    <div className="fv-row">
                      <label className="form-label required">Quantity minimum for discount</label>
                      <input id="modal-product-variant-edit-input-discount-quantity" className="form-control form-control-solid mb-2" type="text"  name="discount-quantity" value={variantValue.discount.quantity} onChange={handleChangeInputDiscountQuantity} onKeyUp={validateDiscountQuantity} onBlur={validateDiscountQuantity} />
                      <Legend property={variantValid.discount.quantity.toString()}>
                        <div id="modal-product-variant-edit-container-validate-discount-quantity-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="discount-quantity" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                        </div>
                        <div id="modal-product-variant-edit-container-validate-discount-quantity-required" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="discount-quantity" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                        </div>
                      </Legend>
                    </div>
                  }
                </div>
                <div className="fv-row">
                  <div className="dropzone dz-clickable position-relative mb-2">
                    <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="photos" accept=".png, .jpg, .jpeg" multiple onChange={handleChangeFiles} />
                    { variantValue.photos.length > 0 && variantValid.photos.length > 0 && variantValue.photos.length === variantValid.photos.length
                      ?
                      <>
                        { variantValue.photos.map (( (item, index) => { return (
                          <div key={index} className="dz-preview dz-image-preview dz-error">
                            <div className="dz-image">
                              <img src={variantValid.photos[index].file ? item.view : imgDefault} className="img-fluid" alt={"photography-" + index} />
                            </div>
                            <div className="dz-details">
                              <div className="dz-size">
                                <span>{index + 1}</span>
                              </div>
                              <div className="dz-filename">
                                <span>{lang.labels.image}</span>
                              </div>
                            </div>
                            { !variantValid.photos[index].file &&
                              <div className="dz-error-message">
                                <span>{variantValid.photos[index].message}</span>
                              </div>
                            }
                            { variantValid.photos[index].file
                              ? 
                              <div className="dz-success-mark">
                                <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                  <title>Success</title>
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" strokeOpacity="0.198794158" stroke="#747474" fillOpacity="0.816519475" fill="#FFFFFF"></path>
                                  </g>
                                </svg>
                              </div>
                              :
                              <div className="dz-error-mark">
                                <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                  <title>Error</title>
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g stroke="#747474" strokeOpacity="0.198794158" fill="#FFFFFF" fillOpacity="0.816519475">
                                      <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            }
                            <button className="dz-remove" onClick={() => executeRemoveFile(index)}>{lang.labels.remove}</button>
                          </div>
                        )}))}
                      </>
                      :
                      <div className="dz-message">
                        <span className="svg-icon svg-icon-primary svg-icon-3x">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z" fill="black"/>
                            <path d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z" fill="black"/>
                          </svg>
                        </span>
                        <div className="ms-5">
                          <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload</h3>
                          <span className="fs-7 fw-semibold text-gray-400">Upload up to 10 files</span>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="text-muted fs-7">Set the product media gallery.</div>
                  <Legend property={variantValid.gallery.toString()}>
                    <div id="modal-product-variant-edit-container-validate-gallery-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="category" data-validator="max">Limit of 10 photos maximum</div>
                    </div>
                  </Legend>
                </div>
              </div>
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center text-center">
            <button className="btn btn-light me-3" type="reset" onClick={executeHideModalProductVariantAdd}>{lang.labels.discard}</button>
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitProductVariantAdd}>
              <span className="indicator-label">
                Add
              </span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalProductVariantAdd;
