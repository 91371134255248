import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { SettingsDA } from '../../services/settings.services';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Settings } from '../../models/settings.model';
import { Unit } from '../../models/unit.model';
import { Legend } from '../../styles/legend.style';
import { legendValidInvalidIconRestart, legendInvalidIcon, legendValid } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';
import { requirementTypeValue } from '../../libraries/requirement.type.library';

interface AppModalSettingCreateUnitProps {
  companyForUser: Company | undefined | null,
  newUnit: {type: string, index: number, subIndex: number} | null,
  requirements: {list: boolean, value: any[], valid: any[]},
  setSettingsForCompany: Dispatch<SetStateAction<Settings | undefined | null>>,
  setNewUnit: Dispatch<SetStateAction<{type: string, index: number, subIndex: number} | null>>,
  setRequirements: Dispatch<SetStateAction<{list: boolean, value: any[], valid: any[]}>>
};

let errorResponse: Error, settingsResponse: Settings;

const AppModalSettingCreateUnit: React.FunctionComponent<AppModalSettingCreateUnitProps> = ({companyForUser, newUnit, requirements, setSettingsForCompany, setNewUnit, setRequirements}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [symbol, setSymbol] = useState({value: '', valid: false})

  const submitSettingsParameterCreate = async () => {
    setLoadIndicator('on')

      if (companyForUser && newUnit && name.valid && symbol.valid) {
        let unit: {type: string, name: string, symbol: string} = {type: newUnit.type, name: name.value, symbol: symbol.value}

        await SettingsDA.putSettingsParameters(companyForUser.id, null, null, unit).then( (response) => {
          if (response.status === 200) {
            settingsResponse = response.data

            let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
              if (newUnit.index === temp_index) {
                temp_item.contents[newUnit.subIndex].unit = settingsResponse.measure_units.find((item: Unit) => (item.type === newUnit.type && item.name === unit.name && item.symbol === unit.symbol)) as Unit
              }
              return temp_item
            })
            let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
              if (newUnit.index === temp_index) {
                temp_item.contents[newUnit.subIndex].unit = true
              }
              return temp_item
            })

            setSettingsForCompany(settingsResponse)
            setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
            legendValid('container-validate-requirement-content-unit-required-' + newUnit.index + newUnit.subIndex)

            Swal.fire({
              title: lang.labels.successfullyCreatedCompanyParameters,
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalSettingUnitCreate()
            })
          } else {
            errorResponse = response.data

            Swal.fire({
              title: getError(errorResponse.code, lang.code),
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        }).catch( (error) => {
          console.error(error)
          window.location.href = '/error'
        })
      } else {
        Swal.fire({
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
          icon: 'error',
          showConfirmButton: false,
          timer: 1800
        } as SweetAlertOptions).then( () => {
          if (name.value.length === 0) {
            legendInvalidIcon('modal-settings-create-unit-input-name', 'modal-settings-create-unit-container-validate-name-required')
          }
          if (symbol.value.length === 0) {
            legendInvalidIcon('modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-container-validate-symbol-required')
          }
          setLoadIndicator('off')
        })
      }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeSymbol = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setSymbol({...symbol, value: event.target.value})
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.text, name, setName, 'modal-settings-create-unit-input-name', 'modal-settings-create-unit-container-validate-name-valid', 'modal-settings-create-unit-container-validate-name-required')
  }

  const validateSymbol = () => {
    evaluateLegendValidateRequiredIcon(expressions.text, symbol, setSymbol, 'modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-container-validate-symbol-valid', 'modal-settings-create-unit-container-validate-symbol-required')
  }

  const executeHideModalSettingUnitCreate = () => {
    modalHide('modal-settings-create-unit')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-settings-create-unit-input-name', 'modal-settings-create-unit-container-validate-name-valid')
    legendValidInvalidIconRestart('modal-settings-create-unit-input-name', 'modal-settings-create-unit-container-validate-name-required')
    legendValidInvalidIconRestart('modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-container-validate-symbol-valid')
    legendValidInvalidIconRestart('modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-container-validate-symbol-required')

    setNewUnit(null)
    setName({value: '', valid: false})
    setSymbol({value: '', valid: false})
  }

  return (
    <div id="modal-settings-create-unit" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addUnitMeasureParameter}</h2>
          </div>
          <div className="modal-body">
            { newUnit
              ?
              <div className="form">
                <div className="row mb-2">
                  <label className="col-lg-3 col-form-label required">{lang.labels.type}</label>
                  <div className="col-lg-9">
                    <input className="form-control form-control-solid is-valid" type="text" name="type" value={requirementTypeValue(lang, newUnit.type)} disabled />
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="col-lg-3 col-form-label required">{lang.labels.name}</label>
                  <div className="col-lg-9">
                    <input id="modal-settings-create-unit-input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                    <Legend property={name.valid.toString()}>
                      <div id="modal-settings-create-unit-container-validate-name-valid" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="name" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                      <div id="modal-settings-create-unit-container-validate-name-required" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="name" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                      </div>
                    </Legend>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-3 col-form-label required">{lang.labels.symbol}</label>
                  <div className="col-lg-9">
                    <input id="modal-settings-create-unit-input-symbol" className="form-control form-control-solid" type="text" name="symbol" value={symbol.value} onChange={handleChangeSymbol} onKeyUp={validateSymbol} onBlur={validateSymbol} />
                    <Legend property={symbol.valid.toString()}>
                      <div id="modal-settings-create-unit-container-validate-symbol-valid" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="symbol" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                      <div id="modal-settings-create-unit-container-validate-symbol-required" className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="symbol" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                      </div>
                    </Legend>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalSettingUnitCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitSettingsParameterCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalSettingCreateUnit;
