import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { modalHide } from '../../tools/modal.tool';
import { CategoryDA } from '../../services/category.service';
import { Product } from '../../models/product.model';
import { imageProduct } from '../../scripts/image.value.script';

interface AppModalCategoryUpdateProductsProps {
  companyForUser: Company | undefined | null,
  productsForCompany: Product[] | undefined | null,
  category: Category | undefined | null,
  categoryProducts: string[] | undefined | null,
  setCategory: Dispatch<SetStateAction<Category | undefined | null>>,
  setCategoryProducts: Dispatch<SetStateAction<string[] | undefined | null>>
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryUpdateProducts: React.FunctionComponent<AppModalCategoryUpdateProductsProps> = ({companyForUser, productsForCompany, category, categoryProducts, setCategory, setCategoryProducts}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitCategoryUpdate = async () => {
    setLoadIndicator('on')

    if (companyForUser && category && categoryProducts) {
      await CategoryDA.putCategoryProducts(companyForUser.id, category.id, categoryProducts).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data
          setCategory(categoryResponse)

          Swal.fire({
            title: 'Successfully updated category!',
            text: 'Updating company categories, returning to page',
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryUpdateProducts()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeCategoryProducts = (item: string) => {
    if (categoryProducts) {
      if (categoryProducts.includes(item)) {
        setCategoryProducts(categoryProducts.filter((temp_item) => (temp_item !== item)))
      } else {
        setCategoryProducts([...categoryProducts, item])
      }
    }
  }

  const executeHideModalCategoryUpdateProducts = () => {
    modalHide('modal-category-update-products')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    if (Array.from(document.querySelectorAll('input[name="products-list"]')).length > 0) {
      Array.from(document.querySelectorAll('input[name="products-list"]')).forEach((node) => {
        let element = node as HTMLInputElement
        element.checked = false
      })
    }

    setCategoryProducts([])
  }

  return (
    <div id="modal-category-update-products" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Update Category Product List</h2>
          </div>
          <div className="modal-body mx-5 mx-xl-8">
            <div className="fs-7 text-gray-700 text-justify mb-8">
              Check or uncheck the products you want to add or remove to category
              <strong className="mx-1">{category && category.display_name}</strong>
              .
            </div>
            <div className="mh-500px hover-scroll-y">
              { productsForCompany && categoryProducts
                ?
                <>
                  { productsForCompany.map (( (item, index) => { return (
                    <div key={index}>
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light">
                              <img src={imageProduct(item.photos[0])} alt="photography" className="w-75 h-75" />
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder fs-6">{item.display_name}</span>
                            <span className="fs-7 text-muted">Variants: {item.variants.length}</span>
                          </span>
                        </span>
                        <span className="form-check form-check-custom form-check-solid me-2">
                          <input className="form-check-input" type="checkbox" name="products-list" checked={categoryProducts.includes(item.id)} value={item.id} onClick={() => handleChangeCategoryProducts(item.id)} />
                        </span>
                      </label>
                    </div>
                  )}))}
                </>
                :
                <div className="w-100 h-50px">
                  <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">{lang.labels.loading}</span>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light me-3" type="reset" onClick={executeHideModalCategoryUpdateProducts}>Cancel</button>
              <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryUpdate}>
                <span className="indicator-label">Update</span>
                <span className="indicator-progress">
                  Please wait... 
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryUpdateProducts;
