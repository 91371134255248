import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextQuote } from './Quote';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { QuoteDA } from '../../../../services/quote.service';
import { Error } from '../../../../models/error.model';
import { Quote } from '../../../../models/quote.model';
import { QuoteGroup } from '../../../../models/quote.group.model';
import { colorStatusQuote, colorValidUntilQuote } from '../../../../scripts/record.badge.color.script';
import { accumulatedAmountLite } from '../../../../scripts/amount.script';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.script';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { nameSaleGroup } from '../../../../libraries/record.group.name.library';
import { statusRecordValue } from '../../../../libraries/status.record.library';

import SaleRecordList from '../card/SaleRecordList';

export interface QuoteListPageProps {};

let errorResponse: Error, quotesResponse: any;

const QuoteListPage: React.FunctionComponent<QuoteListPageProps> = props => {
  const {setRoute, companyForUser, contactsForCompany} = UseOutletContextQuote()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [button, setButton] = useState({filter: false, group: false})
  const [filter, setFilter] = useState({interval: '', startDate: '', endDate: '', contact: '', status: '', expiration: '', validity: '', payment: '', direction: '', order: '', group: ''})
  const [quotes, setQuotes] = useState<Quote[] | undefined | null>(null)
  const [quoteGroups, setQuoteGroups] = useState<QuoteGroup[] | undefined | null>(null)

  const loadQuotes = async (id_company: string) => {
    let filtered: boolean = false
    let grouped: boolean = false

    await QuoteDA.getQuotes(id_company, filter.startDate, filter.endDate, filter.contact, filter.status, filter.expiration, filter.direction, filter.order, filter.group).then( (response) => {
      if (response.status === 200) {
        quotesResponse = response.data

        filtered = (quotesResponse.filter.start_date === '' && quotesResponse.filter.end_date === '' && quotesResponse.filter.contact_id === '' && quotesResponse.filter.status === '' && quotesResponse.filter.expiration === '') ? false : true
        grouped = (quotesResponse.filter.group_by === '') ? false : true

        if (quotesResponse.filter.group_by === '') {
          setQuoteGroups([])
          setQuotes(quotesResponse.quotes)
        } else {
          setQuotes([])
          setQuoteGroups(quotesResponse.groups)
        }

        setButton({filter: filtered, group: grouped})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const executeSortList = (item: string) => {
    if (companyForUser) {
      if (item === filter.order) {
        switch (filter.direction) {
          case 'asc':
            filter.direction = 'desc'
            break;
          case 'desc':
            filter.direction = 'asc'
            break;
        }
      } else {
        filter.order = item
        filter.direction = 'asc'
      }
      loadQuotes(companyForUser.id)
    }
  }

  useEffect( () => {
    if (companyForUser) {
      filter.interval = ''
      filter.startDate = ''
      filter.endDate = ''
      filter.contact = ''
      filter.status = ''
      filter.expiration = ''
      filter.direction = ''
      filter.order = ''
      filter.group = ''

      setButton({filter: false, group: false})
      setQuotes(null)
      setQuoteGroups(null)

      loadQuotes(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.quotes, branch: lang.labels.listQuotes}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && contactsForCompany && quotes && quoteGroups
        ?
        <div className="card card-flush">
          <SaleRecordList sale="quote" button={button} filter={filter} records={quotes} recordGroups={quoteGroups} setFilter={setFilter} loadFunction={loadQuotes}></SaleRecordList>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-1">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-125px cursor-pointer text-nowrap" onClick={() => executeSortList("quote number")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.quoteNumber}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "quote number" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "quote number" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px cursor-pointer text-nowrap" onClick={() => executeSortList("concerning")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.concerning}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "concerning" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "concerning" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px cursor-pointer text-nowrap" onClick={() => executeSortList("customer")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.customer}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "customer" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "customer" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px cursor-pointer text-nowrap" onClick={() => executeSortList("treasury")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.treasury}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "treasury" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "treasury" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px cursor-pointer text-nowrap" onClick={() => executeSortList("created date")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.createdDate}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "created date" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "created date" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px cursor-pointer text-nowrap" onClick={() => executeSortList("valid until")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.validUntil}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "valid until" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "valid until" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px cursor-pointer text-nowrap" onClick={() => executeSortList("status")}>
                        <div className="d-flex align-items-center">
                          <span className="me-1">{lang.labels.status}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "status" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "status" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                      <th className="min-w-100px text-end cursor-pointer text-nowrap" onClick={() => executeSortList("amount total")}>
                        <div className="d-flex align-items-center justify-content-end">
                          <span className="me-1">{lang.labels.amountTotal}</span>
                          <span className="svg-icon svg-icon-3">
                            { filter.order === "amount total" && filter.direction === "asc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z" fill="black"/>
                              </svg>
                            }
                            { filter.order === "amount total" && filter.direction === "desc" &&
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  { quotes.length > 0 || quoteGroups.length > 0
                    ?
                    <>
                      { button.group
                        ?
                        <>
                          { quoteGroups.map (( (item, index) => { return (
                            <tbody key={index} className="text-gray-700 fw-bold">
                              <tr>
                                <td colSpan={8} className="text-gray-700 fw-bolder fs-7 text-uppercase">
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-3 svg-icon-gray-900 me-5">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M22 12C22 12.9 21.9 13.8 21.7 14.6L5 4.89999C6.8 3.09999 9.3 2 12 2C17.5 2 22 6.5 22 12Z" fill="black"/>
                                        <path opacity="0.3" d="M3.7 17.5C2.6 15.9 2 14 2 12C2 9.20003 3.1 6.70002 5 4.90002L9.3 7.40002V14.2L3.7 17.5ZM17.2 12L5 19.1C6.8 20.9 9.3 22 12 22C16.6 22 20.5 18.8 21.7 14.6L17.2 12Z" fill="black"/>
                                      </svg>
                                    </span>
                                    <span className="me-3">{lang.labels.group} {index + 1}:</span>
                                    {nameSaleGroup(lang, filter.group, item.group_detail)}
                                  </div>
                                </td>
                              </tr>
                              { item.quotes.map (( (sub_item, sub_index) => { return (
                                <tr key={sub_index} className="row-dashed">
                                  <td>
                                    <Link to={"/app/sale/quote/view/" + sub_item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{sub_item.quote_number}</Link>
                                  </td>
                                  <td>{sub_item.concerning}</td>
                                  <td>{sub_item.customer.type === "person" ? `${sub_item.customer.data.first_name} ${sub_item.customer.data.last_name}` : `${sub_item.customer.data.name}`}</td>
                                  <td>{sub_item.treasury_account.name}</td>
                                  <td className="text-nowrap">{dateFormatAbbreviated(sub_item.created_date)}</td>
                                  <td>
                                    <span className={`badge badge-light-${colorValidUntilQuote(sub_item.status, sub_item.valid_until)} fs-7`}>{dateFormatAbbreviated(sub_item.valid_until)}</span>
                                  </td>
                                  <td className="text-capitalize">
                                    <span className={`badge badge-light-${colorStatusQuote(sub_item.status)} fs-7`}>{statusRecordValue(lang, sub_item.status)}</span>
                                  </td>
                                  <td className="text-end text-nowrap">{sub_item.currency.symbol} {moneyFormat(sub_item.amount.total)}</td>
                                </tr>
                              )}))}
                              <tr className={`text-gray-800 fw-bolder fs-7 ${index < quoteGroups.length - 1 && "row-dashed"}`}>
                                <td colSpan={7}></td>
                                <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountLite(item.quotes).total)}</td>
                              </tr>
                            </tbody>
                          )}))}
                        </>
                        :
                        <>
                          <tbody className="text-gray-700 fw-bold">
                            { quotes.map (( (item, index) => { return (
                              <tr key={index} className="row-dashed">
                                <td>
                                  <Link to={"/app/sale/quote/view/" + item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{item.quote_number}</Link>
                                </td>
                                <td>{item.concerning}</td>
                                <td>{item.customer.type === "person" ? `${item.customer.data.first_name} ${item.customer.data.last_name}` : `${item.customer.data.name}`}</td>
                                <td>{item.treasury_account.name}</td>
                                <td className="text-nowrap">{dateFormatAbbreviated(item.created_date)}</td>
                                <td>
                                  <span className={`badge badge-light-${colorValidUntilQuote(item.status, item.valid_until)} fs-7`}>{dateFormatAbbreviated(item.valid_until)}</span>  
                                </td>
                                <td className="text-capitalize">
                                  <span className={`badge badge-light-${colorStatusQuote(item.status)} fs-7`}>{statusRecordValue(lang, item.status)}</span>
                                </td>
                                <td className="text-end text-nowrap">{item.currency.symbol} {moneyFormat(item.amount.total)}</td>
                              </tr>
                            )}))}
                          </tbody>
                          <tfoot>
                            <tr className="text-gray-800 fw-bolder fs-7">
                              <td colSpan={7}></td>
                              <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountLite(quotes).total)}</td>
                            </tr>
                          </tfoot>
                        </>
                      }
                    </>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={8} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default QuoteListPage;
