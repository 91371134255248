import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Template } from '../models/template.model';
import { Token } from '../services/token.service';

export class SettingsDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getSettings(id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/settings`
    let token = Token.getToken()
    let settingForCompany: any | null = {
      id_company: id_company,
      data: null
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { settingForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (settingForCompany.id_company === id_company) {
      this.dataResponse = settingForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postSettings(id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/settings`
    let token = Token.getToken()
    let settingsForCompany = {
      id_company: '',
      data: null
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, undefined, token).then(response => { settingsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (settingsForCompany && settingsForCompany!!.id_company === id_company) {
      this.dataResponse = settingsForCompany
      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putSettingsParameters(id_company: string, tax: string | null, deadline: { type: string, quantity: string } | null, unit: { type: string, name: string, symbol: string } | null): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/settings/parameters`
    let token = Token.getToken()
    let settingsForCompany = {
      id_company: '',
      data: null
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let dataSettings = {
      tax_fees: tax,
      payment_deadlines: deadline,
      measure_units: unit
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.put(url, dataSettings, token).then(response => { settingsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (settingsForCompany && settingsForCompany!!.id_company === id_company) {
      this.dataResponse = settingsForCompany.data
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putSettings(id_company: string, template: Template, colors: string[], taxes: string[], deadlines: { id: string, type: string, quantity: string }[], units: { id: string, type: string, name: string, symbol: string }[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/settings`
    let token = Token.getToken()
    let settingForCompany: any | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let dataSettings = {
      tax_fees: taxes,
      payment_deadlines: deadlines,
      measure_units: units,
      template_current: template,
      colors
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataSettings, token).then(response => { settingForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (settingForCompany!!.id_company === id_company) {
      this.dataResponse = settingForCompany.data
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
