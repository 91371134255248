import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Cookies from 'universal-cookie';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { AuthenticationDL } from '../../services/authentication.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { Legend } from '../../styles/legend.style';
import { iconEye, iconEyeSlash } from '../../tools/icon.visibility.tool';
import { legendInvalid, legendInvalidIcon } from '../../tools/legend.data.entry.tool';
import { validateInputPasswordConfirmation, validateInputPasswordController } from '../../scripts/validate.input.password.script';
import { expressions } from '../../libraries/regular.expressions.library';

export interface ResetPageProps {};

let errorResponse: Error, messageResponse: Message;

const ResetPage: React.FunctionComponent<ResetPageProps> = props => {
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [typeInput, setTypeInput] = useState('password')
  const [password, setPassword] = useState({value: '', valid: false})
  const [passwordConfirmation, setPasswordConfirmation] = useState({value: '', valid: false})

  const submitReset = async (event: React.ChangeEvent <HTMLFormElement>) => {
    event.preventDefault()
    setLoadIndicator('on')

    if (password.valid && passwordConfirmation.valid) {
      let email: string = (param.email) ? param.email : ''
      let token: string = (param.token) ? param.token : ''

      await AuthenticationDL.reset(email, password.value, passwordConfirmation.value, token).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          cookies.set('email', email, {path: '/', sameSite: 'lax'})

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.youHaveSuccessfullyResetYourPassword,
            icon: 'success',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/auth/login', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (password.value.length === 0) {
          legendInvalid('container-validate-password-required')
        }
        if (passwordConfirmation.value.length === 0) {
          legendInvalidIcon('input-password-confirmation', 'container-validate-confirmation-password-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangePassword = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPassword({ ...password, value: event.target.value })
  }

  const handleChangePasswordConfirmation = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPasswordConfirmation({...passwordConfirmation , value: event.target.value})
  }

  const handleChangeVisibilityPassword = () => {
    if (typeInput === 'password') {
      setTypeInput('text')
      iconEye('icon-password-visibility')
    } else {
      setTypeInput('password')
      iconEyeSlash('icon-password-visibility')
    }
  }

  const validatePassword = () => {
    validateInputPasswordController(password, setPassword, 'container-validate-password-valid', 'container-validate-password-required', 'container-password-meter-control-1', 'container-password-meter-control-2', 'container-password-meter-control-3', 'container-password-meter-control-4', 'container-password-meter-control-5')
    validateInputPasswordConfirmation(true, password, passwordConfirmation, setPasswordConfirmation, 'input-password-confirmation', 'container-validate-confirmation-password-identical', 'container-validate-confirmation-password-valid', 'container-validate-confirmation-password-required')
  }

  const validatePasswordConfirmation = () => {
    validateInputPasswordConfirmation(false, password, passwordConfirmation, setPasswordConfirmation, 'input-password-confirmation', 'container-validate-confirmation-password-identical', 'container-validate-confirmation-password-valid', 'container-validate-confirmation-password-required')
  }

  function email(parameter: string | undefined | null): string {
    return (expressions && parameter && expressions.email.test(parameter)) ? parameter : lang.labels.undefined.toLowerCase()
  }

  useEffect( () => {
    setMounted(true)

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-lg-550px bg-body rounded shadow-sm p-10 mx-auto">
      <form className="form w-100" noValidate onSubmit={submitReset}>
        <div className="text-center mb-10">
          <h1 className="text-dark text-capitalize mb-3">{lang.labels.resetPassword}</h1>
          <div className="text-gray-400 fw-bold fs-4">
            {lang.labels.enterTheNewPasswordForTheMail}
            <span className="link-primary fw-bolder ms-1">{email(param.email)}</span>
            .
          </div>
        </div>
        <div className="fv-row mb-5">
          <div className="mb-0">
            <label className="form-label fs-6 fw-bolder text-dark">{lang.labels.password}</label>
            <div className="position-relative mb-2">
              <input id="input-password" className="form-control form-control-lg form-control-solid" name="password" autoComplete="off" type={typeInput} value={password.value} onChange={handleChangePassword} onKeyUp={validatePassword} onBlur={validatePassword} />
              <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
                <i id="icon-password-visibility" className="bi bi-eye-slash fs-2" onClick={handleChangeVisibilityPassword}></i>
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <div id="container-password-meter-control-1" className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div id="container-password-meter-control-2" className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div id="container-password-meter-control-3" className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div id="container-password-meter-control-4" className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div id="container-password-meter-control-5" className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
          </div>
          <div className="text-muted">{lang.labels.use8OrMoreCharacters}</div>
          <Legend property={password.valid.toString()}>
            <div id="container-validate-password-valid" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="password" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
            </div>
            <div id="container-validate-password-required" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="password" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
            </div>
          </Legend>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">{lang.labels.confirmPassword}</label>
          <input id="input-password-confirmation" className="form-control form-control-lg form-control-solid" type="password" name="password-confirmation" autoComplete="off" value={passwordConfirmation.value} onChange={handleChangePasswordConfirmation} onKeyUp={validatePasswordConfirmation} onBlur={validatePasswordConfirmation} />
          <Legend property={passwordConfirmation.valid.toString()}>
            <div id="container-validate-confirmation-password-identical" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="confirm-password" data-validator="confirmation">{lang.labels.theValueDoesNotMatch}</div>
            </div>
            <div id="container-validate-confirmation-password-valid" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="confirm-password" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
            </div>
            <div id="container-validate-confirmation-password-required" className="fv-plugins-message-container invalid-feedback d-none">
              <div data-field="confirm-password" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
            </div>
          </Legend>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          <button className="btn btn-lg btn-primary me-4" type="submit" data-kt-indicator={loadIndicator}>
            <span className="indicator-label fw-bolder">{lang.labels.submit}</span>
            <span className="indicator-progress">
              {lang.labels.pleaseWait}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          <Link to="/auth/login" className="btn btn-lg btn-light-primary fw-bolder">{lang.labels.cancel}</Link>
        </div>
      </form>
    </div>
  )
};

export default ResetPage;
