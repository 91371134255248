import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Category } from '../models/category.model';
import { Names } from '../models/names.model';
import { Product } from '../models/product.model';
import { Token } from '../services/token.service';

export class CategoryDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getCategories(id_company: string, name_category: string, order_direction: string, order_by: string): Promise<ApiResponse> {

    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/categories?name=${name_category}&order_direction=${order_direction}&order_by=${order_by}`
    let token = Token.getToken()
    let categoriesByCompany: any | null = {
      id_company: id_company,
      filter: {
        name_category: name_category,
        order_direction: order_direction,
        order_by: order_by,
      },
      categories: []
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token)
      .then(response => { categoriesByCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (categoriesByCompany.id_company === id_company) {

      this.dataResponse = categoriesByCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getCategory(id_company: string, id_category: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/categories/${id_category}`
    let token = Token.getToken()

    let categoryResponse = ''
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { categoryResponse = response.data.category })
      .catch(errorR => { errorResponse = errorR.response.data.error })


    if (categoryResponse) {
      this.dataResponse = categoryResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postCategory(id_company: string, display_name: string, description: string, names: Names): Promise<ApiResponse> {

    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/categories`
    let token = Token.getToken()
    let category: Category | null = null

    let dataCategory = {
      display_name,
      description,
      names
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataCategory, token)
      .then(response => { category = response.data.category })
      .catch(errorR => { errorResponse = errorR.response.data.error })


    if (category) {
      this.dataResponse = category

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putCategory(id_company: string, id_category: string, display_name: string, description: string, names: Names): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/categories/${id_category}`
    let token = Token.getToken()
    let category: Category | null = null

    let dataCategory = {
      display_name,
      description,
      names
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataCategory, token)
      .then(response => { category = response.data.category })
      .catch(errorR => { errorResponse = errorR.response.data.error })


    if (category) {
      this.dataResponse = category

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putCategoryProducts(id_company: string, id_category: string, id_products: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/categories/${id_category}/products`
    let token = Token.getToken()
    let category: Category | null = null

    let dataCategories = {
      ids_products: id_products
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    await axios.put(url, dataCategories, token)
      .then(response => { category = response.data.category })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (category) {

      this.dataResponse = category

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deleteCategories(id_company: string, id_categories: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/categories`
    let token = Token.getTokenDataCategories(id_categories)

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.delete(url, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === "Successfully deleted categories") {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
