import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { User } from '../models/user.model';
import { Token } from '../services/token.service';

export class AuthenticationDL {
  static apiResponse: ApiResponse
  static dataResponse: any
  static apiKey = '23141630-72d2-11ea-8ud6-2f54e765ma0a'

  public static async login(api_key: string, email: string, password: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/login`
    let loginResponse: Token | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    const userDataLogin = { email: email, password: password }
    await axios.post(url, userDataLogin).then(response => { loginResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (loginResponse) {
      this.dataResponse = loginResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {

      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async register(first_name: string, last_name: string, email: string, phone: string, password: string, password_confirmation: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${this.apiKey}/register`
    let messageResponse: Message | null = {
      message: ''
    }

    let error: Error = {
      code: 0,
      message: ''
    }
    const userDataRegister = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      email: email,
      password: password,
      password_confirmation: password_confirmation
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, userDataRegister).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully created user') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Created',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async recover(email: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}password/request`
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    const dataRecover = { email }
    await axios.post(url, dataRecover).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successful request') {
      this.dataResponse = messageResponse
      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async reset(email: string, password: string, password_confirmation: string, token: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}password/reset`
    let users: User[] = []

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    const data = {
      password,
      password_confirmation,
      password_token: token
    }

    await axios.post(url, data).then(response => { messageResponse = response.data })

    if (messageResponse && messageResponse.message === 'Successfully updated password') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async verify(api_key: string, email: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/reset/token/email`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, null, token)
      .then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successful request') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
