import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/translations/response';
import { AuthenticationDL } from '../../../services/authentication.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { User } from '../../../models/user.model';
import { Service } from '../../../models/service.model';
import { percentageCalculate } from '../../../scripts/percentage.script';
import { imageUser } from '../../../scripts/image.value.script';

export interface AccountPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  serviceForUser: Service | undefined | null,
  reloadUserLoggedIn: Function
};

let errorResponse: Error, messageResponse: Message;

const AccountPage: React.FunctionComponent<AccountPageProps> = props => {
  const {setRoute, userLoggedIn, serviceForUser, reloadUserLoggedIn} = UseOutletContext()
  const {lang} = StateLanguage()
  const param = useParams()
  const location = useLocation()
  
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [quantityServices, setQuantityServices] = useState(0)
  const [quantityCompanies, setQuantityCompanies] = useState(0)

  const submitVerifyAccount = async () => {
    if (userLoggedIn && serviceForUser) {
      Swal.fire({
        title: lang.labels.accountNotVerifiedYet,
        text: lang.labels.toValidateYourAccount,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesSend,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadIndicator('on')

          await AuthenticationDL.verify(serviceForUser.api_key, userLoggedIn.email).then( (response) => {
            if (response.status === 201) {
              messageResponse = response.data

              Swal.fire({
                title: getMessage(messageResponse.message, lang.code),
                text: lang.labels.pleaseCheckYourEmailInbox,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
              })
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  function viewCardAccount(): boolean {
    if (param.language && `/${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}` === '/app/account' && (`/${location.pathname.split('/')[4]}` === '/view' || `/${location.pathname.split('/')[4]}` === '/update')) {
      return true
    } else if (location.pathname === '/app/account/view' || location.pathname === '/app/account/update') {
      return true
    } else {
      return false
    }
  }

  function profileCompletation(): string {
    if (userLoggedIn) {
      let totalAttributes: number = 8
      let countAttributes: number = 0

      if (userLoggedIn.verified) { countAttributes += 1 }
      if (userLoggedIn.avatar.length > 0) { countAttributes += 1 }
      if (userLoggedIn.first_name.length > 0) { countAttributes += 1 }
      if (userLoggedIn.last_name.length > 0) { countAttributes += 1 }
      if (userLoggedIn.email.length > 0) { countAttributes += 1 }
      if (userLoggedIn.phone.length > 0) { countAttributes += 1 }
      if (userLoggedIn.services.length > 0) { countAttributes += 1 }
      if (userLoggedIn.companies.length > 0) { countAttributes += 1 }

      return percentageCalculate(totalAttributes, countAttributes)
    } else {
      return '0%'
    }
  }

  useEffect( () => {
    const totalServices: number = (userLoggedIn) ? userLoggedIn.services.length : 0
    const totalCompanies: number = (userLoggedIn) ? userLoggedIn.companies.length : 0

    const intervalServices = setInterval(() => {
      setQuantityServices((prevCount) => {
        if (prevCount >= totalServices) {
          clearInterval(intervalServices);
          return prevCount
        }
        return prevCount + 1
      })
    }, 100)

    const intervalCompanies = setInterval(() => {
      setQuantityCompanies((prevCount) => {
        if (prevCount >= totalCompanies) {
          clearInterval(intervalCompanies);
          return prevCount
        }
        return prevCount + 1
      })
    }, 100)

    return () => {
      clearInterval(intervalServices)
      clearInterval(intervalCompanies)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-100 h-100">
      { userLoggedIn
        ?
        <>
          { viewCardAccount() &&
            <div className="card mb-5">
              <div className="card-body pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-2">
                  <div className="me-5 mb-2">
                    <div className="symbol symbol-100px symbol-lg-150px symbol-fixed position-relative">
                      <img src={imageUser(userLoggedIn.avatar)} alt={lang.labels.avatar} />
                      <div className={`position-absolute translate-middle bottom-0 start-100 rounded-circle border border-3 border-white h-20px w-20px ${userLoggedIn.verified ? "bg-success" : "bg-warning"}`}></div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-3">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-1">
                          <label className="text-gray-800 fs-2 fw-bolder me-2">{userLoggedIn.first_name} {userLoggedIn.last_name}</label>
                          <span className="svg-icon svg-icon-1 svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                              <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                              <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                            </svg>
                          </span>
                        </div>
                        <div className="d-flex flex-wrap fw-bold text-gray-400 fs-6 mb-2">
                          <div className="d-flex align-items-center me-5 mb-2">
                            <span className="svg-icon svg-icon-3 me-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black" />
                                <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black" />
                              </svg>
                            </span>
                            {lang.labels.user}
                          </div>
                          <div className="d-flex align-items-center me-5 mb-2">
                            <span className="svg-icon svg-icon-3 me-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"/>
                                <path d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z" fill="black"/>
                              </svg>
                            </span>
                            {userLoggedIn.uuid}
                          </div>
                        </div>
                      </div>
                      <div className={userLoggedIn.verified ? "d-none": "d-flex"}>
                        <button className="btn btn-primary" type="button" onClick={submitVerifyAccount} data-kt-indicator={loadIndicator}>
                          <span className="svg-icon svg-icon-1 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M3 6C2.4 6 2 5.6 2 5V3C2 2.4 2.4 2 3 2H5C5.6 2 6 2.4 6 3C6 3.6 5.6 4 5 4H4V5C4 5.6 3.6 6 3 6ZM22 5V3C22 2.4 21.6 2 21 2H19C18.4 2 18 2.4 18 3C18 3.6 18.4 4 19 4H20V5C20 5.6 20.4 6 21 6C21.6 6 22 5.6 22 5ZM6 21C6 20.4 5.6 20 5 20H4V19C4 18.4 3.6 18 3 18C2.4 18 2 18.4 2 19V21C2 21.6 2.4 22 3 22H5C5.6 22 6 21.6 6 21ZM22 21V19C22 18.4 21.6 18 21 18C20.4 18 20 18.4 20 19V20H19C18.4 20 18 20.4 18 21C18 21.6 18.4 22 19 22H21C21.6 22 22 21.6 22 21ZM16 11V9C16 6.8 14.2 5 12 5C9.8 5 8 6.8 8 9V11C7.2 11 6.5 11.7 6.5 12.5C6.5 13.3 7.2 14 8 14V15C8 17.2 9.8 19 12 19C14.2 19 16 17.2 16 15V14C16.8 14 17.5 13.3 17.5 12.5C17.5 11.7 16.8 11 16 11ZM13.4 15C13.7 15 14 15.3 13.9 15.6C13.6 16.4 12.9 17 12 17C11.1 17 10.4 16.5 10.1 15.7C10 15.4 10.2 15 10.6 15H13.4Z" fill="black"/>
                              <path d="M9.2 12.9C9.1 12.8 9.10001 12.7 9.10001 12.6C9.00001 12.2 9.3 11.7 9.7 11.6C10.1 11.5 10.6 11.8 10.7 12.2C10.7 12.3 10.7 12.4 10.7 12.5L9.2 12.9ZM14.8 12.9C14.9 12.8 14.9 12.7 14.9 12.6C15 12.2 14.7 11.7 14.3 11.6C13.9 11.5 13.4 11.8 13.3 12.2C13.3 12.3 13.3 12.4 13.3 12.5L14.8 12.9ZM16 7.29998C16.3 6.99998 16.5 6.69998 16.7 6.29998C16.3 6.29998 15.8 6.30001 15.4 6.20001C15 6.10001 14.7 5.90001 14.4 5.70001C13.8 5.20001 13 5.00002 12.2 4.90002C9.9 4.80002 8.10001 6.79997 8.10001 9.09997V11.4C8.90001 10.7 9.40001 9.8 9.60001 9C11 9.1 13.4 8.69998 14.5 8.29998C14.7 9.39998 15.3 10.5 16.1 11.4V9C16.1 8.5 16 8 15.8 7.5C15.8 7.5 15.9 7.39998 16 7.29998Z" fill="black"/>
                            </svg>
                          </span>
                          <span className="indicator-label">
                            {lang.labels.validateAccount}
                          </span>
                          <span className="indicator-progress">
                            {lang.labels.pleaseWait}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap flex-stack mb-3">
                      <div className="me-10 mb-3">
                        <div className="d-flex flex-wrap">
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-2 px-3 me-5 mb-2">
                            <div className="d-flex flex-wrap align-items-center">
                              <span className="svg-icon svg-icon-2 svg-icon-info me-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 19H4C2.9 19 2 18.1 2 17H22C22 18.1 21.1 19 20 19Z" fill="black"/>
                                  <path opacity="0.3" d="M20 5H4C3.4 5 3 5.4 3 6V17H21V6C21 5.4 20.6 5 20 5Z" fill="black"/>
                                </svg>
                              </span>
                              <div className="fs-3 fw-bolder">{quantityServices}</div>
                            </div>
                            <div className="fw-bold text-gray-400">{lang.labels.services}</div>
                          </div>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-2 px-3 me-5 mb-2">
                            <div className="d-flex flex-wrap align-items-center">
                              <span className="svg-icon svg-icon-2 svg-icon-info me-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black"/>
                                  <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black"/>
                                  <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black"/>
                                </svg>
                              </span>
                              <div className="fs-3 fw-bolder">{quantityCompanies}</div>
                            </div>
                            <div className="fw-bold text-gray-400">{lang.labels.companies}</div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center w-250px w-sm-350px flex-column">
                        <div className="d-flex justify-content-between fs-6 w-100 mt-auto mb-2">
                          <span className="fw-bold text-gray-400">{lang.labels.profileCompleation}</span>
                          <span className="fw-bolder">{profileCompletation()}</span>
                        </div>
                        <div className="h-5px w-100 bg-light">
                          <div className="bg-success rounded h-5px" role="progressbar" style={{width: profileCompletation()}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                  <li className="nav-item">
                    <NavLink to="/app/account/view" className={({isActive}) => `nav-link text-active-primary ms-0 me-10 ${isActive && 'active'}`}>{lang.labels.overview}</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/app/account/update" className={({isActive}) => `nav-link text-active-primary ms-0 me-10 ${isActive && 'active'}`}>{lang.labels.settings}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          }
          <Outlet context={{setRoute, userLoggedIn, serviceForUser, reloadUserLoggedIn}}></Outlet>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export function UseOutletContextAccount() {
  return useOutletContext<ContexType>()
};

export default AccountPage;
