import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextInventory } from '../Inventory';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { ProductDA } from '../../../../services/product.service';
import { Error } from '../../../../models/error.model';
import { Service } from '../../../../models/service.model';
import { Company } from '../../../../models/company.model';
import { Product } from '../../../../models/product.model';

export interface CategoryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  productsForCompany: Product[] | undefined | null,
  setCategoriesForCompany: Dispatch<SetStateAction<Product[] | undefined | null>>
};

let errorResponse: Error, productsResponse: any;

const CategoryPage: React.FunctionComponent<CategoryPageProps> = props => {
  const {setRoute, serviceForUser, companyForUser} = UseOutletContextInventory()
  const {lang} = StateLanguage()

  const [productsForCompany, setProductsForCompany] = useState<Product[] | undefined | null>(null)

  const loadProducts = async (id_company: string) => {
    await ProductDA.getProducts(id_company, '', '', '', '').then( (response) => {
      if (response.status === 200) {
        productsResponse = response.data
        setProductsForCompany(productsResponse.products)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    if (companyForUser) {
      setProductsForCompany(null)
      loadProducts(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <Outlet context={{setRoute, serviceForUser, companyForUser, productsForCompany, setProductsForCompany}}></Outlet>
  );
}

export function UseOutletContextCategory() {
  return useOutletContext<ContexType>()
};

export default CategoryPage;
