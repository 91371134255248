import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { ProviderOrder } from '../models/provider.order.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { Unit } from '../models/unit.model';
import { Token } from '../services/token.service';
import Cookies from 'universal-cookie';

export class ProviderOrderDA {
  static apiResponse: ApiResponse
  static dataResponse: any
  static cookies = new Cookies()

  public static async getProviderOrders(id_company: string, start_date: string, end_date: string, contact_id: string, status: string, order_direction: string, order_by: string, group_by: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/orders?start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&order_direction=${order_direction}&group_by=${group_by}&order_by=${order_by}&status=${status}`
    let token = Token.getToken()
    let providerOrdersForCompany

    if (group_by !== '') {
      providerOrdersForCompany = {
        id_company: '',
        filter: {
          start_date: '',
          end_date: '',
          contact_id: '',
          validity: '',
          status: '',
          order_direction: '',
          order_by: '',
          group_by: ''
        },
        groups: []
      }
    }
    else {
      providerOrdersForCompany = {
        id_company: '',
        filter: {
          start_date: '',
          end_date: '',
          contact_id: '',
          validity: '',
          status: '',
          order_direction: '',
          order_by: '',
          group_by: ''
        },
        invoices: []
      }
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { providerOrdersForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerOrdersForCompany.id_company === id_company) {
      this.dataResponse = providerOrdersForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getProviderOrder(id_company: string, id_provider_order: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/orders/${id_provider_order}`
    let token = Token.getToken()
    let providerOrder: ProviderOrder | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.get(url, token).then(response => { providerOrder = response.data.provider_order })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerOrder) {
      this.dataResponse = providerOrder

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postProviderOrder(id_company: string, id_provider: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], vouchers: { name: string, file: any }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/orders`
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append("concerning", concerning)
    data.append("bank_account", id_treasury_account)
    data.append("customer", id_provider)
    data.append("reference", reference)
    data.append("created_date", date)
    data.append('currency', JSON.stringify(currency))
    data.append('company', JSON.stringify(company_data))
    vouchers.map((item) => {
      if (item.file instanceof File)
        data.append("vouchers", item.file, item.name)
      else
        data.append("array_url", item.file)
      data.append("array_names", item.name)
    })
    requirements.forEach(requirement => {
      data.append('requirements', JSON.stringify(requirement))
    })
    signatures.forEach(signature => {
      data.append('signatures', signature)
    })

    await axios.post(url, data, token)
      .then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully created provider order') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putProviderOrder(id_company: string, id_provider_order: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], vouchers: { name: string, file: any }[], signatures: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/orders/${id_provider_order}`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append("concerning", concerning)
    data.append("bank_account", id_treasury_account)
    data.append('currency', JSON.stringify(currency))
    data.append('created_date', date)
    vouchers.map((item) => {
      if (item.file instanceof File)
        data.append("vouchers", item.file, item.name)
      else
        data.append("array_url", item.file)
      data.append("array_names", item.name)
    })
    requirements.forEach(requirement => {
      data.append('requirements', JSON.stringify(requirement))
    })
    signatures.forEach(signature => {
      data.append('signatures', signature)
    })

    await axios.put(url, data, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated provider order') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putProviderOrderStatus(id_company: string, id_provider_order: string, new_status: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/orders/${id_provider_order}/status`
    let token = Token.getToken()
    let providerOrder: ProviderOrder | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataStatus = { status: new_status }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.put(url, dataStatus, token).then(response => { providerOrder = response.data.provider_order })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerOrder!!.id === id_provider_order) {
      providerOrder!!.status = new_status

      this.dataResponse = providerOrder

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
