import { contentEnglish } from './content_en';
import { contentSpanish } from './content_es';
import { contentFrench } from './content_fr';

export const getError = (code: number, lang: string) =>{
  switch (code) {
    case 1000:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1000
        case "FR":
          return contentFrench.labels.error1000
        default:
          return contentEnglish.labels.error1000
      }
    case 1001:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1001
        case "FR":
          return contentFrench.labels.error1001
        default:
          return contentEnglish.labels.error1001
      }
    case 1002:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1002
        case "FR":
          return contentFrench.labels.error1002
        default:
          return contentEnglish.labels.error1002
      }
    case 1003:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1003
        case "FR":
          return contentFrench.labels.error1003
        default:
          return contentEnglish.labels.error1003
      }
    case 1004:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1004 
        case "FR":
          return contentFrench.labels.error1004
        default:
          return contentEnglish.labels.error1004
      }
    case 1005:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1005
        case "FR":
          return contentFrench.labels.error1005
        default:
          return contentEnglish.labels.error1005
      }
    case 1006:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1006
        case "FR":
          return contentFrench.labels.error1006
        default:
          return contentEnglish.labels.error1006
      }
    case 1007:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1007
        case "FR":
          return contentFrench.labels.error1007
        default:
          return contentEnglish.labels.error1007 
      }
    case 1008:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1008
        case "FR":
          return contentFrench.labels.error1008
        default:
          return contentEnglish.labels.error1008
      }
    case 1009:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1009
        case "FR":
          return contentFrench.labels.error1009
        default:
          return contentEnglish.labels.error1009
      }
    case 1010:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1010
        case "FR":
          return contentFrench.labels.error1010
        default:
          return contentEnglish.labels.error1010
      }
    case 1011:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1011
        case "FR":
          return contentFrench.labels.error1011
        default:
          return contentEnglish.labels.error1011
      }
    case 1012:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1012
        case "FR":
          return contentFrench.labels.error1012
        default:
          return contentEnglish.labels.error1012
      }
    case 1014:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1014
        case "FR":
          return contentFrench.labels.error1014
        default:
          return contentEnglish.labels.error1014
      }
    case 1015:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1015
        case "FR":
          return contentFrench.labels.error1015
        default:
          return contentEnglish.labels.error1015
      }
    case 1016:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1016
        case "FR":
          return contentFrench.labels.error1016
        default:
          return contentEnglish.labels.error1016
      }
    case 1017:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1017
        case "FR":
          return contentFrench.labels.error1017
        default:
          return contentEnglish.labels.error1017
      }
    case 1018:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1018
        case "FR":
          return contentFrench.labels.error1018
        default:
          return contentEnglish.labels.error1018
      }
    case 1019:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1019
        case "FR":
          return contentFrench.labels.error1019
        default:
          return contentEnglish.labels.error1019
      }
    case 1020:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1020
        case "FR":
          return contentFrench.labels.error1020
        default:
          return contentEnglish.labels.error1020
      }
    case 1021:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1021
        case "FR":
          return contentFrench.labels.error1021
        default:
          return contentEnglish.labels.error1021
      }
    case 1022:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1022
        case "FR":
          return contentFrench.labels.error1022
        default:
          return contentEnglish.labels.error1022
      }
    case 1023:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1023
        case "FR":
          return contentFrench.labels.error1023
        default:
          return contentEnglish.labels.error1023
      }
    case 1024:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1024
        case "FR":
          return contentFrench.labels.error1024
        default:
          return contentEnglish.labels.error1024
      }
    case 1025:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1025
        case "FR":
          return contentFrench.labels.error1025
        default:
          return contentEnglish.labels.error1025
      }
    case 1026:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1026
        case "FR":
          return contentFrench.labels.error1026
        default:
          return contentEnglish.labels.error1026
      }
    case 1027:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1027
        case "FR":
          return contentFrench.labels.error1027
        default:
          return contentEnglish.labels.error1027
      }
    case 1028:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1028
        case "FR":
          return contentFrench.labels.error1028
        default:
          return contentEnglish.labels.error1028
      }
    case 1029:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1029
        case "FR":
          return contentFrench.labels.error1029
        default:
          return contentEnglish.labels.error1029
      }
    case 1030:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1030
        case "FR":
          return contentFrench.labels.error1030
        default:
          return contentEnglish.labels.error1030
      }
    case 1031:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1031
        case "FR":
          return contentFrench.labels.error1031
        default:
          return contentEnglish.labels.error1031
      }
    case 1032:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1032
        case "FR":
          return contentFrench.labels.error1032
        default:
          return contentEnglish.labels.error1032
      }
    case 1033:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1033
        case "FR":
          return contentFrench.labels.error1033
        default:
          return contentEnglish.labels.error1033
      }
    case 1034:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1034
        case "FR":
          return contentFrench.labels.error1034
        default:
          return contentEnglish.labels.error1034
      }
    case 1035:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1035
        case "FR":
          return contentFrench.labels.error1035
        default:
          return contentEnglish.labels.error1035
      }
    case 1036:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1036
        case "FR":
          return contentFrench.labels.error1036
        default:
          return contentEnglish.labels.error1036
      }
    case 1037:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1037
        case "FR":
          return contentFrench.labels.error1037
        default:
          return contentEnglish.labels.error1037
      }
    case 1038:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1038
        case "FR":
          return contentFrench.labels.error1038
        default:
          return contentEnglish.labels.error1038
      }
    case 1039:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1039
        case "FR":
          return contentFrench.labels.error1039
        default:
          return contentEnglish.labels.error1039
      }
    case 1040:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1040
        case "FR":
          return contentFrench.labels.error1040
        default:
          return contentEnglish.labels.error1040
      }
    case 1041:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1041
        case "FR":
          return contentFrench.labels.error1041
        default:
          return contentEnglish.labels.error1041
      }
    case 1042:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1042
        case "FR":
          return contentFrench.labels.error1042
        default:
          return contentEnglish.labels.error1042
      }
    case 1043:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1043
        case "FR":
          return contentFrench.labels.error1043
        default:
          return contentEnglish.labels.error1043
      }
    case 1044:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1044
        case "FR":
          return contentFrench.labels.error1044
        default:
          return contentEnglish.labels.error1044
      }
    case 1047:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1047
        case "FR":
          return contentFrench.labels.error1047
        default:
          return contentEnglish.labels.error1047
      }
    case 1048:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1048
        case "FR":
          return contentFrench.labels.error1048
        default:
          return contentEnglish.labels.error1048
      }
    case 1049:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1049
        case "FR":
          return contentFrench.labels.error1049
        default:
          return contentEnglish.labels.error1049
      }
    case 1050:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1050
        case "FR":
          return contentFrench.labels.error1050
        default:
          return contentEnglish.labels.error1050
      }
    case 1051:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1051
        case "FR":
          return contentFrench.labels.error1051
        default:
          return contentEnglish.labels.error1051
      }
    case 1052:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1052
        case "FR":
          return contentFrench.labels.error1052
        default:
          return contentEnglish.labels.error1052
      }
    case 1055:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1055
        case "FR":
          return contentFrench.labels.error1055
        default:
          return contentEnglish.labels.error1055
      }
    case 1056:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1056
        case "FR":
          return contentFrench.labels.error1056
        default:
          return contentEnglish.labels.error1056
      }
    case 1057:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1057
        case "FR":
          return contentFrench.labels.error1057
        default:
          return contentEnglish.labels.error1057
      }
    case 1058:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1058
        case "FR":
          return contentFrench.labels.error1058
        default:
          return contentEnglish.labels.error1058
      }
    case 1059:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1059
        case "FR":
          return contentFrench.labels.error1059
        default:
          return contentEnglish.labels.error1059
      }
    case 1060:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1060
        case "FR":
          return contentFrench.labels.error1060
        default:
          return contentEnglish.labels.error1060
      }
    case 1061:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1061
        case "FR":
          return contentFrench.labels.error1061
        default:
          return contentEnglish.labels.error1061
      }
    case 1062:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1062
        case "FR":
          return contentFrench.labels.error1062
        default:
          return contentEnglish.labels.error1062
      }
    case 1064:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1064
        case "FR":
          return contentFrench.labels.error1064
        default:
          return contentEnglish.labels.error1064
      }
    case 1065:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1065
        case "FR":
          return contentFrench.labels.error1065
        default:
          return contentEnglish.labels.error1065
      }
    case 1066:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1066
        case "FR":
          return contentFrench.labels.error1066
        default:
          return contentEnglish.labels.error1066
      }
    case 1067:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1067
        case "FR":
          return contentFrench.labels.error1067
        default:
          return contentEnglish.labels.error1067
      }
    case 1068:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1068
        case "FR":
          return contentFrench.labels.error1068
        default:
          return contentEnglish.labels.error1068
      }
    case 1069:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1069
        case "FR":
          return contentFrench.labels.error1069
        default:
          return contentEnglish.labels.error1069
      }
    case 1070:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1070
        case "FR":
          return contentFrench.labels.error1070
        default:
          return contentEnglish.labels.error1070
      }
    case 1071:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1071
        case "FR":
          return contentFrench.labels.error1071
        default:
          return contentEnglish.labels.error1071
      }
    case 1072:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1072
        case "FR":
          return contentFrench.labels.error1072
        default:
          return contentEnglish.labels.error1072
      }
    case 1073:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1073
        case "FR":
          return contentFrench.labels.error1073
        default:
          return contentEnglish.labels.error1073
      }
    case 1074:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1074
        case "FR":
          return contentFrench.labels.error1074
        default:
          return contentEnglish.labels.error1074
      }
    case 1075:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1075
        case "FR":
          return contentFrench.labels.error1075
        default:
          return contentEnglish.labels.error1075
      }
    case 1076:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1076
        case "FR":
          return contentFrench.labels.error1076
        default:
          return contentEnglish.labels.error1076
      }
    case 1077:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1077
        case "FR":
          return contentFrench.labels.error1077
        default:
          return contentEnglish.labels.error1077
      }
    case 1078:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1078
        case "FR":
          return contentFrench.labels.error1078
        default:
          return contentEnglish.labels.error1078
      }
    case 1079:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1079
        case "FR":
          return contentFrench.labels.error1079
        default:
          return contentEnglish.labels.error1079
      }
    case 1080:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1080
        case "FR":
          return contentFrench.labels.error1080
        default:
          return contentEnglish.labels.error1080
      }
    case 1081:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1081
        case "FR":
          return contentFrench.labels.error1081
        default:
          return contentEnglish.labels.error1081
      }
    case 1082:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1082
        case "FR":
          return contentFrench.labels.error1082
        default:
          return contentEnglish.labels.error1082
      }
    case 1083:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1083
        case "FR":
          return contentFrench.labels.error1083
        default:
          return contentEnglish.labels.error1083
      }
    case 1084:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1084
        case "FR":
          return contentFrench.labels.error1084
        default:
          return contentEnglish.labels.error1084
      }
    case 1085:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1085
        case "FR":
          return contentFrench.labels.error1085
        default:
          return contentEnglish.labels.error1085
      }
    case 1086:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1086
        case "FR":
          return contentFrench.labels.error1086
        default:
          return contentEnglish.labels.error1086
      }
    case 1087:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1087
        case "FR":
          return contentFrench.labels.error1087
        default:
          return contentEnglish.labels.error1087
      }
    case 1088:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1088
        case "FR":
          return contentFrench.labels.error1088
        default:
          return contentEnglish.labels.error1088
      }
    
    case 1089:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1089
        case "FR":
          return contentFrench.labels.error1089
        default:
          return contentEnglish.labels.error1089
      }
    case 1090:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1090
        case "FR":
          return contentFrench.labels.error1090
        default:
          return contentEnglish.labels.error1090
      }
    case 1091:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1091
        case "FR":
          return contentFrench.labels.error1091
        default:
          return contentEnglish.labels.error1091
      }
    case 1100:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1100
        case "FR":
          return contentFrench.labels.error1100
        default:
          return contentEnglish.labels.error1100
      }
    case 1101:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1101
        case "FR":
          return contentFrench.labels.error1101
        default:
          return contentEnglish.labels.error1101
      }
    case 1102:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1102
        case "FR":
          return contentFrench.labels.error1102
        default:
          return contentEnglish.labels.error1102
      }
    case 1103:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1103
        case "FR":
          return contentFrench.labels.error1103
        default:
          return contentEnglish.labels.error1103
      }
    case 10104:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1104
        case "FR":
          return contentFrench.labels.error1104
        default:
          return contentEnglish.labels.error1104
      }
    case 1105:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1105
        case "FR":
          return contentFrench.labels.error1105
        default:
          return contentEnglish.labels.error1105
      }
    case 1106:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1106
        case "FR":
          return contentFrench.labels.error1106
        default:
          return contentEnglish.labels.error1106
      }
    case 1107:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1107
        case "FR":
          return contentFrench.labels.error1107
        default:
          return contentEnglish.labels.error1107
      }
    case 1108:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1108
        case "FR":
          return contentFrench.labels.error1108
        default:
          return contentEnglish.labels.error1108
      }
    case 1109:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1109
        case "FR":
          return contentFrench.labels.error1109
        default:
          return contentEnglish.labels.error1109
      }
    case 1110:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.error1110
        case "FR":
          return contentFrench.labels.error1110
        default:
          return contentEnglish.labels.error1110
      } 
    default:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.undefined
        case "FR":
          return contentFrench.labels.undefined
        default:
          return contentEnglish.labels.undefined
      }
  }
}

export const getMessage = (message: string, lang: string) =>{
  switch (message) {
    case "Successfully accepted company":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyAcceptedCompany
        case "FR":
          return contentFrench.labels.successfullyAcceptedCompany
        default:
          return contentEnglish.labels.successfullyAcceptedCompany
      }
    case "Successfully created treasury account":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedTreasuryAccount
        case "FR":
          return contentFrench.labels.successfullyCreatedTreasuryAccount
        default:
          return contentEnglish.labels.successfullyCreatedTreasuryAccount
      }
    case "Successfully created company":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedCompany
        case "FR":
          return contentFrench.labels.successfullyCreatedCompany
        default:
          return contentEnglish.labels.successfullyCreatedCompany
      }
    case "Successfully created company parameters":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedCompanyParameters
        case "FR":
          return contentFrench.labels.successfullyCreatedCompanyParameters
        default:
          return contentEnglish.labels.successfullyCreatedCompanyParameters
      }
    case "Successfully created contact":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedContact
        case "FR":
          return contentFrench.labels.successfullyCreatedContact
        default:
          return contentEnglish.labels.successfullyCreatedContact
      }
    case "Successfully created expense":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedExpense
        case "FR":
          return contentFrench.labels.successfullyCreatedExpense
        default:
          return contentEnglish.labels.successfullyCreatedExpense
      }
    case "Successfully created order":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedOrder
        case "FR":
          return contentFrench.labels.successfullyCreatedOrder
        default:
          return contentEnglish.labels.successfullyCreatedOrder
      }
    case "Successfully created invoice":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedInvoice
        case "FR":
          return contentFrench.labels.successfullyCreatedInvoice
        default:
          return contentEnglish.labels.successfullyCreatedInvoice
      }
    case "Successfully created notification":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedNotification
        case "FR":
          return contentFrench.labels.successfullyCreatedNotification
        default:
          return contentEnglish.labels.successfullyCreatedNotification
      }
    case "Successfully created payment":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedPayment
        case "FR":
          return contentFrench.labels.successfullyCreatedPayment
        default:
          return contentEnglish.labels.successfullyCreatedPayment
      }
    case "Successfully created provider invoice":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedProviderInvoice
        case "FR":
          return contentFrench.labels.successfullyCreatedProviderInvoice
        default:
          return contentEnglish.labels.successfullyCreatedProviderInvoice
      }
    case "Successfully created provider order":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedProviderOrder
        case "FR":
          return contentFrench.labels.successfullyCreatedProviderOrder
        default:
          return contentEnglish.labels.successfullyCreatedProviderOrder
      }
    case "Successfully created quote":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedQuote
        case "FR":
          return contentFrench.labels.successfullyCreatedQuote
        default:
          return contentEnglish.labels.successfullyCreatedQuote
      }
    case "Successfully created user":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyCreatedUser
        case "FR":
          return contentFrench.labels.successfullyCreatedUser
        default:
          return contentEnglish.labels.successfullyCreatedUser
      }
    case "Successfully deleted treasury account":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyDeletedTreasuryAccount
        case "FR":
          return contentFrench.labels.successfullyDeletedTreasuryAccount
        default:
          return contentEnglish.labels.successfullyDeletedTreasuryAccount
      }
    case "Successfully deleted contact":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyDeletedContact
        case "FR":
          return contentFrench.labels.successfullyDeletedContact
        default:
          return contentEnglish.labels.successfullyDeletedContact
      }
    case "Successfully deleted expense":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyDeletedExpense
        case "FR":
          return contentFrench.labels.successfullyDeletedExpense
        default:
          return contentEnglish.labels.successfullyDeletedExpense
      }
    case "Successfully deleted payment":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyDeletedPayment
        case "FR":
          return contentFrench.labels.successfullyDeletedPayment
        default:
          return contentEnglish.labels.successfullyDeletedPayment
      }
    case "Successfully invitation cancel":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyInvitationCancel
        case "FR":
          return contentFrench.labels.successfullyInvitationCancel
        default:
          return contentEnglish.labels.successfullyInvitationCancel
      }
    case "Successfully invitation sent":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyInvitationSent
        case "FR":
          return contentFrench.labels.successfullyInvitationSent
        default:
          return contentEnglish.labels.successfullyInvitationSent
      }
    case "You have successfully logged in":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyLoggedIn
        case "FR":
          return contentFrench.labels.successfullyLoggedIn
        default:
          return contentEnglish.labels.successfullyLoggedIn
      }
    case "Successfully order sent":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyOrderSent
        case "FR":
          return contentFrench.labels.successfullyOrderSent
        default:
          return contentEnglish.labels.successfullyOrderSent
      }
    case "Successfully updated company settings":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedCompanySettings
        case "FR":
          return contentFrench.labels.successfullyUpdatedCompanySettings
        default:
          return contentEnglish.labels.successfullyUpdatedCompanySettings
      }
    case "Successfully updated treasury account":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedTreasuryAccount
        case "FR":
          return contentFrench.labels.successfullyUpdatedTreasuryAccount
        default:
          return contentEnglish.labels.successfullyUpdatedTreasuryAccount
      }
    case "Successfully updated default treasury account":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedDefaultTreasuryAccount
        case "FR":
          return contentFrench.labels.successfullyUpdatedDefaultTreasuryAccount
        default:
          return contentEnglish.labels.successfullyUpdatedDefaultTreasuryAccount
      }
    case "Successfully updated contact":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedContact
        case "FR":
          return contentFrench.labels.successfullyUpdatedContact
        default:
          return contentEnglish.labels.successfullyUpdatedContact
      }
    case "Successfully updated expense":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedExpense
        case "FR":
          return contentFrench.labels.successfullyUpdatedExpense
        default:
          return contentEnglish.labels.successfullyUpdatedExpense
      }
    case "Successfully updated invoice":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedInvoice
        case "FR":
          return contentFrench.labels.successfullyUpdatedInvoice
        default:
          return contentEnglish.labels.successfullyUpdatedInvoice
      }
    case "Successfully updated notification":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedNotification
        case "FR":
            return contentFrench.labels.successfullyUpdatedNotification
        default:
            return contentEnglish.labels.successfullyUpdatedNotification
      }
    case "Successfully updated order":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedOrder
        case "FR":
          return contentFrench.labels.successfullyUpdatedOrder
        default:
          return contentEnglish.labels.successfullyUpdatedOrder
      }
    case "Successfully updated password":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedPassword
        case "FR":
          return contentFrench.labels.successfullyUpdatedPassword
        default:
          return contentEnglish.labels.successfullyUpdatedPassword
      }
    case "Successfully updated provider invoice":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedProviderInvoice
        case "FR":
          return contentFrench.labels.successfullyUpdatedProviderInvoice
        default:
          return contentEnglish.labels.successfullyUpdatedProviderInvoice
      }
    case "Successfully updated provider order":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedProviderOrder
        case "FR":
          return contentFrench.labels.successfullyUpdatedProviderOrder
        default:
          return contentEnglish.labels.successfullyUpdatedProviderOrder
      }
    case "Successfully updated quote":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedQuote
        case "FR":
          return contentFrench.labels.successfullyUpdatedQuote
        default:
          return contentEnglish.labels.successfullyUpdatedQuote
      }
    case "Successfully updated status":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedStatus
        case "FR":
          return contentFrench.labels.successfullyUpdatedStatus
        default:
          return contentEnglish.labels.successfullyUpdatedStatus
      }
    case "Successfully quote sent":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyQuoteSent
        case "FR":
          return contentFrench.labels.successfullyQuoteSent
        default:
          return contentEnglish.labels.successfullyQuoteSent
      }
    case "Successfully rejected company":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyRejectedCompany
        case "FR":
          return contentFrench.labels.successfullyRejectedCompany
        default:
          return contentEnglish.labels.successfullyRejectedCompany
      }
    case "Successful request":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyRequest
        case "FR":
          return contentFrench.labels.successfullyRequest
        default:
          return contentEnglish.labels.successfullyRequest
      }
    case "Verification was successful":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.verificationWasSuccessful
        case "FR":
          return contentFrench.labels.verificationWasSuccessful
        default:
          return contentEnglish.labels.verificationWasSuccessful
      }
    case "Successfully mail sent":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyMailSent
        case "FR":
          return contentFrench.labels.successfullyMailSent
        default:
          return contentEnglish.labels.successfullyMailSent
      }
    case "Successfully updated user":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedUser
        case "FR":
          return contentFrench.labels.successfullyUpdatedUser
        default:
          return contentEnglish.labels.successfullyUpdatedUser
      }
    case "Successfully updated account":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullyUpdatedAccount
        case "FR":
          return contentFrench.labels.successfullyUpdatedAccount
        default:
          return contentEnglish.labels.successfullyUpdatedAccount
      }
    case "Successfully subscribed":
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successfullySubscribed
        case "FR":
          return contentFrench.labels.successfullySubscribed
        default:
          return contentEnglish.labels.successfullySubscribed
      }
    default:
      switch (lang) {
        case "ES":
          return contentSpanish.labels.successful
        case "FR":
          return contentFrench.labels.successful
        default:
          return contentEnglish.labels.successful
      }
  }
} 
