import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/translations/response';
import { TemplateDA } from '../../../services/template.service';
import { Error } from '../../../models/error.model';
import { Service } from '../../../models/service.model';
import { Company } from '../../../models/company.model';
import { Template } from '../../../models/template.model';
import { Settings } from '../../../models/settings.model';
import { removeTooltip, uploadTooltip } from '../../../tools/tooltip.tool';
import { modalShow } from '../../../tools/modal.tool';
import { imageCompany } from '../../../scripts/image.value.script';
import { countryName } from '../../../libraries/countries.library';

import AppModalCompanyUsers from '../../../components/modal/ModalCompanyUsers';

export interface CompanyPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | undefined | null,
  templates: Template[] | undefined | null,
  reloadUserLoggedIn: Function,
  loadCompanyForUser: Function
};

let errorResponse: Error, templateResponse: any;

const CompanyPage: React.FunctionComponent<CompanyPageProps> = props => {
  const {setRoute, serviceForUser, companyForUser, settingsForCompany, reloadUserLoggedIn, loadCompanyForUser} = UseOutletContext()
  const {lang} = StateLanguage()
  const param = useParams()
  const location = useLocation()

  const [clipboard, setClipboard] = useState(false)
  const [idCompany, setIdCompany] = useState('none')
  const [quantityUsers, setQuantityUsers] = useState(0)
  const [templates, setTemplates] = useState<Template[] | undefined | null>(null)

  const loadTemplates = async (id_company: string) => {
    await TemplateDA.getTemplates(id_company).then( (response) => {
      if (response.status === 200) {
        templateResponse = response.data
        setTemplates(templateResponse.templates)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    }) 
  }

  const executeCopyCompanyUuid = () => {
    removeTooltip()

    if(companyForUser) {
      navigator.clipboard.writeText(companyForUser.uuid).then ( () => {
        setClipboard(true)
        setTimeout( () => {
          setClipboard(false)
        }, 500 )
      }).catch (()=> {
        setClipboard(false)
      })
    }
  }

  const executeShowModalCompanyView = () => {
    modalShow('modal-company-view')
  }

  function viewCardCompany(): boolean {
    if (param.language && `/${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}` === '/app/company' && (`/${location.pathname.split('/')[4]}` === '/view' || `/${location.pathname.split('/')[4]}` === '/update')) {
      return true
    } else if (location.pathname === '/app/company/view' || location.pathname === '/app/company/update') {
      return true
    } else {
      return false
    }
  }

  function viewNavigationCardCompany(): boolean {
    if (param.language && `/${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}` === '/app/company/update') {
      return true
    } else if (location.pathname === '/app/company/update') {
      return true
    } else {
      return false
    }
  }

  useEffect( () => {
    if (companyForUser && companyForUser.id !== idCompany) {
      setIdCompany(companyForUser.id)
      setQuantityUsers(0)
      setTemplates(null)

      const totalUsers: number = (companyForUser) ? companyForUser.users.length : 0

      const intervalUsers = setInterval(() => {
        setQuantityUsers((prevCount) => {
          if (prevCount >= totalUsers) {
            clearInterval(intervalUsers)
            return prevCount
          }
          return prevCount + 1
        })
      }, 100)

      loadTemplates(companyForUser.id)
      uploadTooltip()

      return () => {
        clearInterval(intervalUsers)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <div className="w-100 h-100">
      {  companyForUser && viewCardCompany() &&
        <div className="card mb-5">
          <div className="card-body pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-2">
              <div className="me-5 mb-2">
                <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px">
                  <img src={imageCompany(companyForUser.logo)} alt={lang.labels.logo} className="mw-75px mh-75px mw-lg-125px mh-lg-125px" />
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-1">
                      <label className="text-gray-800 fs-2 fw-bolder">{companyForUser.name}</label>
                    </div>
                    <div className="d-flex flex-wrap fw-bold text-gray-400 fs-6 mb-2">
                      {companyForUser.address.number} {companyForUser.address.street}, {companyForUser.address.zip_code}, {companyForUser.address.city}, {countryName(companyForUser.address.country, lang.code)}.
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-start mb-3">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-2 px-3 me-5 mb-2">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="me-5">
                          <div className="d-flex flex-wrap align-items-center">
                            <span className="svg-icon svg-icon-2 svg-icon-primary me-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"/>
                                <path d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z" fill="black"/>
                              </svg>
                            </span>
                            <div className="fs-6 fw-bold">{companyForUser.uuid}</div>
                          </div>
                          <div className="fw-semibold text-gray-400">{lang.labels.uuidCompany}</div>
                        </div>
                        <button className="btn btn-icon btn-sm btn-bg-light btn-active-color-primary" type="button" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theUuidCanBeUsedToCreateNnewContact} onClick={executeCopyCompanyUuid}>
                          <span className="svg-icon svg-icon-1">
                            { !clipboard
                              ?
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="black"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="black"/>
                              </svg>
                              :
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M12.8956 13.4982L10.7949 11.2651C10.2697 10.7068 9.38251 10.7068 8.85731 11.2651C8.37559 11.7772 8.37559 12.5757 8.85731 13.0878L12.7499 17.2257C13.1448 17.6455 13.8118 17.6455 14.2066 17.2257L21.1427 9.85252C21.6244 9.34044 21.6244 8.54191 21.1427 8.02984C20.6175 7.47154 19.7303 7.47154 19.2051 8.02984L14.061 13.4982C13.7451 13.834 13.2115 13.834 12.8956 13.4982Z" fill="black"/>
                                <path d="M7.89557 13.4982L5.79487 11.2651C5.26967 10.7068 4.38251 10.7068 3.85731 11.2651C3.37559 11.7772 3.37559 12.5757 3.85731 13.0878L7.74989 17.2257C8.14476 17.6455 8.81176 17.6455 9.20663 17.2257L16.1427 9.85252C16.6244 9.34044 16.6244 8.54191 16.1427 8.02984C15.6175 7.47154 14.7303 7.47154 14.2051 8.02984L9.06096 13.4982C8.74506 13.834 8.21146 13.834 7.89557 13.4982Z" fill="black"/>
                              </svg>
                            }
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-2 px-3 me-5 mb-2">
                      <div className="d-flex flex-wrap align-items-center">
                        <span className="svg-icon svg-icon-2 svg-icon-primary me-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"/>
                            <path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="black"/>
                            <path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="black"/>
                          </svg>
                        </span>
                        <div className="fs-6 fw-bold">{quantityUsers}</div>
                      </div>
                      <div className="fw-semibold text-gray-400">{lang.labels.users}</div>
                    </div>
                  </div>
                  <div className="symbol-group symbol-hover">
                    { companyForUser.users.map (( (item, index) => { return (
                      <div key={index} className={`symbol symbol-35px symbol-circle + ${index > 3 && "d-none"}`} data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={`${item.first_name} ${item.last_name}`}>
                        { item.avatar.length > 0
                          ?
                          <img src={item.avatar} alt={lang.labels.avatar}/>
                          :
                          <span className="symbol-label bg-primary text-inverse-primary">{item.last_name.charAt(0)}</span>
                        }
                      </div>
                    )}))}
                    { companyForUser.users.length > 1 &&
                      <div className="symbol symbol-35px symbol-circle" onClick={executeShowModalCompanyView}>
                        <span className="symbol-label bg-dark text-inverse-dark fs-1" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.viewMoreUsers}>+</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            { viewNavigationCardCompany() &&
              <>
                <div className="separator mb-2"></div>
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                  <li className="nav-item">
                    <a href="#container-settings-general" className="nav-link text-active-primary ms-0 me-10 active" data-bs-toggle="tab">{lang.labels.generalSettings}</a>
                  </li>
                  <li className="nav-item">
                    <a href="#container-settings-parameter" className="nav-link text-active-primary ms-0 me-10" data-bs-toggle="tab">{lang.labels.parameterSettings}</a>
                  </li>
                  <li className="nav-item">
                    <a href="#container-settings-download" className="nav-link text-active-primary ms-0 me-10" data-bs-toggle="tab">{lang.labels.downloadSettings}</a>
                  </li>
                </ul>
              </>
            }
          </div>
        </div>
      }
      <Outlet context={{setRoute, serviceForUser, companyForUser, settingsForCompany, templates, reloadUserLoggedIn, loadCompanyForUser}}></Outlet>
      <AppModalCompanyUsers companyForUser={companyForUser}></AppModalCompanyUsers>
    </div>
  )
};

export function UseOutletContextCompany() {
  return useOutletContext<ContexType>()
};

export default CompanyPage;
