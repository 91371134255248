import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { ProviderInvoice } from '../models/provider.invoice.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { Unit } from '../models/unit.model';
import { Token } from '../services/token.service';

export class ProviderInvoiceDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getProviderInvoices(id_company: string, start_date: string, end_date: string, contact_id: string, validity: string, payment: string, order_direction: string, order_by: string, group_by: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices?start_date=${start_date}&end_date=${end_date}&contact_id=${contact_id}&order_by=${order_by}&order_direction=${order_direction}&page&limit&group_by=${group_by}&payment=${payment}&validity=${validity}`
    let token = Token.getToken()

    let providerInvoicesForCompany
    if (group_by !== '') {
      providerInvoicesForCompany = {
        id_company: id_company,
        filter: {
          start_date: start_date,
          end_date: end_date,
          contact_id: contact_id,
          payment,
          validity: validity,
          order_direction: order_direction,
          order_by: order_by,
          group_by: group_by
        },
        groups: []
      }
    }
    else {
      providerInvoicesForCompany = {
        id_company: id_company,
        filter: {
          start_date: start_date,
          end_date: end_date,
          contact_id: contact_id,
          payment,
          validity: validity,
          order_direction: order_direction,
          order_by: order_by,
          group_by: group_by
        },
        quotes: []
      }
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }
    await axios.get(url, token).then(response => { providerInvoicesForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerInvoicesForCompany.id_company === id_company) {
      this.dataResponse = providerInvoicesForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getProviderInvoice(id_company: string, id_provider_invoice: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices/${id_provider_invoice}`
    let token = Token.getToken()
    let providerInvoice: ProviderInvoice | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.get(url, token).then(response => { providerInvoice = response.data.provider_invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerInvoice) {

      this.dataResponse = providerInvoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postProviderInvoice(id_company: string, id_provider: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], vouchers: { name: string, file: any }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append("concerning", concerning)
    data.append("bank_account", id_treasury_account)
    data.append("customer", id_provider)
    data.append('currency', JSON.stringify(currency))
    data.append('company', JSON.stringify(company_data))
    data.append('created_date', date)
    vouchers.map((item) => {
      data.append("vouchers", item.file, item.name)
    })
    requirements.forEach(requirement => {
      data.append('requirements', JSON.stringify(requirement))
    })
    signatures.forEach(signature => {
      data.append('signatures', signature)
    })

    await axios.post(url, data, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully created provider invoice') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postProviderInvoiceReference(id_company: string, id_provider_order: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices/${id_provider_order}`
    let token = Token.getToken()
    let providerInvoice: ProviderInvoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, undefined, token).then(response => { providerInvoice = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerInvoice) {
      this.dataResponse = providerInvoice

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putProviderInvoice(id_company: string, id_provider_invoice: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], vouchers: { name: string, file: any }[], signatures: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices/${id_provider_invoice}`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append("concerning", concerning)
    data.append("bank_account", id_treasury_account)
    data.append('currency', JSON.stringify(currency))
    data.append('created_date', date)
    vouchers.map((item) => {
      if (item.file instanceof File)
        data.append("vouchers", item.file, item.name)
      else
        data.append("array_url", item.file)
      data.append("array_names", item.name)
    })
    requirements.forEach(requirement => {
      data.append('requirements', JSON.stringify(requirement))
    })
    signatures.forEach(signature => {
      data.append('signatures', signature)
    })

    await axios.put(url, data, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated provider invoice') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putProviderInvoiceValidated(id_company: string, id_provider_invoice: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices/${id_provider_invoice}/status`
    let token = Token.getToken()
    let providerInvoice: ProviderInvoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, undefined, token).then(response => { providerInvoice = response.data.provider_invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerInvoice) {
      this.dataResponse = providerInvoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
