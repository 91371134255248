import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Invoice } from '../models/invoice.model';
import { ProviderInvoice } from '../models/provider.invoice.model';
import { Payment } from '../models/payment.model';
import { Token } from '../services/token.service';

export class PaymentDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async postPayment(id_company: string, id_invoice: string, amount: string, source: string, method: string, date: string): Promise<ApiResponse> {

    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/payments`
    let token = Token.getToken()
    let invoice: Invoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataPayment = {
      amount,
      method,
      source,
      created_date: date
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.post(url, dataPayment, token).then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {
      this.dataResponse = invoice

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postPaymentProvider(id_company: string, id_provider_invoice: string, amount: string, source: string, method: string, date: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices/${id_provider_invoice}/payments`
    let token = Token.getToken()
    let providerInvoice: ProviderInvoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataPayment = {
      amount,
      method,
      source,
      created_date: date
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.post(url, dataPayment, token).then(response => { providerInvoice = response.data.provider_invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerInvoice) {
      this.dataResponse = providerInvoice

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deletePayment(id_company: string, id_invoice: string, id_payment: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/payments/${id_payment}`
    let token = Token.getToken()
    let invoice: Invoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.delete(url, token).then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {
      this.dataResponse = invoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deletePaymentProvider(id_company: string, id_provider_invoice: string, id_payment: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/provider/invoices/${id_provider_invoice}/payments/${id_payment}`
    let token = Token.getToken()
    let providerInvoice: ProviderInvoice | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.delete(url, token).then(response => { providerInvoice = response.data.provider_invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (providerInvoice!) {
      this.dataResponse = providerInvoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
