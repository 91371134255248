import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Error } from '../../models/error.model';
import { Invoice } from '../../models/invoice.model';
import { Legend } from '../../styles/legend.style';
import { legendInvalid, legendValidInvalidIconRestart, legendValidInvalidRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequired } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';
import { getError } from '../../languages/translations/response';
import { InvoiceDA } from '../../services/invoice.service';

interface AppModalInvoiceCreateReminderProps {
  invoice: Invoice | undefined | null,
  setInvoice: Dispatch<SetStateAction<Invoice | undefined | null>>
};

let errorResponse: Error, invoiceResponse: Invoice;

const AppModalInvoiceCreateReminder: React.FunctionComponent<AppModalInvoiceCreateReminderProps> = ({invoice, setInvoice}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [description, setDescription] = useState({value: '', valid: false})
  const [amount, setAmount] = useState({value: '', valid: true})

  const submitInvoiceCreateReminder = async () => {
    setLoadIndicator('on')

    if (invoice && description.valid && amount.valid) {
      await InvoiceDA.postInvoiceReminder(invoice.company.id, invoice.id, description.value, amount.value).then( (response) => {
        if (response.status === 201) {
          invoiceResponse = response.data
          setInvoice(invoiceResponse)

          Swal.fire({
            title: lang.labels.successfullyCreatedReminder,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalInvoiceCreateReminder()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (description.value.length === 0) {
          legendInvalid('modal-invoice-create-reminder-container-validate-description-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeDescription = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setDescription({...description, value: event.target.value})
  }

  const handleChangeAmount = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAmount({...amount, value: event.target.value})
  }

  const validateDescription = () => {
    evaluateLegendValidateRequired(expressions.text, description, setDescription, 'modal-invoice-create-reminder-container-validate-description-valid', 'modal-invoice-create-reminder-container-validate-description-required')
  }

  const validateAmount = () => {
    evaluateLegendValidateEmptyIcon(expressions.currency, amount, setAmount, 'modal-invoice-create-reminder-input-amount', 'modal-invoice-create-reminder-container-validate-amount-valid')
  }

  const executeHideModalInvoiceCreateReminder = () => {
    modalHide('modal-invoice-create-reminder')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidRestart('modal-invoice-create-reminder-container-validate-description-valid')
    legendValidInvalidRestart('modal-invoice-create-reminder-container-validate-description-required')
    legendValidInvalidIconRestart('modal-invoice-create-reminder-input-amount', 'modal-invoice-create-reminder-container-validate-amount-valid')

    setDescription({value: '', valid: true})
    setAmount({value: '', valid: false})
  }

  return (
    <div id="modal-invoice-create-reminder" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.createReminder}</h2>
          </div>
          <div className="modal-body">
            { invoice
              ?
              <div className="form">
                <div className="fv-row mb-3">
                  <label className="form-label required">{lang.labels.description}</label>
                  <TextareaAutosize className="form-control form-control-solid" name="description" minRows={2} maxRows={3} value={description.value} onChange={handleChangeDescription} onKeyUp={validateDescription} onBlur={validateDescription} />
                  <Legend property={description.valid.toString()}>
                    <div id="modal-invoice-create-reminder-container-validate-description-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="description" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                    <div id="modal-invoice-create-reminder-container-validate-description-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="description" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                    </div>
                  </Legend>
                </div>
                <div className="fv-row">
                  <label className="form-label">{lang.labels.amount}</label>
                  <div className="position-relative">
                    <div className="position-absolute translate-middle-y top-50 start-0 fw-bolder ms-5">{invoice.currency.symbol}</div>  
                    <input id="modal-invoice-create-reminder-input-amount" className="form-control form-control-solid ps-20" type="text" name="amount" placeholder="0.00" value={amount.value} onChange={handleChangeAmount} onKeyUp={validateAmount} onBlur={validateAmount} />
                  </div>
                  <div className="form-text">{lang.labels.ifNoAmountIsEnteredTheValueWillBeZero}</div>
                  <Legend property={amount.valid.toString()}>
                    <div id="modal-invoice-create-reminder-container-validate-amount-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="amount" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                  </Legend>
                </div>
              </div>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalInvoiceCreateReminder}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitInvoiceCreateReminder}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalInvoiceCreateReminder;
