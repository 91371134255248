import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextContact } from './Contact';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/translations/response';
import { ContactDA } from '../../../services/contact.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { Company } from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { collapsible } from '../../../tools/collapsible.tool';
import { modalShow } from '../../../tools/modal.tool';
import { uploadTooltip } from '../../../tools/tooltip.tool';
import { imageCompany } from '../../../scripts/image.value.script';
import { bicFormat, ibanFormat } from '../../../scripts/format.value.script';
import { countryName } from '../../../libraries/countries.library';
import { languageDownloadValue } from '../../../libraries/language.download.library';
import { numberAddress } from '../../../libraries/address.number.library';
import { qrTemplateValue } from '../../../libraries/qr.template.library';
import { contactTypeValue } from '../../../libraries/contatc.type.library';

import AppModalContactUpdate from '../../../components/modal/ModalContactUpdate';
import AppModalContactAdd from '../../../components/modal/ModalContactUpdateCompanies';

export interface ContactViewPageProps {};

let errorResponse: Error, messageResponse: Message, contactResponse: Contact;

const ContactViewPage: React.FunctionComponent<ContactViewPageProps> = props => {
  const {setRoute, userLoggedIn, companyForUser} = UseOutletContextContact()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [contact, setContact] = useState<Contact | undefined | null>(null)
  const [companiesOfContact, setCompaniesOfContact] = useState<Company[] | undefined | null>(null)
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])

  const loadContact = async (id_company: string, id_contact: string) => {
    if (userLoggedIn) {
      await ContactDA.getContact(id_company, id_contact).then( (response) => {
        if (response.status === 200) {
          contactResponse = response.data

          let tempCompaniesOfContact: Company[] = userLoggedIn.companies.filter((company) => (contactResponse.data.companies.includes(company.id)))

          setContact(contactResponse)
          setCompaniesOfContact(tempCompaniesOfContact)
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    }
  }

  const submitContactDelete = async () => {
    if (companyForUser && contact) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisContact,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
            await ContactDA.deleteContacts(companyForUser.id, [contact.data.id]).then( (response) => {
              if (response.status === 200) {
                messageResponse = response.data

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions).then( () => {
                  navigate('/app/contact/list', {replace: true})
                })
              } else {
                errorResponse = response.data

                Swal.fire({
                  title: getError(errorResponse.code, lang.code),
                  text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                  icon: 'error',
                  buttonsStyling: !1,
                  confirmButtonText: lang.labels.okGotIt,
                  customClass: {confirmButton: 'btn btn-primary'}
                } as SweetAlertOptions)
              }
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          }
      })
    }
  }

  const executeShowModalContactUpdate = () => {
    modalShow('modal-contact-update')
  }

  const executeShowModalContactCompanies = () => {
    if (contact) {
      setSelectedCompanies(contact.data.companies)
      modalShow('modal-contact-update-companies')
    }
  }

  const executeCollapsible = () => {
    collapsible('button-collapsible-details', 'container-collapsible-details')
  }

  useEffect( () => {
    if(userLoggedIn && companyForUser && param.id) {
      loadContact(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn && companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.contacts, branch: lang.labels.viewContact}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && contact && companiesOfContact
        ?
        <div className="d-flex flex-column flex-xl-row" onLoad={uploadTooltip}>
          <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-5 mb-xl-0">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-center flex-column">
                  <div className="symbol symbol-100px symbol-circle mb-5">
                    <div className="symbol-label fs-3x bg-light-primary text-primary">
                      { contact.type === "person" &&
                        contact.data.last_name.charAt(0)
                      }
                      { contact.type === "company" &&
                        <>
                          { contact.data.logo.length === 0
                            ?
                            contact.data.name.charAt(0)
                            :
                            <img src={imageCompany(contact.data.logo)} alt={lang.labels.avatar} className="w-100 h-100" />
                          }
                        </>
                      }
                    </div>
                  </div>
                  <span className="fs-3 text-gray-800 fw-bolder mb-3">
                    {contact.type === "person" ? `${contact.data.first_name} ${contact.data.last_name}` : `${contact.data.name}`}
                  </span>
                  <div className="mb-5">
                    <div className="badge badge-lg badge-light-primary d-inline text-uppercase">{contactTypeValue(lang, contact.type)}</div>
                  </div>
                </div>
                <div className="d-flex flex-stack fs-5">
                  <div id="button-collapsible-details" className="fw-bolder rotate collapsible" data-bs-toggle="collapse" role="button" onClick={executeCollapsible}>
                    {lang.labels.details}
                    <span className="ms-2 rotate-180">
                      <span className="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="separator"></div>
                <div id="container-collapsible-details" className="collapse show">
                  <div className="fw-bold">
                    <div className="fs-6 mt-5 mb-2">{lang.labels.userUuid}</div>
                    <div className="text-gray-700">{contact.data.uuid.length > 0 ? contact.data.uuid : lang.labels.contactIsNotAnAppUser}</div>
                    <div className="fs-6 mt-5 mb-2">{contact.data.companies.length === 1 ? lang.labels.company : lang.labels.companies}</div>
                    { companiesOfContact.map (( (item, index) => { return (
                      <div key={index} className={`text-gray-700 ${companiesOfContact.length > 1 && "d-flex align-items-center mb-1"}`}>
                        {item.name}
                        { companyForUser.id === item.id && companiesOfContact.length > 1 &&
                          <span className="badge badge-sm badge-light fw-bolder fs-8 ms-3">
                            <small className="text-uppercase">{lang.labels.current}</small>
                          </span>
                        }
                      </div>
                    )}))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-lg-row-fluid ms-lg-5">
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-bolder ms-2 mb-5">
              <li className="nav-item">
                <a href="#container-contact-identity" className="nav-link text-active-primary active" data-bs-toggle="tab">{lang.labels.identity}</a>
              </li>
              <li className="nav-item">
                <a href="#container-contact-information" className="nav-link text-active-primary" data-bs-toggle="tab">{lang.labels.information}</a>
              </li>
              <li className="nav-item">
                <a href="#container-contact-legal" className="nav-link text-active-primary" data-bs-toggle="tab">{lang.labels.legal}</a>
              </li>
              <li className="nav-item">
                <a href="#container-contact-settings" className="nav-link text-active-primary" data-bs-toggle="tab">{lang.labels.settings}</a>
              </li>
              <li className="nav-item ms-auto">
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-primary">
                    <div className="d-flex align-items-center">
                      {lang.labels.actions}
                      <span className="svg-icon svg-icon-2 me-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                        </svg>
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary w-250px p-3" align={"end"}>
                    <Dropdown.Item bsPrefix="menu-item" onClick={executeShowModalContactUpdate}>
                      <p className="menu-link fw-bold px-5 m-0">{lang.labels.editContact}</p>
                    </Dropdown.Item>
                    <Dropdown.Item bsPrefix="menu-item" onClick={submitContactDelete}>
                      <p className="menu-link fw-bold px-5 m-0">{lang.labels.deleteContact}</p>
                    </Dropdown.Item>
                    <Dropdown.Item bsPrefix="menu-item" onClick={executeShowModalContactCompanies}>
                      <p className="menu-link fw-bold px-5 m-0">{lang.labels.addOrRemoveCompanies}</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <div className="tab-content">
              <div id="container-contact-identity" className="tab-pane fade show active" role="tabpanel">
                <div className="card">
                  <div className="card-header border-0">
                    <div className="card-title">
                      <h4>{lang.labels.identityOfContact}</h4>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <table className="table align-middle gy-3 m-0">
                      <tbody className="fw-bold text-gray-700">
                        { contact.type === "person"
                          ?
                          <>
                            <tr>
                              <td className="w-200px text-black">{lang.labels.firstName}</td>
                              <td className="min-w-200px fs-6">{contact.data.first_name}</td>
                            </tr>
                            <tr>
                              <td className="w-200px text-black">{lang.labels.lastName}</td>
                              <td className="min-w-200px fs-6">{contact.data.last_name}</td>
                            </tr>
                          </>
                          :
                          <tr>
                            <td className="w-200px text-black">{lang.labels.name}</td>
                            <td className="min-w-200px fs-6">{contact.data.name}</td>
                          </tr>
                        }
                        <tr>
                          <td className="w-200px text-black">{lang.labels.phone}</td>
                          <td className="min-w-200px fs-6">{contact.data.phone}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.email}</td>
                          <td className="min-w-200px fs-6">{contact.data.email}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.webSite}</td>
                          <td className="min-w-200px fs-6">{contact.data.website.length > 0 ? `${contact.data.website}` : `${lang.labels.none}`}</td>
                        </tr>
                        { contact.type === "company" &&
                          <>
                            <tr>
                              <td className="w-200px text-black">{lang.labels.vatNumber}</td>
                              <td className="min-w-200px fs-6">{contact.data.vat_number.length > 0 ? `${contact.data.vat_number}` : `${lang.labels.none}`}</td>
                            </tr>
                            <tr className="d-none">
                              <td className="w-200px text-black">{lang.labels.registerNumber}</td>
                              <td className="min-w-200px fs-6">{contact.data.register_number.length > 0 ? `${contact.data.register_number}` : `${lang.labels.none}`}</td>
                            </tr>
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div id="container-contact-information" className="tab-pane fade" role="tabpanel">
                <div className="card">
                  <div className="card-header border-0">
                    <div className="card-title">
                      <h4>{lang.labels.informationOfContact}</h4>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <table className="table align-middle gy-3 m-0">
                      <tbody className="fw-bold text-gray-700">
                        <tr>
                          <td className="w-200px text-black">{lang.labels.street}</td>
                          <td className="min-w-200px fs-6">{contact.data.address.street}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.number}</td>
                          <td className="min-w-200px fs-6">{numberAddress(lang, contact.data.address.number)}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.additional}</td>
                          <td className="min-w-200px fs-6">{contact.data.address.additional.length > 0 ? `${contact.data.address.additional}` : `${lang.labels.none}`}</td>
                        </tr>
                        <tr>
                        <td className="w-200px text-black">{lang.labels.administrativeArea}</td>
                          <td className="min-w-200px fs-6">{contact.data.address.administrative_area}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.zipCode}</td>
                          <td className="min-w-200px fs-6">{contact.data.address.zip_code}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.city}</td>
                          <td className="min-w-200px fs-6">{contact.data.address.city}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">{lang.labels.country}</td>
                          <td className="min-w-200px fs-6">{countryName(contact.data.address.country, lang.code)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div id="container-contact-legal" className="tab-pane fade" role="tabpanel">
                <div className="card">
                  <div className="card-header border-0">
                    <div className="card-title">
                      <h4>{lang.labels.legalInformationOfContact}</h4>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <table className="table align-middle gy-3 m-0">
                      <tbody className="fw-bold text-gray-700">
                        { contact.type === "company" &&
                          <tr>
                            <td className="w-200px text-black">{lang.labels.currency}</td>
                            <td className="min-w-200px fs-6">{contact.data.currency.code}: {contact.data.currency.name} ({contact.data.currency.symbol})</td>
                          </tr>
                        }
                        <tr>
                          <td className="w-200px text-black">{lang.labels.financialEntity}</td>
                          <td className="min-w-200px fs-6">{contact.data.bank_account.financial_entity.length > 0 ? `${contact.data.bank_account.financial_entity}` : `${lang.labels.none}`}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">
                            <div className="d-flex align-items-center">
                              {lang.labels.iban}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumber}><FaExclamationCircle /></i>
                            </div>
                          </td>
                          <td className="min-w-200px fs-6">{contact.data.bank_account.iban.length > 0 ? `${ibanFormat(contact.data.bank_account.iban)}` : `${lang.labels.none}`}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">
                            <div className="d-flex align-items-center">
                              {lang.labels.bban}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.basicBankAccountNumber}><FaExclamationCircle /></i>
                            </div>
                          </td>
                          <td className="min-w-200px fs-6">{contact.data.bank_account.bban.length > 0 ? `${contact.data.bank_account.bban}` : `${lang.labels.none}`}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">
                            <div className="d-flex align-items-center">
                              {lang.labels.bicSwift}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.bankIdentifierCode}><FaExclamationCircle /></i>
                            </div>
                          </td>
                          <td className="min-w-200px fs-6">{contact.data.bank_account.bic.length > 0 ? `${bicFormat(contact.data.bank_account.bic)}` : `${lang.labels.none}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div id="container-contact-settings" className="tab-pane fade" role="tabpanel">
                <div className="card">
                  <div className="card-header border-0">
                    <div className="card-title">
                      <h4>{lang.labels.fileDownloadSettings}</h4>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <table className="table align-middle gy-3 m-0">
                      <tbody className="fw-bold text-gray-700">
                        <tr>
                          <td className="w-200px text-black">
                            <div className="d-flex align-items-center">
                              {lang.labels.language}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                            </div>
                          </td>
                          <td className="text-capitalize">{languageDownloadValue(lang, contact.data.language)}</td>
                        </tr>
                        <tr>
                          <td className="w-200px text-black">
                            <div className="d-flex align-items-center">
                              {lang.labels.qrType}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                            </div>
                          </td>
                          <td className="min-w-200px fs-6">{qrTemplateValue(lang, contact.data.qr_type)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalContactUpdate companyForUser={companyForUser} contact={contact} contacts={null} companiesOfContact={companiesOfContact} setContact={setContact} setContacts={null} loadContactsForCompany={null}></AppModalContactUpdate>
      <AppModalContactAdd userLoggedIn={userLoggedIn} companyForUser={companyForUser} contact={contact} contacts={null} selectedCompanies={selectedCompanies} setContact={setContact} setContacts={null} setCompaniesOfContact={setCompaniesOfContact} setSelectedCompanies={setSelectedCompanies} loadContactsForCompany={null}></AppModalContactAdd>
    </div>
  )
};

export default ContactViewPage;
