import axios from 'axios';
import { ApiResponse } from '../models/api.response.model';
import { TokenAccess } from '../models/token.access.model';
import { Error } from '../models/error.model';
import { User } from '../models/user.model';
import { Message } from '../models/message.model';

export class AccessDL {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async invitation(api_key: string, email: string, token: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/companies/access`

    let tokenAccess: TokenAccess | null = null

    let error: Error = {
      code: 0,
      message: ''
    }
    let data = {
      email,
      invitation_token: token
    }

    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.post(url, data)
    .then(response => { tokenAccess = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (tokenAccess) {
      this.dataResponse = tokenAccess

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async verification(api_key: string, email: string, token: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}user/verification`
    let user: User | null = null

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    let data = {
      email,
      verification_token: token
    }

    await axios.post(url, data)
    .then(response => { messageResponse = response.data})
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully verified account') {

      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
