import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { TagsInput } from "react-tag-input-component";
import { FaExclamationCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Slider from 'rc-slider';
import Carousel from 'react-multi-carousel';

import { UseOutletContextProduct } from './Product';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ProductDA } from '../../../../services/product.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Unit } from '../../../../models/unit.model';
import { Legend } from '../../../../styles/legend.style';
import { legendInvalid, legendInvalidIcon, legendInvalidEmptyIcon, legendValid, legendValidIcon, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../../../tools/legend.data.entry.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { extractBase64 } from '../../../../scripts/extract.base.64.script';
import { imageProduct } from '../../../../scripts/image.value.script';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { categorySelected } from '../../../../libraries/selected.item.list.library';
import { measureUnitSelected } from '../../../../libraries/selected.item.list.library';
import { languageDownloadValue } from '../../../../libraries/language.download.library';

import AppModalCategoryCreate from '../../../../components/modal/ModalCategoryCreate';
import ModalProductVariantAdd from '../../../../components/modal/ModalProductVariantAdd';
import ModalProductVariantEdit from '../../../../components/modal/ModalProductVariantEdit';

import imgDefault from '../../../../assets/images/default.png';

export interface ProductCreatePageProps {};

let errorResponse: Error, messageResponse: Message;

const ProductCreatePage: React.FunctionComponent<ProductCreatePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, categoriesForCompany, setCategoriesForCompany, loadProductsForCompany} = UseOutletContextProduct()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const expressions = {
    //eslint-disable-next-line
    name: /^[A-Za-zÀ-ÿ0-9\s]{1,100}$/,
    //eslint-disable-next-line
    tag: /^[A-Za-zÀ-ÿ0-9\'\.\-\,\#\°?\s!@#$%^&*()+=[\]{};:'"<>|\\/~_/]{0,100}$/,
    //eslint-disable-next-line
    text: /^[\s\S]{1,2000}$/,
    //eslint-disable-next-line
    code: /^[A-Za-z0-9\'\.\-\,\#\°?\s!@#$%^&*()+=[\]{};:'"<>|\\/~_/]{0,100}$/,
    //eslint-disable-next-line
    sku: /^[A-Za-z0-9]{8,22}$/,
    //eslint-disable-next-line
    codebar: /^\d{6,22}$/,
    //eslint-disable-next-line
    size: /^[A-Za-z0-9\s-]+$/,
    //eslint-disable-next-line
    color: /^[a-zA-Z]+$/,
    //eslint-disable-next-line
    currency: /^-?[0-9]+(\.[0-9]{1,2})?$/,
    //eslint-disable-next-line
    quantity: /^\d+(\.\d{1,2})?$/
  }

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [indexVariantEdit, setIndexVariantEdit] = useState(0)
  const [name, setName] = useState({value: '', valid: false})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
  const [category, setCategory] = useState({value: '', valid: false})
  const [tags, setTags] = useState({value: [] as string[], valid: true})
  const [variantsValue, setVariantsValue] = useState([{description: '', product_variant_code: '', sku: '', codebar: '', size: '', color: '', tax_rate: '', price: '', unit: {id: '', type: '', name: '', symbol: ''}, discount: {type: 'none', value: '', quantity: ''}, photos: [] as {view: string, file: any}[]}])
  const [variantsValid, setVariantsValid] = useState([{description: false, product_variant_code: true, sku: true, codebar: true, size: true, color: true, tax_rate: true, price: false, gallery: true, unit: true, discount: {type: true, value: true, quantity: true}, photos: [] as {file: boolean, message: string}[]}])
  const [variantValueModal, setVariantValueModal] = useState({description: '', product_variant_code: '', sku: '', codebar: '', size: '', color: '', tax_rate: '', price: '', unit: {id: '', type: '', name: '', symbol: ''}, discount: {type: 'none', value: '', quantity: ''}, photos: [] as {view: string, file: any}[]})
  const [variantValidModal, setVariantValidModal] = useState({description: false, product_variant_code: true, sku: true, codebar: true, size: true, color: true, tax_rate: true, price: false, gallery: true, unit: true, discount: {type: true, value: true, quantity: true}, photos: [] as {file: boolean, message: string}[]})

  const submitProductCreate = async () => {
    setLoadIndicator('on')

    const namesIsValid: boolean = !Object.values(names.valid).includes(false)
    const variantsIsValid: boolean = variantsValid.every(item => validateAttributes(item))

    if (companyForUser && name.valid && namesIsValid && tags.valid && category.valid && variantsIsValid) {
      let variantList = variantsValue.map(item => {
        return {
          description: item.description,
          product_variant_code: item.product_variant_code,
          sku: item.sku,
          codebar: item.codebar,
          size: item.size,
          color: item.color,
          price: item.price,
          tax_rate: item.tax_rate,
          unit: item.unit,
          discount: {
            type: item.discount.type,
            value: item.discount.value,
            quantity: item.discount.quantity
          },
          photos: item.photos.map(sub_item => {return sub_item.file})
          }
        })

      await ProductDA.postProduct(companyForUser.id, name.value, names.value, tags.value, category.value, variantList).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          loadProductsForCompany(companyForUser.id)

          Swal.fire({
            title: `${messageResponse.message}!`,
            text: 'Updating company product list, redirecting to product list page',
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/inventory/product/list')
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('input-name', 'container-validate-name-required')
        }
        if (category.value.length === 0) {
          legendInvalid('container-validate-category-required')
        }
        /* if (tags.value.length === 0) {
          legendInvalid('container-validate-tags-required')
        } */
        if (variantsValue[0].description.length === 0) {
          legendInvalidIcon('input-variant-description', 'container-validate-variant-description-required')
        }
        if (variantsValue[0].price.length === 0) {
          legendInvalidIcon('input-variant-price', 'container-validate-variant-price-required')
        }
        if (variantsValue[0].discount.type !== 'none') {
          if (variantsValue[0].discount.value.length === 0) {
            legendInvalidIcon('input-variant-discount-value', 'container-validate-variant-discount-value-required')
          }
          if (variantsValue[0].discount.quantity.length === 0) {
            legendInvalidIcon('input-variant-discount-quantity', 'container-validate-variant-discount-quantity-required')
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeNames = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNames(names => ({...names, value: {...names.value, [item]: event.target.value}}))
  }

  const handleChangeCategory = (item: string) => {
    setCategory({...category, value: item, valid: true})
    legendValid('container-validate-category-required')
  }

  const handleChangeTags = (items: string[]) => {
    setTags({...tags, value: items})
  }

  const handleChangeDescriptionToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], description: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeCodeToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], product_variant_code: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeSkuToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], sku: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeCodebarToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], codebar: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeSizeToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], size: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeColorToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], color: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeUnitToVariant = (item: Unit) => {
    setVariantsValue([{...variantsValue[0], unit: item}, ...variantsValue.slice(1)])
    setVariantsValid([{...variantsValid[0], unit: true}, ...variantsValid.slice(1)])
  }

  const handleChangeTaxRateToVariant = (item: string) => {
    setVariantsValue([{...variantsValue[0], tax_rate: item}, ...variantsValue.slice(1)])
    setVariantsValid([{...variantsValid[0], tax_rate: true}, ...variantsValid.slice(1)])
  }

  const handleChangePriceToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], price: event.target.value}, ...variantsValue.slice(1)])
  }

  const handleChangeDiscountTypeToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], discount: {type: event.target.value, value: (event.target.value === 'percentage') ? '0' : '', quantity: (event.target.value === 'none') ? '' : variantsValue[0].discount.quantity}}, ...variantsValue.slice(1)])
    setVariantsValid([{...variantsValid[0], discount: {type: true, value: (event.target.value === 'none') ? true : false, quantity: (event.target.value === 'none') ? true : false}}, ...variantsValid.slice(1)])
  }

  const handleChangeSlideDiscountValueToVariant = (item: number | number[]) => {
    setVariantsValue([{...variantsValue[0], discount: {...variantsValue[0].discount, value: item.toString()}}, ...variantsValue.slice(1)])
    setVariantsValid([{...variantsValid[0], discount: {...variantsValid[0].discount, value: true}}, ...variantsValid.slice(1)])
  }

  const handleChangeInputDiscountValueToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], discount: {...variantsValue[0].discount, value: event.target.value}}, ...variantsValue.slice(1)])
  }

  const handleChangeInputDiscountQuantityToVariant = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVariantsValue([{...variantsValue[0], discount: {...variantsValue[0].discount, quantity: event.target.value}}, ...variantsValue.slice(1)])
  }

  const handleChangeFiles = async (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
    let archiveValueList: {view: string, file: any}[] = []
    let archiveValidList: {file: boolean, message: string}[] = []
    let archivesCapture: any = event.target.files
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576
    let images: Promise<any>[] = []

    for (let archiveCapture of archivesCapture) {
      if (!variantsValue[0].photos.some((existingArchive) => existingArchive.file.name === archiveCapture.name)) {
        if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
          archiveValueList.push({view: '', file: archiveCapture})
          archiveValidList.push({file: false, message: lang.labels.invalidFileType})
        } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
          archiveValueList.push({view: '', file: archiveCapture})
          archiveValidList.push({file: false, message: lang.labels.fileSizeNotAllowed})
        } else {
          images.push(
            extractBase64(archiveCapture).then((image: any) => {
              archiveValueList.push({view: image.base, file: archiveCapture});
              archiveValidList.push({file: true, message: lang.labels.valid});
            })
          )
        }
      }
    }

    await Promise.all(images)

    setVariantsValue([{...variantsValue[0], photos: [...variantsValue[0].photos, ...archiveValueList]}, ...variantsValue.slice(1)])
    setVariantsValid([{...variantsValid[0], photos: [...variantsValid[0].photos, ...archiveValidList], gallery: ([...variantsValid[0].photos, ...archiveValidList].length > 10) ? false : true}, ...variantsValid.slice(1)])
    cleanInputFile()

    if ([...variantsValid[0].photos, ...archiveValidList].length > 10) {
      legendInvalid('container-validate-variant-gallery-valid')
    } else {
      legendValid('container-validate-variant-gallery-valid')
    }
  }

  const validateName = () => {
    if (expressions) {
      if (expressions.name.test(name.value)) {
        setName({...name, valid: true})
        legendValidRequiredIcon('input-name', 'container-validate-name-valid', 'container-validate-name-required')
      } else {
        setName({...name, valid: false})
        legendInvalidEmptyIcon(name.value, 'input-name', 'container-validate-name-valid', 'container-validate-name-required')
      }
    }
  }

  const validateNames = (index: number, item: string) => {
    if (expressions && names.value[item as keyof typeof names.value].length > 0) {
      if (expressions.name.test(names.value[item as keyof typeof names.value])) {
        setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
        legendValidIcon('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
      } else {
        setNames(names => ({...names, valid: {...names.valid, [item]: false}}))
        legendInvalidIcon('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
      }
    } else {
      setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
      legendValidInvalidIconRestart('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
    }
  }

  const validateTagKeyUp = (event: React.KeyboardEvent <HTMLFormElement | HTMLInputElement>) => {
    if (expressions) {
      if (expressions.tag.test(event.currentTarget.value)) {
        if (!tags.value.includes(event.currentTarget.value)) {
          setTags({...tags, valid: true})
          legendValid('container-validate-tags-valid')
          legendValid('container-validate-tags-existed')
        } else {
          setTags({...tags, valid: false})
          legendInvalid('container-validate-tags-existed')
        }
      } else {
        setTags({...tags, valid: false})
        legendInvalid('container-validate-tags-valid')
      }
    }
  }

  const validateTagBlur = (event: React.FocusEvent <HTMLFormElement | HTMLInputElement>) => {
    /* if (expressions && event.currentTarget.value.length > 0 && expressions.tag.test(event.currentTarget.value) && !tags.value.includes(event.currentTarget.value)) {
      setTags({...tags, value: [...tags.value, event.currentTarget.value], valid: true})
      legendValid('container-validate-tags-required')
    } else if (tags.value.length === 0) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-required')
    } */

    if (tags.value.includes(event.currentTarget.value)) {
      legendValidInvalidRestart('container-validate-tags-existed')
      setTags({...tags, valid: true})
    }

    event.currentTarget.value = ''
    legendValidInvalidRestart('container-validate-tags-valid')
  }

  const validateTagAdd = (item: string, items: string[]) => {
    if (expressions && items) {
      if (expressions.tag.test(item)) {
        setTags({...tags, valid: true})
        legendValid('container-validate-tags-valid')
        /* legendValid('container-validate-tags-required') */
        return true;
      } else {
        setTags({...tags, valid: false})
        legendInvalid('container-validate-tags-valid')
        return false;
      }
    } else {
      setTags({...tags, valid: false})
      legendValidInvalidRestart('container-validate-tags-valid')
      /* legendValidInvalidRestart('container-validate-tags-required') */
      return false;
    }
  }

  const validateTagExisting = (item: string) => {
    if (item.length > 0) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-existed')
    } else {
      setTags({...tags, valid: true})
      legendValid('container-validate-tags-existed')
    }
  }

  const validateTagRemoved = (item: string) => {
    /* if (item.length > 0 && tags.value.length === 1) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-required')
    } else {
      setTags({...tags, valid: true})
      legendValid('container-validate-tags-required')
    } */
    setTags({...tags, valid: true})
  }

  const validateDescriptionToVariant = () => {
    if (expressions) {
      if (expressions.text.test(variantsValue[0].description)) {
        setVariantsValid([{...variantsValid[0], description: true}, ...variantsValid.slice(1)])
        legendValidRequiredIcon('input-variant-description', 'container-validate-variant-description-valid', 'container-validate-variant-description-required')
      } else {
        setVariantsValid([{...variantsValid[0], description: false}, ...variantsValid.slice(1)])
        legendInvalidEmptyIcon(variantsValue[0].description, 'input-variant-description', 'container-validate-variant-description-valid', 'container-validate-variant-description-required')
      }
    }
  }

  const validateCodeToVariant = () => {
    if (expressions && variantsValue[0].product_variant_code.length > 0) {
      if (expressions.code.test(variantsValue[0].product_variant_code)) {
        setVariantsValid([{...variantsValid[0], product_variant_code: true}, ...variantsValid.slice(1)])
        legendValidIcon('input-variant-code', 'container-validate-variant-code-valid')
      } else {
        setVariantsValid([{...variantsValid[0], product_variant_code: false}, ...variantsValid.slice(1)])
        legendInvalidIcon('input-variant-code', 'container-validate-variant-code-valid')
      }
    } else if (variantsValue[0].product_variant_code.length === 0) {
      setVariantsValid([{...variantsValid[0], product_variant_code: true}, ...variantsValid.slice(1)])
      legendValidInvalidIconRestart('input-variant-code', 'container-validate-variant-code-valid')
    }
  }

  const validateSkuToVariant = () => {
    if (expressions && variantsValue[0].sku.length > 0) {
      if (expressions.sku.test(variantsValue[0].sku)) {
        setVariantsValid([{...variantsValid[0], sku: true}, ...variantsValid.slice(1)])
        legendValidIcon('input-variant-sku', 'container-validate-variant-sku-valid')
      } else {
        setVariantsValid([{...variantsValid[0], sku: false}, ...variantsValid.slice(1)])
        legendInvalidIcon('input-variant-sku', 'container-validate-variant-sku-valid')
      }
    } else if (variantsValue[0].sku.length === 0) {
      setVariantsValid([{...variantsValid[0], sku: true}, ...variantsValid.slice(1)])
      legendValidInvalidIconRestart('input-variant-sku', 'container-validate-variant-sku-valid')
    }
  }

  const validateCodebarToVariant = () => {
    if (expressions && variantsValue[0].codebar.length > 0) {
      if (expressions.codebar.test(variantsValue[0].codebar)) {
        setVariantsValid([{...variantsValid[0], codebar: true}, ...variantsValid.slice(1)])
        legendValidIcon('input-variant-codebar', 'container-validate-variant-codebar-valid')
      } else {
        setVariantsValid([{...variantsValid[0], codebar: false}, ...variantsValid.slice(1)])
        legendInvalidIcon('input-variant-codebar', 'container-validate-variant-codebar-valid')
      }
    } else if (variantsValue[0].codebar.length === 0) {
      setVariantsValid([{...variantsValid[0], codebar: true}, ...variantsValid.slice(1)])
      legendValidInvalidIconRestart('input-variant-codebar', 'container-validate-variant-codebar-valid')
    }
  }

  const validateSizeToVariant = () => {
    if (expressions && variantsValue[0].size.length > 0) {
      if (expressions.size.test(variantsValue[0].size)) {
        setVariantsValid([{...variantsValid[0], size: true}, ...variantsValid.slice(1)])
        legendValidIcon('input-variant-size', 'container-validate-variant-size-valid')
      } else {
        setVariantsValid([{...variantsValid[0], size: false}, ...variantsValid.slice(1)])
        legendInvalidIcon('input-variant-size', 'container-validate-variant-size-valid')
      }
    } else if (variantsValue[0].size.length === 0) {
      setVariantsValid([{...variantsValid[0], size: true}, ...variantsValid.slice(1)])
      legendValidInvalidIconRestart('input-variant-size', 'container-validate-variant-size-valid')
    }
  }

  const validateColorToVariant = () => {
    if (expressions && variantsValue[0].color.length > 0) {
      if (expressions.color.test(variantsValue[0].color)) {
        setVariantsValid([{...variantsValid[0], color: true}, ...variantsValid.slice(1)])
        legendValidIcon('input-variant-color', 'container-validate-variant-color-valid')
      } else {
        setVariantsValid([{...variantsValid[0], color: false}, ...variantsValid.slice(1)])
        legendInvalidIcon('input-variant-color', 'container-validate-variant-color-valid')
      }
    } else if (variantsValue[0].color.length === 0) {
      setVariantsValid([{...variantsValid[0], color: true}, ...variantsValid.slice(1)])
      legendValidInvalidIconRestart('input-variant-color', 'container-validate-variant-color-valid')
    }
  }

  const validatePriceToVariant = () => {
    if (expressions) {
      if (expressions.currency.test(variantsValue[0].price)) {
        setVariantsValid([{...variantsValid[0], price: true}, ...variantsValid.slice(1)])
        legendValidRequiredIcon('input-variant-price', 'container-validate-variant-price-valid', 'container-validate-variant-price-required')
      } else {
        setVariantsValid([{...variantsValid[0], price: false}, ...variantsValid.slice(1)])
        legendInvalidEmptyIcon(variantsValue[0].price, 'input-variant-price', 'container-validate-variant-price-valid', 'container-validate-variant-price-required')
      }
    }
  }

  const validateDiscountValueToVariant = () => {
    if (expressions) {
      if (expressions.currency.test(variantsValue[0].discount.value)) {
        setVariantsValid([{...variantsValid[0], discount: {...variantsValid[0].discount, value: true}}, ...variantsValid.slice(1)])
        legendValidRequiredIcon('input-variant-discount-value', 'container-validate-variant-discount-value-valid', 'container-validate-variant-discount-value-required')
      } else {
        setVariantsValid([{...variantsValid[0], discount: {...variantsValid[0].discount, value: false}}, ...variantsValid.slice(1)])
        legendInvalidEmptyIcon(variantsValue[0].discount.value, 'input-variant-discount-value', 'container-validate-variant-discount-value-valid', 'container-validate-variant-discount-value-required')
      }
    }
  }

  const validateDiscountQuantityToVariant = () => {
    if (expressions) {
      if (expressions.quantity.test(variantsValue[0].discount.quantity)) {
        setVariantsValid([{...variantsValid[0], discount: {...variantsValid[0].discount, quantity: true}}, ...variantsValid.slice(1)])
        legendValidRequiredIcon('input-variant-discount-quantity', 'container-validate-variant-discount-quantity-valid', 'container-validate-variant-discount-quantity-required')
      } else {
        setVariantsValid([{...variantsValid[0], discount: {...variantsValid[0].discount, quantity: false}}, ...variantsValid.slice(1)])
        legendInvalidEmptyIcon(variantsValue[0].discount.quantity, 'input-variant-discount-quantity', 'container-validate-variant-discount-quantity-valid', 'container-validate-variant-discount-quantity-required')
      }
    }
  }

  const executeRemoveFile = (index: number) => {
    let tempVariantsValue = variantsValue[0].photos.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempVariantsValid = variantsValid[0].photos.filter((_temp_item, temp_index) => (temp_index !== index))

    setVariantsValue([{...variantsValue[0], photos: tempVariantsValue}, ...variantsValue.slice(1)])
    setVariantsValid([{...variantsValid[0], photos: tempVariantsValid, gallery: ((tempVariantsValid.length > 10) ? false : true)}, ...variantsValid.slice(1)])
    cleanInputFile()

    if (tempVariantsValid.length > 10) {
      legendInvalid('container-validate-variant-gallery-valid')
    } else {
      legendValid('container-validate-variant-gallery-valid')
    }
  }

  const executeShowModalCategoryCreate = () => {
    modalShow('modal-category-create')
  }

  const executeShowModalProductVariantAdd = () => {
    modalShow('modal-product-variant-add')
  }

  const executeShowModalProductVariantEdit = (index: number) => {
    setIndexVariantEdit(index)
    setVariantValueModal(variantsValue[index])
    setVariantValidModal(variantsValid[index])
    modalShow('modal-product-variant-edit')
  }

  const executeShowModalProductVariantRemove = (index: number) => {
    let tempVatiantsValue = variantsValue.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempVatiantsValid = variantsValid.filter((_temp_item, temp_index) => (temp_index !== index))

    setVariantsValue(tempVatiantsValue)
    setVariantsValid(tempVatiantsValid)
  }

  function cleanInputFile() {
    Array.from(document.querySelectorAll('input[name="photos"]')).forEach( (node)  => {
      let element = node as HTMLInputElement
      element.value = ''
    })
  }

  function validateAttributes(item: any) {
    for (let key in item) {
      if (typeof item[key] === 'object') {
        if (Array.isArray(item[key])) {
          for (let i = 0; i < item[key].length; i++) {
            if (!validateAttributes(item[key][i])) {
              return false
            }
          }
        } else {
          if (!validateAttributes(item[key])) {
            return false
          }
        }
      } else {
        if (!item[key]) {
          return false
        }
      }
    }
    return true
  }

  function discountProduct(discount: {type: string, value: string, quantity: string}): string {
    switch (discount.type) {
      case ('percentage'):
        return `${discount.value} % x ${discount.quantity} units`
      case ('fixed'):
        return `${companyForUser && companyForUser.currency.symbol} ${moneyFormat(Number(discount.value))} x ${discount.quantity} units`
      case ('none'):
        return `${companyForUser && companyForUser.currency.symbol} ${moneyFormat(0)}`
      default:
        return ''
    }
  }

  function unitsProductForCompany(units: Unit[]): Unit[] {
    return units.filter((item: Unit) => (item.type === 'product'))
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: 'Products', branch: 'Create Product'}, company: true})
    uploadTooltip()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { settingsForCompany && categoriesForCompany
        ?
        <div className="form d-flex flex-column flex-lg-row">
          <div className="d-none d-lg-flex flex-column flex-lg-row-auto gap-5 gap-lg-7 w-100 w-lg-350px me-lg-7">
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h2>Name in other languages</h2>
                </div>
              </div>
              <div className="card-body pt-0">
                <label className="form-label">Enter in language you want</label>
                { Object.keys(names.value).map (( (item, index) => { return (
                  <div key={index}>
                    <input id={"input-language-name-" + index} className="form-control my-2" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item.toUpperCase())} value={names.value[item as keyof typeof names.value]} onChange={(event) => handleChangeNames(item, event)} onKeyUp={() => validateNames(index, item)} onBlur={() => validateNames(index, item)} />
                    <Legend property={names.valid[item as keyof typeof names.valid].toString()}>
                      <div id={"container-validate-language-name-valid-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                        <div data-field="language-name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                      </div>
                    </Legend>
                  </div>
                )}))}
                <div className="text-muted fs-7 mt-5">If you wish, you can assign the name of the product in languages that you think are necessary.</div>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h2>Product Details</h2>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="d-flex justify-content-between align-items-end flex-wrap mb-3">
                  <label className="form-label required">Category</label>
                  <button className="btn btn-light-primary btn-sm" type="button" onClick={executeShowModalCategoryCreate}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      Add category
                    </div>
                  </button>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0 mb-2">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {category.valid ? `${categorySelected(lang, categoriesForCompany, category.value)}` : `${lang.labels.selectOption}`}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { categoriesForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === category.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === category.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => handleChangeCategory(item.id)}>{item.display_name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="text-muted fs-7">Add product to a category.</div>
                <Legend property={category.valid.toString()}>
                  <div id="container-validate-category-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="category" data-validator="notEmpty">Category is required</div>
                  </div>
                </Legend>
                <div className="separator my-5"></div>
                <label className="form-label">Tags</label>
                <TagsInput classNames={{tag: "tag-cls bg-dark bg-opacity-10 fs-7", input: "input-cls form-control form-control-solid w-100"}} name="tags" value={tags.value} onChange={handleChangeTags} onKeyUp={validateTagKeyUp} onBlur={validateTagBlur} beforeAddValidate={validateTagAdd} onExisting={validateTagExisting} onRemoved={validateTagRemoved} />
                <div className="text-muted fs-7"> ​Add tags to a product.</div>
                <Legend property={tags.valid.toString()}>
                  <div id="container-validate-tags-valid" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="tags" data-validator="text">The value is not a valid tag</div>
                  </div>
                  <div id="container-validate-tags-existed" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="tags" data-validator="text">Tag is already exist</div>
                  </div>
                  <div id="container-validate-tags-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="tags" data-validator="notEmpty">Tags is required</div>
                  </div>
                </Legend>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-5 gap-lg-7">
            <div className="d-flex flex-column gap-5 gap-lg-7">
              <div className="card card-flush">
                <div className="card-header">
                  <div className="card-title">
                    <h2>General</h2>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <label className="form-label required">Product name</label>
                  <input id="input-name" className="form-control form-control-solid mb-2" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                  <div className="text-muted fs-7">A product name is required and recommended to be unique.</div>
                  <Legend property={name.valid.toString()}>
                    <div id="container-validate-name-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                    <div id="container-validate-name-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="name" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                    </div>
                  </Legend>
                </div>
              </div>
              <div className="card card-flush d-lg-none">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Product Details</h2>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="d-flex justify-content-between align-items-end flex-wrap mb-3">
                    <label className="form-label required">Category</label>
                    <button className="btn btn-light-primary btn-sm" type="button" onClick={executeShowModalCategoryCreate}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                          </svg>
                        </span>
                        Add category
                      </div>
                    </button>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0 mb-2">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {category.valid ? `${categorySelected(lang, categoriesForCompany, category.value)}` : `${lang.labels.selectOption}`}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { categoriesForCompany.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === category.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === category.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => handleChangeCategory(item.id)}>{item.display_name}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="text-muted fs-7">Add product to a category.</div>
                  <Legend property={category.valid.toString()}>
                    <div id="container-validate-category-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="category" data-validator="notEmpty">Category is required</div>
                    </div>
                  </Legend>
                  <div className="separator my-5"></div>
                  <label className="form-label">Tags</label>
                  <TagsInput classNames={{tag: "tag-cls bg-dark bg-opacity-10 fs-7", input: "input-cls form-control form-control-solid w-100"}} name="tags" value={tags.value} onChange={handleChangeTags} onKeyUp={validateTagKeyUp} onBlur={validateTagBlur} beforeAddValidate={validateTagAdd} onExisting={validateTagExisting} onRemoved={validateTagRemoved} />
                  <div className="text-muted fs-7"> ​Add tags to a product.</div>
                  <Legend property={tags.valid.toString()}>
                    <div id="container-validate-tags-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="tags" data-validator="text">The value is not a valid tag</div>
                    </div>
                    <div id="container-validate-tags-existed" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="tags" data-validator="text">Tag is already exist</div>
                    </div>
                    <div id="container-validate-tags-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="tags" data-validator="notEmpty">Tags is required</div>
                    </div>
                  </Legend>
                </div>
              </div>
              <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h2>{(variantsValue.length > 1 && variantsValid.length > 1) ? "Variants" : "Advanced"}</h2>
                </div>
                <div className="card-toolbar">
                  <button className="btn btn-light-primary" type="button" disabled={!validateAttributes(variantsValid[0])} onClick={executeShowModalProductVariantAdd}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      Add Variant
                    </div>
                  </button>
                </div>
              </div>
              <div className="card-body pt-0">
                { variantsValue.length > 1 && variantsValid.length > 1
                  ?
                  <>
                    { variantsValue.map (( (item, index) => { return (
                      <div key={index} className="d-flex flex-column bg-white border border-dashed border-gray-400 rounded p-5 m-2">
                        <div className="d-flex flex-stack">
                          <label className="form-label d-flex justify-content-start align-items-center">
                            <span className="svg-icon svg-icon-2 svg-icon-primary me-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M20 22H4C3.4 22 3 21.6 3 21V2H21V21C21 21.6 20.6 22 20 22Z" fill="black"/>
                                <path d="M12 14C9.2 14 7 11.8 7 9V5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5V9C9 10.7 10.3 12 12 12C13.7 12 15 10.7 15 9V5C15 4.4 15.4 4 16 4C16.6 4 17 4.4 17 5V9C17 11.8 14.8 14 12 14Z" fill="black"/>
                              </svg>
                            </span>
                            <strong>
                              {item.description}
                            </strong>
                          </label>
                          <div className="d-flex justify-content-end align-items-center">
                            <button type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-2" onClick={() => executeShowModalProductVariantEdit(index)}>
                              <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.edit} onClick={removeTooltip}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
                                  <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
                                </svg>
                              </span>
                            </button>
                            <button type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-2" onClick={() => executeShowModalProductVariantRemove(index)}>
                              <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.remove} onClick={removeTooltip}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                                  <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                                  <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-xl-row">
                          <div className="flex-column flex-xl-row-auto me-xl-5">
                            <table className="fs-7 fw-bold">
                              <tbody>
                                <tr>
                                  <td className="w-100px text-gray-500">SKU:</td>
                                  <td className="text-gray-800 text-nowrap">{(item.sku.length > 0) ? item.sku : "None"}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Codebar:</td>
                                  <td className="text-gray-800 text-nowrap">{(item.codebar.length > 0) ? item.codebar : "None"}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Size:</td>
                                  <td className="text-gray-800">{(item.size.length > 0) ? item.size : "None"}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Color:</td>
                                  <td className="text-gray-800 text-capitalize">{(item.color.length > 0) ? item.color : "None"}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Unit:</td>
                                  <td className="text-gray-800 text-capitalize">{(item.unit.id.length > 0) ? item.unit.name : "None"}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Tax:</td>
                                  <td className="text-gray-800 text-nowrap">{(item.tax_rate.length > 0) ? item.tax_rate : "None"}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Price:</td>
                                  <td className="text-gray-800 text-nowrap">{companyForUser?.currency.symbol} {moneyFormat(Number(item.price))}</td>
                                </tr>
                                <tr>
                                  <td className="w-100px text-gray-500">Discount:</td>
                                  <td className="text-gray-800">
                                    <span className="me-2 text-nowrap">{discountProduct(item.discount)}</span>
                                    <span className="badge badge-light fs-9 fw-bolder text-uppercase">{item.discount.type}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="flex-xl-row-fluid mt-3">
                            { item.photos.length > 0
                              ?
                              <Carousel autoPlay={true} showDots={true} infinite={true} partialVisible={false} arrows={false} responsive={{desktop: {breakpoint: {max: 3000, min: 992}, items: 4}, tablet: {breakpoint: {max: 991, min: 576}, items: 3}, mobile: {breakpoint: {max: 475, min: 0}, items: 2}}}>
                                { item.photos.map((item, index) => {
                                  return (
                                    <div className="overflow-hidden mx-1 pb-6" key={index}>
                                      <img src={imageProduct(item.view)} alt={"photography-" + index} className="w-100 h-100px" />
                                    </div>
                                  )
                                })}
                              </Carousel>
                              :
                              <img src={imgDefault} className="img-fluid h-100px" alt={"photography"} />
                            }
                          </div>
                        </div>
                      </div>
                    )}))}
                  </>
                  :
                  <>
                    <div className="mb-5">
                      <div className="d-flex align-items-center mb-2">
                        <label className="form-label m-0">Code</label>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title="Unique product code assigned by the company"><FaExclamationCircle /></i>
                      </div>
                      <input id="input-variant-code" className="form-control form-control-solid mb-2" type="text" name="code" value={variantsValue[0].product_variant_code} onChange={handleChangeCodeToVariant} onKeyUp={validateCodeToVariant} onBlur={validateCodeToVariant} />
                      <Legend property={variantsValid[0].product_variant_code.toString()}>
                        <div id="container-validate-variant-code-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="code" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                        </div>
                      </Legend>
                    </div>
                    <div className="mb-5">
                      <label className="form-label required">Description</label>
                      <input id="input-variant-description" className="form-control form-control-solid mb-2" type="text" name="description" value={variantsValue[0].description} onChange={handleChangeDescriptionToVariant} onKeyUp={validateDescriptionToVariant} onBlur={validateDescriptionToVariant} />
                      <Legend property={variantsValid[0].description.toString()}>
                        <div id="container-validate-variant-description-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="description" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                        </div>
                        <div id="container-validate-variant-description-required" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="description" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                        </div>
                      </Legend>
                    </div>
                    <div className="d-flex flex-wrap gap-5 mb-5">
                      <div className="fv-row w-100 flex-md-root">
                        <label className="form-label">SKU</label>
                        <input id="input-variant-sku" className="form-control form-control-solid mb-2" type="text" name="sku" value={variantsValue[0].sku} onChange={handleChangeSkuToVariant} onKeyUp={validateSkuToVariant} onBlur={validateSkuToVariant} />
                        <Legend property={variantsValid[0].sku.toString()}>
                          <div id="container-validate-variant-sku-valid" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="sku" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                        </Legend>
                      </div>
                      <div className="fv-row w-100 flex-md-root">
                        <label className="form-label">Codebar</label>
                        <input id="input-variant-codebar" className="form-control form-control-solid mb-2" type="text" name="codebar" value={variantsValue[0].codebar} onChange={handleChangeCodebarToVariant} onKeyUp={validateCodebarToVariant} onBlur={validateCodebarToVariant} />
                        <Legend property={variantsValid[0].codebar.toString()}>
                          <div id="container-validate-variant-codebar-valid" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="codebar" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                        </Legend>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-5 mb-5">
                      <div className="fv-row w-100 flex-md-root">
                        <label className="form-label">Size</label>
                        <input id="input-variant-size" className="form-control form-control-solid mb-2" type="text" name="size" value={variantsValue[0].size} onChange={handleChangeSizeToVariant} onKeyUp={validateSizeToVariant} onBlur={validateSizeToVariant} />
                        <Legend property={variantsValid[0].size.toString()}>
                          <div id="container-validate-variant-size-valid" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="size" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                        </Legend>
                      </div>
                      <div className="fv-row w-100 flex-md-root">
                        <label className="form-label">Color</label>
                        <input id="input-variant-color" className="form-control form-control-solid mb-2" type="text" name="color" value={variantsValue[0].color} onChange={handleChangeColorToVariant} onKeyUp={validateColorToVariant} onBlur={validateColorToVariant} />
                        <Legend property={variantsValid[0].color.toString()}>
                          <div id="container-validate-variant-color-valid" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="color" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                        </Legend>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-5 mb-5">
                      <div className="fv-row w-100 flex-md-root">
                        <label className="form-label">{lang.labels.unitMeasure}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid bg-light" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {variantsValue[0].unit.id.length > 0 ? `${measureUnitSelected(lang, settingsForCompany.measure_units, variantsValue[0].unit.type, variantsValue[0].unit.id)}` : `${"Select..."}`}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  {/* <li className="select2-results__option select2-results__option--selectable">
                                    <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => executeShowModalSettingsCreateUnit(item.type, index, sub_index)}>
                                      <div className="d-flex align-items-center">
                                        <span className="svg-icon svg-icon-2 me-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                          </svg>
                                        </span>
                                        {lang.labels.addUnit}
                                      </div>
                                    </Dropdown.Item>
                                  </li> */}
                                  <li className={`select2-results__option select2-results__option--selectable ${unitsProductForCompany(settingsForCompany.measure_units).length > 0 && "d-none"}`}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text fs-7">{lang.labels.selectOption}</Dropdown.Item>
                                  </li>
                                  { unitsProductForCompany(settingsForCompany.measure_units).map (( (other_item, other_index) => { return (
                                    <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === variantsValue[0].unit.id && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === variantsValue[0].unit.id}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => handleChangeUnitToVariant(other_item)}>{other_item.name}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="fv-row w-100 flex-md-root">
                        <label className="form-label">{lang.labels.taxRate}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid bg-light" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder text-capitalize">
                                    {variantsValue[0].tax_rate.length > 0 ? `${variantsValue[0].tax_rate}` : `${"Select..."}`}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  {/* <li className="select2-results__option select2-results__option--selectable d-none">
                                    <Dropdown.Item bsPrefix="select2-results__option__text fs-7" onClick={() => executeShowModalSettingsCreateTax(index, sub_index)}>
                                      <div className="d-flex align-items-center">
                                        <span className="svg-icon svg-icon-2 me-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                          </svg>
                                        </span>
                                        {lang.labels.addTax}
                                      </div>
                                    </Dropdown.Item>
                                  </li> */}
                                  { settingsForCompany.tax_fees.map (( (other_item, other_index) => { return (
                                    <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item === variantsValue[0].tax_rate && "select2-results__option--selected"}`} role="option" aria-selected={other_item === variantsValue[0].tax_rate}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text fs-7 text-capitalize" onClick={() => handleChangeTaxRateToVariant(other_item)}>{other_item}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="mb-5">
                      <label className="form-label required">Price</label>
                      <input id="input-variant-price" className="form-control form-control-solid mb-2" type="text"  name="price" value={variantsValue[0].price} onChange={handleChangePriceToVariant} onKeyUp={validatePriceToVariant} onBlur={validatePriceToVariant} />
                      <Legend property={variantsValid[0].price.toString()}>
                        <div id="container-validate-variant-price-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="price" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                        </div>
                        <div id="container-validate-variant-price-required" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="price" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                        </div>
                      </Legend>
                    </div>
                    <div className="mb-10">
                      <div className="fv-row mb-5">
                        <div className="d-flex align-items-center mb-2">
                          <label className="form-label m-0 required">Discount type</label>
                          <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title="Select a discount type that will be applied to this product"><FaExclamationCircle /></i>
                        </div>
                        <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-5">
                          <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${variantsValue[0].discount.type === "none" && "active"}`}>
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" value="none" checked={variantsValue[0].discount.type === "none"} onChange={handleChangeDiscountTypeToVariant} />
                              </span>
                              <span className="ms-5 fs-4 fw-bold text-gray-800 d-block">No Discount</span>
                            </label>
                          </div>
                          <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${variantsValue[0].discount.type === "percentage" && "active"}`}>
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" value="percentage" checked={variantsValue[0].discount.type === "percentage"} onChange={handleChangeDiscountTypeToVariant} />
                              </span>
                              <span className="ms-5 fs-4 fw-bold text-gray-800 d-block">Percentage %</span>
                            </label>
                          </div>
                          <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 ${variantsValue[0].discount.type === "fixed" && "active"}`}>
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input className="form-check-input" type="radio" value="fixed" checked={variantsValue[0].discount.type === "fixed"}  onChange={handleChangeDiscountTypeToVariant} />
                              </span>
                              <span className="ms-5 fs-4 fw-bold text-gray-800 d-block">Fixed Price</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      { variantsValue[0].discount.type === "percentage" &&
                        <div className={`fv-row mb-8 ${variantsValue[0].discount.type !== "percentage" && "d-none"}`}>
                          <label className="form-label required">Set discount percentage</label>
                          <div className="d-flex flex-column text-center">
                            <div className="d-flex align-items-start justify-content-center">
                              <span className="fw-bolder fs-2x">{variantsValue[0].discount.value}</span>
                              <span className="fw-bold fs-4 mt-1 ms-2">%</span>
                            </div>
                            <Slider value={Number(variantsValue[0].discount.value)} defaultValue={Number(variantsValue[0].discount.value)} onChange={handleChangeSlideDiscountValueToVariant} onChangeComplete={handleChangeSlideDiscountValueToVariant} step={1} min={0} max={100} styles={{track: {height: 10, backgroundColor: "gray"}, rail: {height: 10, backgroundColor: "silver"}, handle: {height: 22, width: 22, opacity: 100, borderColor: "blue", backgroundColor: "black"}}} />
                          </div>
                        </div>
                      }
                      { variantsValue[0].discount.type === "fixed" &&
                        <div className={`fv-row mb-5 ${variantsValue[0].discount.type !== "fixed" && "d-none"}`}>
                          <label className="form-label required">Fixed discounted price</label>
                          <input id="input-variant-discount-value" className="form-control form-control-solid mb-2" type="text"  name="discount-value" value={variantsValue[0].discount.value} onChange={handleChangeInputDiscountValueToVariant} onKeyUp={validateDiscountValueToVariant} onBlur={validateDiscountValueToVariant} />
                          <Legend property={variantsValid[0].discount.value.toString()}>
                            <div id="container-validate-variant-discount-value-valid" className="fv-plugins-message-container invalid-feedback d-none">
                              <div data-field="discount-value" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                            </div>
                            <div id="container-validate-variant-discount-value-required" className="fv-plugins-message-container invalid-feedback d-none">
                              <div data-field="discount-value" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                            </div>
                          </Legend>
                        </div>
                      }
                      { (variantsValue[0].discount.type === "percentage" || variantsValue[0].discount.type === "fixed") &&
                        <div className="fv-row">
                          <label className="form-label required">Quantity minimum for discount</label>
                          <input id="input-variant-discount-quantity" className="form-control form-control-solid mb-2" type="text"  name="discount-quantity" value={variantsValue[0].discount.quantity} onChange={handleChangeInputDiscountQuantityToVariant} onKeyUp={validateDiscountQuantityToVariant} onBlur={validateDiscountQuantityToVariant} />
                          <Legend property={variantsValid[0].discount.quantity.toString()}>
                            <div id="container-validate-variant-discount-quantity-valid" className="fv-plugins-message-container invalid-feedback d-none">
                              <div data-field="discount-quantity" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                            </div>
                            <div id="container-validate-variant-discount-quantity-required" className="fv-plugins-message-container invalid-feedback d-none">
                              <div data-field="discount-quantity" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                            </div>
                          </Legend>
                        </div>
                      }
                    </div>
                    <div className="fv-row">
                      <div className="dropzone dz-clickable position-relative mb-2">
                        <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="photos" accept=".png, .jpg, .jpeg" multiple onChange={handleChangeFiles} />
                        { variantsValue[0].photos.length > 0 && variantsValid[0].photos.length > 0 && variantsValue[0].photos.length === variantsValid[0].photos.length
                          ?
                          <>
                            { variantsValue[0].photos.map (( (item, index) => { return (
                              <div key={index} className="dz-preview dz-image-preview dz-error">
                                <div className="dz-image">
                                  <img src={variantsValid[0].photos[index].file ? item.view : imgDefault} className="img-fluid" alt="photography" />
                                </div>
                                <div className="dz-details">
                                  <div className="dz-size">
                                    <span>{index + 1}</span>
                                  </div>
                                  <div className="dz-filename">
                                    <span>{lang.labels.image}</span>
                                  </div>
                                </div>
                                { !variantsValid[0].photos[index].file &&
                                  <div className="dz-error-message">
                                    <span>{variantsValid[0].photos[index].message}</span>
                                  </div>
                                }
                                { variantsValid[0].photos[index].file
                                  ? 
                                  <div className="dz-success-mark">
                                    <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                      <title>Success</title>
                                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" strokeOpacity="0.198794158" stroke="#747474" fillOpacity="0.816519475" fill="#FFFFFF"></path>
                                      </g>
                                    </svg>
                                  </div>
                                  :
                                  <div className="dz-error-mark">
                                    <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                      <title>Error</title>
                                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g stroke="#747474" strokeOpacity="0.198794158" fill="#FFFFFF" fillOpacity="0.816519475">
                                          <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </div>
                                }
                                <button className="dz-remove" onClick={() => executeRemoveFile(index)}>{lang.labels.remove}</button>
                              </div>
                            )}))}
                          </>
                          :
                          <div className="dz-message">
                            <span className="svg-icon svg-icon-primary svg-icon-3x">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z" fill="black"/>
                                <path d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z" fill="black"/>
                              </svg>
                            </span>
                            <div className="ms-5">
                              <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload</h3>
                              <span className="fs-7 fw-semibold text-gray-400">Upload up to 10 files</span>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="text-muted fs-7">Set the product media gallery.</div>
                      <Legend property={variantsValid[0].gallery.toString()}>
                        <div id="container-validate-variant-gallery-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="category" data-validator="max">Limit of 10 photos maximum</div>
                        </div>
                      </Legend>
                    </div>
                  </>
                }
              </div>
              </div>
              <div className="card card-flush d-lg-none">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Name in other languages</h2>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <label className="form-label">Enter in language you want</label>
                  { Object.keys(names.value).map (( (item, index) => { return (
                    <div key={index}>
                      <input id={"input-language-name-" + index} className="form-control my-2" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item.toUpperCase())} value={names.value[item as keyof typeof names.value]} onChange={(event) => handleChangeNames(item, event)} onKeyUp={() => validateNames(index, item)} onBlur={() => validateNames(index, item)} />
                      <Legend property={names.valid[item as keyof typeof names.valid].toString()}>
                        <div id={"container-validate-language-name-valid-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="language-name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                        </div>
                      </Legend>
                    </div>
                  )}))}
                  <div className="text-muted fs-7 mt-5">If you wish, you can assign the name of the product in languages that you think are necessary.</div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Link to="/app/inventory/product/list" className="btn btn-light text-uppercase me-5">Cancel</Link>
              <button className="btn btn-primary text-nowrap fw-bolder" type="button" data-kt-indicator={loadIndicator} onClick={submitProductCreate}>
                <span className="indicator-label text-uppercase">Create Product</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
              </button>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalCategoryCreate companyForUser={companyForUser} category={category} categories={categoriesForCompany} setCategory={setCategory} setCategories={setCategoriesForCompany}></AppModalCategoryCreate>
      <ModalProductVariantAdd settingsForCompany={settingsForCompany} variantValue={variantValueModal} variantValid={variantValidModal} variantsValue={variantsValue} setVariantValue={setVariantValueModal} setVariantValid={setVariantValidModal} variantsValid={variantsValid} setVariantsValue={setVariantsValue} setVariantsValid={setVariantsValid}></ModalProductVariantAdd>
      <ModalProductVariantEdit settingsForCompany={settingsForCompany} indexVariantEdit={indexVariantEdit} variantValue={variantValueModal} variantValid={variantValidModal} variantsValue={variantsValue} setIndexVariantEdit={setIndexVariantEdit} setVariantValue={setVariantValueModal} setVariantValid={setVariantValidModal} variantsValid={variantsValid} setVariantsValue={setVariantsValue} setVariantsValid={setVariantsValid}></ModalProductVariantEdit>
    </div>
  )
};

export default ProductCreatePage;
