import React, { Dispatch, SetStateAction, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { CategoryDA } from '../../services/category.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { Legend } from '../../styles/legend.style';
import { modalHide } from '../../tools/modal.tool';
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendInvalidEmptyIcon, legendValidIcon, legendValidRequired, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { languageDownloadValue } from '../../libraries/language.download.library';

interface AppModalCategoryUpdateProps {
  companyForUser: Company | undefined | null,
  category: Category | undefined | null,
  categoryValue: {name: string, description: string, names: {en: string, fr: string, it: string, de: string}},
  categoryValid: {name: boolean, description: boolean, names: {en: boolean, fr: boolean, it: boolean, de: boolean}},
  categories: Category[] | undefined | null,
  setCategory: Dispatch<SetStateAction<Category | undefined | null>>,
  setCategoryValue: Dispatch<SetStateAction<{name: string, description: string, names: {en: string, fr: string, it: string, de: string}}>>,
  setCategoryValid: Dispatch<SetStateAction<{name: boolean, description: boolean, names: {en: boolean, fr: boolean, it: boolean, de: boolean}}>>,
  setCategories: Dispatch<SetStateAction<Category[] | undefined | null>> | null
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryUpdate: React.FunctionComponent<AppModalCategoryUpdateProps> = ({companyForUser, category, categoryValue, categoryValid, categories, setCategory, setCategoryValue, setCategoryValid, setCategories}) => {
  const {lang} = StateLanguage()

  const expressions = {
    //eslint-disable-next-line
    name: /^[A-Za-zÀ-ÿ0-9\s]{1,100}$/,
    //eslint-disable-next-line
    description: /^[A-Za-zÀ-ÿ0-9\'\.\-\,\#\°?\s!@#$%^&*()+=[\]{};:'"<>|\\/~_/]{3,300}$/
  }

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitCategoryUpdate = async () => {
    setLoadIndicator('on')

    let languageNamesInvalid: number = 0
    Object.keys(categoryValid.names).forEach((item) => { if (!item) { languageNamesInvalid += 1 }})

    if (companyForUser && category && categoryValid.name && categoryValid.description && languageNamesInvalid === 0) {
      await CategoryDA.putCategory(companyForUser.id, category.id, categoryValue.name, categoryValue.description, categoryValue.names).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data

          if (categories && setCategories) {
            let tempCategories = categories.map((temp_item) => {
              if (temp_item.id === category.id) {
                temp_item = categoryResponse
              }
              return temp_item
            })

            setCategories(tempCategories)
          } else {
            setCategory(categoryResponse)
          }

          Swal.fire({
            title: 'Successfully updated category!',
            text: 'Updating company categories, returning to page',
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (categoryValue.name.length === 0) {
          legendInvalidIcon('modal-category-update-input-name', 'modal-category-update-container-validate-name-required')
        }
        if (categoryValue.description.length === 0) {
          legendInvalid('modal-category-update-container-validate-description-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCategoryValue({...categoryValue, name: event.target.value})
  }

  const handleChangeDescription = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setCategoryValue({...categoryValue, description: event.target.value})
  }

  const handleChangeNames = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCategoryValue(categoryValue => ({...categoryValue, names: {...categoryValue.names, [item]: event.target.value}}))
  }

  const validateName = () => {
    if (expressions) {
      if (expressions.name.test(categoryValue.name)) {
        setCategoryValid({...categoryValid, name: true})
        legendValidRequiredIcon('modal-category-update-input-name', 'modal-category-update-container-validate-name-valid', 'modal-category-update-container-validate-name-required')
      } else {
        setCategoryValid({...categoryValid, name: false})
        legendInvalidEmptyIcon(categoryValue.name, 'modal-category-update-input-name', 'modal-category-update-container-validate-name-valid', 'modal-category-update-container-validate-name-required')
      }
    }
  }

  const validateDescription = () => {
    if (expressions) {
      if (expressions.description.test(categoryValue.description)) {
        setCategoryValid({...categoryValid, description: true})
        legendValidRequired('modal-category-update-container-validate-description-valid', 'modal-category-update-container-validate-description-required')
      } else {
        setCategoryValid({...categoryValid, description: false})
        legendInvalidEmpty(categoryValue.description, 'modal-category-update-container-validate-description-valid', 'modal-category-update-container-validate-description-required')
      }
    }
  }

  const validateNames = (index: number, item: string) => {
    if (expressions && categoryValue.names[item as keyof typeof categoryValue.names].length > 0) {
      if (expressions.name.test(categoryValue.names[item as keyof typeof categoryValue.names])) {
        setCategoryValid(categoryValid => ({...categoryValid, names: {...categoryValid.names, [item]: true}}))
        legendValidIcon('modal-category-update-input-language-name-' + index, 'modal-category-update-container-validate-language-name-valid-' + index)
      } else {
        setCategoryValid(categoryValid => ({...categoryValid, names: {...categoryValid.names, [item]: false}}))
        legendInvalidIcon('modal-category-update-input-language-name-' + index, 'modal-category-update-container-validate-language-name-valid-' + index)
      }
    } else {
      setCategoryValid(categoryValid => ({...categoryValid, names: {...categoryValid.names, [item]: true}}))
      legendValidInvalidIconRestart('modal-category-update-input-language-name-' + index, 'modal-category-update-container-validate-language-name-valid-' + index)
    }
  }

  const executeHideModalCategoryUpdate = () => {
    modalHide('modal-category-update')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-category-update-input-name', 'modal-category-update-container-validate-name-valid')
    legendValidInvalidIconRestart('modal-category-update-input-name', 'modal-category-update-container-validate-name-required')
    legendValidInvalidRestart('modal-category-update-container-validate-description-valid')
    legendValidInvalidRestart('modal-category-update-container-validate-description-required')

    for (var indexName in Object.keys(categoryValid.names)) {
      legendValidInvalidIconRestart('modal-category-update-input-language-name-' + indexName, 'modal-category-update-container-validate-language-name-valid-' + indexName)
    }

    if (categories && setCategories) {
      setCategory(null)
    }

    setCategoryValue({name: '', description: '', names: {en: '', fr: '', it: '', de: ''}})
    setCategoryValid({name: false, description: false, names: {en: true, fr: true, it: true, de: true}})
  }

  return (
    <div id="modal-category-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Update Category</h2>
          </div>
          <div className="modal-body mx-5 mx-xl-10 py-0">
            <div className="form d-flex flex-column flex-lg-row">
              <div className="d-flex flex-column order-2 order-lg-1 w-100 w-lg-350px">
                <div className="card card-flush">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>Name in other languages</h2>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <label className="form-label">Enter or modify in language you want</label>
                    { Object.keys(categoryValue.names).map (( (item, index) => { return (
                      <div key={index}>
                        <input id={"modal-category-update-input-language-name-" + index} className="form-control my-2" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item.toUpperCase())} value={categoryValue.names[item as keyof typeof categoryValue.names]} onChange={(event) => handleChangeNames(item, event)} onKeyUp={() => validateNames(index, item)} onBlur={() => validateNames(index, item)} />
                        <Legend property={categoryValid.names[item as keyof typeof categoryValid.names].toString()}>
                          <div id={"modal-category-update-container-validate-language-name-valid-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="language-name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                        </Legend>
                      </div>
                    )}))}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-row-fluid order-1 order-lg-2 ">
                <div className="card card-flush">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>General</h2>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="mb-10 fv-row fv-plugins-icon-container">
                      <label className="required form-label">Category Name</label>
                        <input id="modal-category-update-input-name" className="form-control form-control-solid mb-2" type="text" name="name" value={categoryValue.name} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                        <Legend property={categoryValid.name.toString()}>
                          <div id="modal-category-update-container-validate-name-valid" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="name" data-validator="text">{lang.labels.theValueIsNotAValid}</div>
                          </div>
                          <div id="modal-category-update-container-validate-name-required" className="fv-plugins-message-container invalid-feedback d-none">
                            <div data-field="name" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                          </div>
                        </Legend>
                    </div>
                    <div>
                      <label className="required form-label">Description</label>
                      <TextareaAutosize className="form-control form-control-solid" minRows={3} maxRows={5} name="description" autoComplete="off" value={categoryValue.description} onChange={handleChangeDescription} onKeyUp={validateDescription} onBlur={validateDescription} />
                      <Legend property={categoryValid.description.toString()}>
                        <div id="modal-category-update-container-validate-description-valid" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="description" data-validator="text">The value is not a valid description</div>
                        </div>
                        <div id="modal-category-update-container-validate-description-required" className="fv-plugins-message-container invalid-feedback d-none">
                          <div data-field="description" data-validator="notEmpty">Description is required</div>
                        </div>
                      </Legend>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light me-3" type="reset" onClick={executeHideModalCategoryUpdate}>Cancel</button>
              <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryUpdate}>
                <span className="indicator-label">Update</span>
                <span className="indicator-progress">
                  Please wait... 
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryUpdate;
