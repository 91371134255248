import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { TreasuryAccount } from '../models/treasury.account.model';
import { Company } from '../models/company.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Token } from '../services/token.service';
import { TreasuryAccountHolder } from '../models/treasury.account.holder.model';

export class TreasuryAccountDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getTreasuryAccounts(id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/bank_accounts`
    let token = Token.getToken()

    let bankAccountsForCompany: any | null = {
      id_company: '',
      treasury_accounts: []
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { bankAccountsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (bankAccountsForCompany.id_company === id_company) {
      this.dataResponse = bankAccountsForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postTreasuryAccount(id_company: string, type: string, name: string, iban: string, bban: string, bic: string, membership: string, qr_iban: string, reference: string, is_reference: boolean, default_treasury_account: boolean, account_holder: TreasuryAccountHolder): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/bank_accounts`
    let token = Token.getToken()
    let bankAccountsForCompany: any | null = {
      id_company: '',
      treasury_accounts: []
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }
    let dataBankAccount = {
      type,
      name,
      iban: iban.trim(),
      bban,
      bic,
      membership,
      qr_iban,
      is_reference,
      reference,
      account_holder,
      default_value: default_treasury_account
    }

    await axios.post(url, dataBankAccount, token)
      .then(response => { bankAccountsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (bankAccountsForCompany.id_company === id_company) {

      this.dataResponse = bankAccountsForCompany.treasury_accounts

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putTreasuryAccount(id_company: string, id_treasury_account: string, name: string, iban: string, bban: string, bic: string, membership: string, qr_iban: string, reference: string, is_reference: boolean, account_holder: TreasuryAccountHolder): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/bank_accounts/${id_treasury_account}`
    let token = Token.getToken()
    let bankAccountsForCompany: any | null = {
      id_company: '',
      treasury_accounts: []
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }
    console.log({ account_holder })
    let dataBankAccount = {
      name,
      iban: iban.trim(),
      bban,
      bic,
      membership,
      qr_iban,
      is_reference,
      reference,
      account_holder
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataBankAccount, token)
      .then(response => { bankAccountsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (bankAccountsForCompany.id_company === id_company) {

      this.dataResponse = bankAccountsForCompany.treasury_accounts
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putTreasuryAccountDefault(id_company: string, id_treasury_account: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/bank_accounts/${id_treasury_account}/default`
    let token = Token.getToken()
    let bankAccountsForCompany: any | null = {
      id_company: '',
      treasury_accounts: []
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.put(url, undefined, token)
      .then(response => { bankAccountsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (bankAccountsForCompany.id_company === id_company) {

      this.dataResponse = bankAccountsForCompany.treasury_accounts

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deleteTreasuryAccount(id_company: string, id_treasury_account: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/bank_accounts/${id_treasury_account}`
    let token = Token.getToken()
    let bankAccountsForCompany: any | null = {
      id_company: '',
      treasury_accounts: []
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.delete(url, token)
      .then(response => { bankAccountsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (bankAccountsForCompany.id_company === id_company) {

      this.dataResponse = bankAccountsForCompany.treasury_accounts

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
