import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';

import { UseOutletContextAccount } from './Account';
import { StateLanguage } from '../../../languages/config/StateLanguage';

export interface AccountViewPageProps {};

const AccountViewPage: React.FunctionComponent<AccountViewPageProps> = props => {
  const {setRoute, userLoggedIn} = UseOutletContextAccount()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [collapse, setCollapse] = useState(true)

  const executeCollapse = () => {
    setCollapse(!collapse)
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.account, branch: lang.labels.viewUser}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="card">
      <div className="card-header cursor-pointer" role="button" onClick={executeCollapse}>
        <div className="card-title">
          <h4>{lang.labels.profileDetails}</h4>
        </div>
      </div>
      <Collapse in={collapse}>
        <div>
          { userLoggedIn
            ?
            <div className="card-body">
              <div className="row mb-5">
                <label className="col-lg-3 fw-bold text-muted">{lang.labels.firstName}</label>
                <div className="col-lg-9">
                  <span className="fw-bold fs-6 text-gray-800">{userLoggedIn.first_name}</span>
                </div>
              </div>
              <div className="row mb-5">
                <label className="col-lg-3 fw-bold text-muted">{lang.labels.lastName}</label>
                <div className="col-lg-9 fv-row">
                  <span className="fw-bold fs-6 text-gray-800">{userLoggedIn.last_name}</span>
                </div>
              </div>
              <div className="row mb-5">
                <label className="col-lg-3 fw-bold text-muted">{lang.labels.email}</label>
                <div className="col-lg-9 d-flex align-items-center">
                  <span className="fw-bold fs-6 text-gray-800 me-2">{userLoggedIn.email}</span>
                  { userLoggedIn.verified
                    ?
                    <span className="badge badge-success text-uppercase">{lang.labels.verified}</span>
                    :
                    <span className="badge badge-warning text-uppercase">{lang.labels.notVerified}</span>
                  }
                </div>
              </div>
              <div className="row mb-5">
                <label className="col-lg-3 fw-bold text-muted">{lang.labels.phoneNumber}</label>
                <div className="col-lg-9">
                  <span className="fw-bold fs-6 text-gray-800">{userLoggedIn.phone}</span>
                </div>
              </div>
              { !userLoggedIn.verified &&
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-5 mt-10">
                  <span className="svg-icon svg-icon-2tx svg-icon-warning me-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                      <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                      <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                    </svg>
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bold">
                      <h5 className="text-gray-800">{lang.labels.weNeedYourAttention}</h5>
                      <div className="text-justify text-gray-600">
                        {lang.labels.yourAccountIsNotVerified}
                        <label className="text-primary text-lowercase fw-bolder mx-1">{lang.labels.validateAccount}</label>
                        {lang.labels.button}
                        .
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            :
            <div className="w-100 h-200px">
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            </div>
          }
        </div>
      </Collapse>
    </div>
  )
};

export default AccountViewPage;
