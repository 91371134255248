import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { SettingsDA } from '../../services/settings.services';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Settings } from '../../models/settings.model';
import { Legend } from '../../styles/legend.style';
import { legendValidInvalidIconRestart, legendInvalidIcon, legendValidRequiredIcon } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

interface AppModalSettingTaxCreateProps {
  companyForUser: Company | undefined | null,
  newTax: {index: number, subIndex: number} | null,
  requirements: {list: boolean, value: any[], valid: any[]},
  setSettingsForCompany: Dispatch<SetStateAction<Settings | undefined | null>>,
  setNewTax: Dispatch<SetStateAction<{index: number, subIndex: number} | null>>,
  setRequirements: Dispatch<SetStateAction<{list: boolean, value: any[], valid: any[]}>>
};

let errorResponse: Error, settingsResponse: Settings;

const AppModalSettingCreateTax: React.FunctionComponent<AppModalSettingTaxCreateProps> = ({companyForUser, newTax, requirements, setSettingsForCompany, setNewTax, setRequirements}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [tax, setTax] = useState({value: '', valid: false})

  const submitSettingsParameterCreate = async () => {
    setLoadIndicator('on')

      if (companyForUser && newTax && tax.valid) {
        await SettingsDA.putSettingsParameters(companyForUser.id, tax.value, null, null).then( (response) => {
          if (response.status === 200) {
            settingsResponse = response.data

            let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
              if (newTax.index === temp_index) {
                temp_item.contents[newTax.subIndex].tax_rate = settingsResponse.tax_fees.find((item: string) => (item === tax.value)) as string
              }
              return temp_item
            })
            let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
              if (newTax.index === temp_index) {
                temp_item.contents[newTax.subIndex].tax_rate = true
              }
              return temp_item
            })

            setSettingsForCompany(settingsResponse)
            setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
            legendValidRequiredIcon('input-requirement-content-tax-' + newTax.index + newTax.subIndex, 'container-validate-requirement-content-tax-valid-' + newTax.index + newTax.subIndex, 'container-validate-requirement-content-tax-required-' + newTax.index + newTax.subIndex)

            Swal.fire({
              title: lang.labels.successfullyCreatedCompanyParameters,
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalSettingsUnitCreate()
            })
          } else {
            errorResponse = response.data

            Swal.fire({
              title: getError(errorResponse.code, lang.code),
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        }).catch( (error) => {
          console.error(error)
          window.location.href = '/error'
        })
      } else {
        Swal.fire({
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
          icon: 'error',
          showConfirmButton: false,
          timer: 1800
        } as SweetAlertOptions).then( () => {
          if (tax.value.length === 0) {
            legendInvalidIcon('modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-required')
          }
          setLoadIndicator('off')
        })
      }
  }

  const handleChangeTax = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTax({...tax, value: event.target.value})
  }

  const validateTax = () => {
    evaluateLegendValidateRequiredIcon(expressions.tax, tax, setTax, 'modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-valid', 'modal-settings-create-tax-container-validate-tax-required')
  }

  const executeHideModalSettingsUnitCreate = () => {
    modalHide('modal-settings-create-tax')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-valid')
    legendValidInvalidIconRestart('modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-required')

    setNewTax(null)
    setTax({value: '', valid: false})
  }

  return (
    <div id="modal-settings-create-tax" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addTaxRateParameter}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="row">
                <label className="col-lg-3 col-form-label required">{lang.labels.taxValue}</label>
                <div className="col-lg-9">
                  <input id="modal-settings-create-tax-input-tax" className="form-control form-control-solid pe-13" type="text" name="tax" value={tax.value} onChange={handleChangeTax} onKeyUp={validateTax} onBlur={validateTax} />
                  <Legend property={tax.valid.toString()}>
                    <div id="modal-settings-create-tax-container-validate-tax-valid" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="tax" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                    </div>
                    <div id="modal-settings-create-tax-container-validate-tax-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="tax" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                    </div>
                  </Legend>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalSettingsUnitCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitSettingsParameterCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalSettingCreateTax;
