import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { UseOutletContextPurchase } from '../Purchase';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Settings } from '../../../../models/settings.model';
import { Currency } from '../../../../models/currency.model';
import { Unit } from '../../../../models/unit.model';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { Legend } from '../../../../styles/legend.style';
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendValid, legendValidIcon, legendValidRequired } from '../../../../tools/legend.data.entry.tool';
import { evaluateLegendValidateEmptyObjectList, evaluateLegendValidateRequiredObjectMap } from '../../../../scripts/validate.legend.script';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { measureUnitSelected } from '../../../../libraries/selected.item.list.library';
import { requirementTypeValue } from '../../../../libraries/requirement.type.library';

import AppModalSettingCreateTax from '../../../../components/modal/ModalSettingsCreateTax';
import AppModalSettingCreateUnit from '../../../../components/modal/ModalSettingsCreateUnit';

interface PurchaseFormRequirementsPageProps {
  currency: Currency | undefined | null,
  requirements: {list: boolean, value: {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: Unit}[]}[], valid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean}[]}[]},
  setRequirements: Dispatch<SetStateAction<{list: boolean, value: {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: Unit}[]}[], valid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean}[]}[]}>>
};

const PurchaseFormRequirements: React.FunctionComponent<PurchaseFormRequirementsPageProps> = ({currency, requirements, setRequirements}) => {
  const {companyForUser, settingsForCompany, setSettingsForCompany} = UseOutletContextPurchase()
  const {lang} = StateLanguage()

  const [newTax, setNewTax] = useState<{index: number, subIndex: number} | null>(null)
  const [newUnit, setNewUnit] = useState<{type: string, index: number, subIndex: number} | null>(null)

  const handleChangeDescriptionToRequirement = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.description = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeDetailContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].detail = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeUnitContentToRequirement = (index: number, sub_index: number, item: Unit) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].unit = item
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].unit = true
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
    legendValid('container-validate-requirement-content-unit-required-' + index + sub_index)
  }

  const handleChangeQuantityContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].quantity = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangePriceContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].price_unit = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeTaxIncludeContentToRequirement = (index: number, sub_index: number, item: boolean) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].tax_included = !item
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeTaxRateContentToRequirement = (index: number, sub_index: number, item: string) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].tax_rate = item
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].tax_rate = true
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
    legendValid('container-validate-requirement-content-tax-required-' + index + sub_index)
  }

  const validateDescriptionToRequirement = (index: number) => {
    evaluateLegendValidateEmptyObjectList(expressions.text, index, 'description', requirements, setRequirements, 'container-validate-requirement-description-valid-' + index)
  }

  const validateDetailContentToRequirement = (index: number, sub_index: number) => {
    evaluateLegendValidateRequiredObjectMap(expressions.text, index, sub_index, 'detail', requirements, setRequirements, 'container-validate-requirement-content-detail-valid-' + index + sub_index, 'container-validate-requirement-content-detail-required-' + index + sub_index)
  }

  const validateQuantityContentToRequirement = (index: number, sub_index: number) => {
    evaluateLegendValidateRequiredObjectMap(expressions.quantity, index, sub_index, 'quantity', requirements, setRequirements, 'container-validate-requirement-content-quantity-valid-' + index + sub_index, 'container-validate-requirement-content-quantity-required-' + index + sub_index)
  }

  const validatePriceContentToRequirement = (index: number, sub_index: number) => {
    evaluateLegendValidateRequiredObjectMap(expressions.currency, index, sub_index, 'price_unit', requirements, setRequirements, 'container-validate-requirement-content-price-valid-' + index + sub_index, 'container-validate-requirement-content-price-required-' + index + sub_index)
  }

  const executeAddRequirement = (item: string) => {
    if (allowAddRequirement()) {
      let newRequirimentValue: {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: Unit}[]} = {
        type: item,
        description: '',
        contents: [
          {
            detail: '',
            quantity: '',
            price_unit: '',
            tax_rate: '',
            tax_included: false,
            unit: {
              id: '',
              type: '',
              name: '',
              symbol: ''
            }
          }
        ]
      }
      let newRequirementValid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean}[]} = {
        type: true,
        description: true,
        content: true,
        contents: [
          {
            detail: false,
            quantity: false,
            price_unit: false,
            tax_rate: false,
            unit: false
          }
        ]
      }

      setRequirements({list: true, value: [...requirements.value, newRequirimentValue], valid: [...requirements.valid, newRequirementValid]})

      setTimeout( () => {
        let scroll = document.getElementById('container-requirement-' + requirements.value.length)
        scroll?.scrollIntoView({behavior: 'smooth', block: 'center'})
      }, 100 )

      uploadTooltip()
    }

    if (requirements.value.length === 0 && requirements.valid.length === 0) {
      legendValid('container-validate-requirements-required')
    }
  }

  const executeRemoveRequirement = (index: number) => {
    let tempRequirementsValue = requirements.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempRequirementsValid = requirements.valid.filter((_temp_item, temp_index) => (temp_index !== index))
    setRequirements({list: (tempRequirementsValue.length > 0 && tempRequirementsValid.length > 0), value: tempRequirementsValue, valid: tempRequirementsValid})

    if (tempRequirementsValue.length === 0 && tempRequirementsValid.length === 0) {
      legendInvalid('container-validate-requirements-required')
    }

    restartFormRemoveRequirement(tempRequirementsValue)
  }

  const executeAddContentToRequirement = (index: number) => {
    if (allowAddContentToRequirement(index)) {
      let newContentValue: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: Unit} = {
        detail: '',
        quantity: '',
        price_unit: '',
        tax_rate: '',
        tax_included: false,
        unit: {
          id: '',
          type: '',
          name: '',
          symbol: ''
        }
      }
      let newContentValid: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean} = {
        detail: false,
        quantity: false,
        price_unit: false,
        tax_rate: false,
        unit: false
      }
      let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
        if (index === temp_index) {
          temp_item.contents.push(newContentValue)
        }
        return temp_item
      })
      let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          temp_item.contents.push(newContentValid)
          temp_item.content = true
        }
        return temp_item
      })

      setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})

      if (tempRequirementsValue[index].contents.length > 0 && tempRequirementsValid[index].contents.length > 0 && tempRequirementsValid[index].content) {
        legendValid('container-validate-requirement-contents-required-' + index)
      }

      uploadTooltip()
    }
  }

  const executeRemoveContentToRequirement = (index: number, sub_index: number) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents = temp_item.contents.filter((_temp_sub_item, temp_sub_index) => (temp_sub_index !== sub_index))
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents = temp_item.contents.filter((_temp_sub_item, temp_sub_index) => (temp_sub_index !== sub_index))
        if (temp_item.contents.length === 0) {
          temp_item.content = false
        }
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})

    if (tempRequirementsValue[index].contents.length === 0 && tempRequirementsValid[index].contents.length === 0 && !tempRequirementsValid[index].content) {
      legendInvalid('container-validate-requirement-contents-required-' + index)
    }

    restartFormRemoveContentToRequirement(index, tempRequirementsValue)
  }

  const executeShowModalSettingsCreateTax = (index: number, sub_index: number) => {
    setNewTax({index: index, subIndex: sub_index})
    modalShow('modal-settings-create-tax')
  }

  const executeShowModalSettingsCreateUnit = (item: string, index: number, sub_index: number) => {
    setNewUnit({type: item, index: index, subIndex: sub_index})
    modalShow('modal-settings-create-unit')
  }

  function restartFormRemoveRequirement(items: any[]) {
    for (let i = 0; i < items.length; i++) {
      if (expressions && items[i].description.length > 0) {
        if (expressions.text.test(items[i].description)) {
          legendValidIcon('input-requirement-description-' + i, 'container-validate-requirement-description-valid-' + i)
        } else {
          legendInvalidIcon('input-requirement-description-' + i, 'container-validate-requirement-description-valid-' + i)
        }
      }
      if (items[i].contents.length === 0) {
        legendInvalid('container-validate-requirement-contents-required-' + i)
      } else {
        legendValid('container-validate-requirement-description-valid-' + i)
        restartFormRemoveContentToRequirement(i, items)
      }
    }
  }

  function restartFormRemoveContentToRequirement(index: number, items: any[]) {
    for (let i = 0; i < items[index].contents.length; i++) {
      if (expressions) {
        if (expressions.text.test(items[index].contents[i].detail)) {
          legendValidRequired('container-validate-requirement-content-detail-valid-' + index + i, 'container-validate-requirement-content-detail-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[i].detail, 'container-validate-requirement-content-detail-valid-' + index + i, 'container-validate-requirement-content-detail-required-' + index + i)
        }
        if (expressions.quantity.test(items[index].contents[i].quantity)) {
          legendValidRequired('container-validate-requirement-content-quantity-valid-' + index + i, 'container-validate-requirement-content-quantity-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[i].quantity, 'container-validate-requirement-content-quantity-valid-' + index + i, 'container-validate-requirement-content-quantity-required-' + index + i)
        }
        if (expressions.currency.test(items[index].contents[i].price_unit)) {
          legendValidRequired('container-validate-requirement-content-price-valid-' + index + i, 'container-validate-requirement-content-price-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[i].price_unit, 'container-validate-requirement-content-price-valid-' + index + i, 'container-validate-requirement-content-price-required-' + index + i)
        }
      }
      if (items[index].contents[i].unit.id.length > 0) {
        legendValid('container-validate-requirement-content-unit-required-' + index + i)
      } else {
        legendInvalid('container-validate-requirement-content-unit-required-' + index + i)
      }
      if (items[index].contents[i].tax_rate.length > 0) {
        legendValid('container-validate-requirement-content-tax-required-' + index + i)
      } else {
        legendInvalid('container-validate-requirement-content-tax-required-' + index + i)
      }
    }
  }

  function allowAddRequirement(): boolean {
    let allow: boolean = true

    for (let i = 0; i < requirements.valid.length; i++) {
      if (!requirements.valid[i].type || !requirements.valid[i].description || !requirements.valid[i].content) {
        if (!requirements.valid[i].content) {
          legendInvalid('container-validate-requirement-contents-required-' + i)
        }
        allow = false
      } else {
        allow = allowAddContentToRequirement(i)
      }
    }

    return allow
  }

  function allowAddContentToRequirement(index: number): boolean {
    let allow: boolean = true

    if (requirements.valid[index].contents.length > 0) {
      for (let i = 0; i < requirements.valid[index].contents.length; i++) {
        if (!requirements.valid[index].contents[i].detail || !requirements.valid[index].contents[i].quantity || !requirements.valid[index].contents[i].price_unit || !requirements.valid[index].contents[i].tax_rate || !requirements.valid[index].contents[i].unit) {
          if (!requirements.valid[index].contents[i].detail) {
            legendInvalidEmpty(requirements.value[index].contents[i].detail, 'container-validate-requirement-content-detail-valid-' + index + i, 'container-validate-requirement-content-detail-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].unit) {
            legendInvalid('container-validate-requirement-content-unit-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].quantity) {
            legendInvalidEmpty(requirements.value[index].contents[i].quantity, 'container-validate-requirement-content-quantity-valid-' + index + i, 'container-validate-requirement-content-quantity-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].price_unit) {
            legendInvalidEmpty(requirements.value[index].contents[i].price_unit, 'container-validate-requirement-content-price-valid-' + index + i, 'container-validate-requirement-content-price-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].tax_rate) {
            legendInvalid('container-validate-requirement-content-tax-required-' + index + i)
          }
          allow = false
        }
      }
    }

    return allow
  }

  function serviceUnits(settings: Settings): Unit[] {
    return settings.measure_units.filter((item: Unit) => (item.type === 'service'))
  }

  function productUnits(settings: Settings): Unit[] {
    return settings.measure_units.filter((item: Unit) => (item.type === 'product'))
  }

  function calculatorAmount(): {sub_total: number, tax_total: number, total: number, requirement_prices: number[], requirement_content_prices: number[][]} {
    let requirementContentPrices: number[][] = []
    let requirementPrices: number[] = []
    let subTotal: number = 0
    let taxTotal: number = 0
    let total: number = 0

    for (let requirement of requirements.value) {
      let requirementContentPrice: number[] = []
      let requirementPrice: number = 0

      for (let content of requirement.contents) {
        let quantity: number = Number(content.quantity)
        let price: number = (content.tax_included && Number(content.tax_rate)) ? (Number(content.price_unit) / (1 + (Number(content.tax_rate) / 100))) : Number(content.price_unit)
        let tax: number =  (Number(content.tax_rate) || Number(content.tax_rate) === 0) ? (content.tax_included) ? (Number(content.price_unit) - price) : (price * (Number(content.tax_rate) / 100)) : 0

        requirementContentPrice.push((quantity * (price + tax)))
        requirementPrice += (quantity * (price + tax))
        subTotal += (quantity * price)
        taxTotal += (quantity * tax)
        total += (quantity * (price + tax))
      }

      requirementContentPrices.push(requirementContentPrice)
      requirementPrices.push(requirementPrice)
    }

    return {sub_total: subTotal, tax_total: taxTotal, total: total, requirement_prices: requirementPrices, requirement_content_prices: requirementContentPrices}
  }

  useEffect( () => {
    if (settingsForCompany) {
      uploadTooltip()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsForCompany])

  return (
    <>
      { settingsForCompany && currency &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h4>{lang.labels.requirements}</h4>
            </div>
            <div className="card-toolbar">
              <button className="btn btn-sm btn-light-primary mb-1 me-3" type="button" onClick={() => executeAddRequirement("service")}>
                <div className="d-flex align-items-center">
                  <span className="svg-icon svg-icon-3 ms-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                    </svg>
                  </span>
                  {lang.labels.addService}
                </div>
              </button>
              <button className="btn btn-sm btn-light-primary mb-1" type="button" onClick={() => executeAddRequirement("product")}>
                <div className="d-flex align-items-center">
                  <span className="svg-icon svg-icon-3 ms-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                    </svg>
                  </span>
                  {lang.labels.addProduct}
                </div>
              </button>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle g-1 mb-1">
                    <thead className="border-bottom">
                      <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                        <th className="min-w-225px text-nowrap">{lang.labels.detail}</th>
                        <th className="w-100px text-center text-nowrap">{lang.labels.unitMeasure}</th>
                        <th className="w-125px text-center text-nowrap">{lang.labels.quantity}</th>
                        <th className="w-150px text-center text-nowrap">{lang.labels.unitPrice} ({currency.symbol})</th>
                        <th className="w-100px text-center text-nowrap">{lang.labels.taxRate} (%)</th>
                        <th className="w-100px"></th>
                      </tr>
                    </thead>
                    { requirements.value.length > 0 && requirements.valid.length > 0
                      ?
                      <>
                        { requirements.value.map (( (item, index) => { return (
                          <tbody key={index} id={"container-requirement-" + index}>
                            <tr>
                              <td colSpan={6}>
                                <div className="d-flex align-items-start">
                                  <div className="d-flex align-items-center text-gray-700 text-uppercase fw-bold fs-6 mt-3 me-5">
                                    <span className="svg-icon svg-icon-2 me-1">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="black"/>
                                        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                                      </svg>
                                    </span>
                                    {requirementTypeValue(lang, item.type)}
                                  </div>
                                  <div className="w-100">
                                    <input className="form-control form-control-solid px-3" type="text" name="description" autoComplete="off" placeholder={lang.labels.description} value={item.description} onChange={(event) => handleChangeDescriptionToRequirement(index, event)} onKeyUp={() => validateDescriptionToRequirement(index)} onBlur={() => validateDescriptionToRequirement(index)} />
                                    <Legend property={requirements.valid[index].description.toString()}>
                                      <div id={"container-validate-requirement-description-valid-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                                        <div data-field="description" data-validator="regexp">{lang.labels.invalid}</div>
                                      </div>
                                    </Legend>
                                  </div>
                                  <button className="btn btn-icon btn-flex btn-light-primary p-5 ms-2" type="button" onClick={() => executeAddContentToRequirement(index)}>
                                    <span className="svg-icon svg-icon-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.addContent} onClick={removeTooltip}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                  </button>
                                  <button className="btn btn-icon btn-flex btn-light-primary p-3 ms-2" type="button" onClick={() => executeRemoveRequirement(index)}>
                                    <span className="svg-icon svg-icon-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.removeRequirement} onClick={removeTooltip}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            { item.contents.map (( (sub_item, sub_index) => { return (
                              <tr key={sub_index}>
                                <td valign="top">
                                  <textarea className="form-control form-control-solid px-2" name="detail" autoComplete="off" rows={1} placeholder={lang.labels.content} value={sub_item.detail} onChange={(event) => handleChangeDetailContentToRequirement(index, sub_index, event)} onKeyUp={() => validateDetailContentToRequirement(index, sub_index)} onBlur={() => validateDetailContentToRequirement(index, sub_index)} />
                                  <Legend property={requirements.valid[index].contents[sub_index].detail.toString()}>
                                    <div id={"container-validate-requirement-content-detail-valid-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="detail" data-validator="regexp">{lang.labels.invalid}</div>
                                    </div>
                                    <div id={"container-validate-requirement-content-detail-required-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="detail" data-validator="notEmpty">{lang.labels.required}</div>
                                    </div>
                                  </Legend>
                                </td>
                                <td valign="top">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                      <span className="selection">
                                        <span className="select2-selection select2-selection--single form-select form-select-solid bg-light px-2" style={{backgroundImage:(requirements.valid[index].contents[sub_index].unit) ? "none" : "", height: "42.55px"}} aria-disabled="false">
                                          <span className="select2-selection__rendered" role="textbox">
                                            <span className="select2-selection__placeholder">
                                              {requirements.valid[index].contents[sub_index].unit && `${measureUnitSelected(lang, settingsForCompany.measure_units, item.type, sub_item.unit.id)}`}
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                      <span className="select2-dropdown select2-dropdown--below">
                                        <span className="select2-results">
                                          <ul className="select2-results__options" role="listbox">
                                            { requirements.value[index].type === "service" &&
                                              <>
                                                <li className="select2-results__option select2-results__option--selectable">
                                                  <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => executeShowModalSettingsCreateUnit(item.type, index, sub_index)}>
                                                    <div className="d-flex align-items-center">
                                                      <span className="svg-icon svg-icon-3 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                                        </svg>
                                                      </span>
                                                      {lang.labels.addUnit}
                                                    </div>
                                                  </Dropdown.Item>
                                                </li>
                                                <li className={`select2-results__option select2-results__option--selectable ${serviceUnits(settingsForCompany).length > 0 && "d-none"}`}>
                                                  <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2">{lang.labels.selectOption}</Dropdown.Item>
                                                </li>
                                                { serviceUnits(settingsForCompany).map (( (other_item, other_index) => { return (
                                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === sub_item.unit.id && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === sub_item.unit.id}>
                                                    <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => handleChangeUnitContentToRequirement(index, sub_index, other_item)}>{other_item.name}</Dropdown.Item>
                                                  </li>
                                                )}))}
                                              </>
                                            }
                                            { requirements.value[index].type === "product" &&
                                              <>
                                                <li className="select2-results__option select2-results__option--selectable">
                                                  <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => executeShowModalSettingsCreateUnit(item.type, index, sub_index)}>
                                                    <div className="d-flex align-items-center">
                                                      <span className="svg-icon svg-icon-3 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                                        </svg>
                                                      </span>
                                                      {lang.labels.addUnit}
                                                    </div>
                                                  </Dropdown.Item>
                                                </li>
                                                <li className={`select2-results__option select2-results__option--selectable ${productUnits(settingsForCompany).length > 0 && "d-none"}`}>
                                                  <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2">{lang.labels.selectOption}</Dropdown.Item>
                                                </li>
                                                { productUnits(settingsForCompany).map (( (other_item, other_index) => { return (
                                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === sub_item.unit.id && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === sub_item.unit.id}>
                                                    <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => handleChangeUnitContentToRequirement(index, sub_index, other_item)}>{other_item.name}</Dropdown.Item>
                                                  </li>
                                                )}))}
                                              </>
                                            }
                                          </ul>
                                        </span>
                                      </span>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <Legend property={requirements.valid[index].contents[sub_index].unit.toString()}>
                                    <div id={"container-validate-requirement-content-unit-required-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="unit" data-validator="notEmpty">{lang.labels.required}</div>
                                    </div>
                                  </Legend>
                                </td>
                                <td valign="top">
                                  <input className="form-control form-control-solid px-2" type="text" name="quantity" autoComplete="off" value={sub_item.quantity} onChange={(event) => handleChangeQuantityContentToRequirement(index, sub_index, event)} onKeyUp={() => validateQuantityContentToRequirement(index, sub_index)} onBlur={() => validateQuantityContentToRequirement(index, sub_index)} />
                                  <Legend property={requirements.valid[index].contents[sub_index].quantity.toString()}>
                                    <div id={"container-validate-requirement-content-quantity-valid-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="quantity" data-validator="regexp">{lang.labels.invalid}</div>
                                    </div>
                                    <div id={"container-validate-requirement-content-quantity-required-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="quantity" data-validator="notEmpty">{lang.labels.required}</div>
                                    </div>
                                  </Legend>
                                </td>
                                <td valign="top">
                                  <div className="position-relative">
                                    <input className="form-control form-control-solid ps-2 pe-20" type="text" name="price" autoComplete="off" value={sub_item.price_unit} onChange={(event) => handleChangePriceContentToRequirement(index, sub_index, event)} onKeyUp={() => validatePriceContentToRequirement(index, sub_index)} onBlur={() => validatePriceContentToRequirement(index, sub_index)} />
                                    <button className="btn btn-bg-secondary btn-flex flex-center position-absolute translate-middle-y top-50 end-0 h-25px w-55px me-2" onClick={() => handleChangeTaxIncludeContentToRequirement(index, sub_index, sub_item.tax_included)}>
                                      <span className="btn-label text-primary text-uppercase fw-bolder fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" data-bs-original-title={sub_item.tax_included ? lang.labels.thisOptionIncludeTaxPrice : lang.labels.thisOptionNotIncludeTaxPrice} onClick={removeTooltip}>
                                        {sub_item.tax_included ? lang.labels.net : lang.labels.gross}
                                      </span>
                                    </button>
                                  </div>
                                  <Legend property={requirements.valid[index].contents[sub_index].price_unit.toString()}>
                                    <div id={"container-validate-requirement-content-price-valid-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="price" data-validator="regexp">{lang.labels.invalid}</div>
                                    </div>
                                    <div id={"container-validate-requirement-content-price-required-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="price" data-validator="notEmpty">{lang.labels.required}</div>
                                    </div>
                                  </Legend>
                                </td>
                                <td valign="top">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                      <span className="selection">
                                        <span className="select2-selection select2-selection--single form-select form-select-solid bg-light px-2" style={{backgroundImage:(requirements.valid[index].contents[sub_index].tax_rate) ? "none" : "", height: "42.55px"}} aria-disabled="false">
                                          <span className="select2-selection__rendered" role="textbox">
                                            <span className="select2-selection__placeholder text-capitalize">
                                              {requirements.valid[index].contents[sub_index].tax_rate && `${sub_item.tax_rate}`}
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                      <span className="select2-dropdown select2-dropdown--below">
                                        <span className="select2-results">
                                          <ul className="select2-results__options" role="listbox">
                                            <li className="select2-results__option select2-results__option--selectable d-none">
                                              <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => executeShowModalSettingsCreateTax(index, sub_index)}>
                                                <div className="d-flex align-items-center">
                                                  <span className="svg-icon svg-icon-3 me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                  </span>
                                                  {lang.labels.addTax}
                                                </div>
                                              </Dropdown.Item>
                                            </li>
                                            { settingsForCompany.tax_fees.map (( (other_item, other_index) => { return (
                                              <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item === sub_item.tax_rate && "select2-results__option--selected"}`} role="option" aria-selected={other_item === sub_item.tax_rate}>
                                                <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2 text-capitalize" onClick={() => handleChangeTaxRateContentToRequirement(index, sub_index, other_item)}>{other_item}</Dropdown.Item>
                                              </li>
                                            )}))}
                                          </ul>
                                        </span>
                                      </span>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <Legend property={requirements.valid[index].contents[sub_index].tax_rate.toString()}>
                                    <div id={"container-validate-requirement-content-tax-required-" + index + sub_index} className="fv-plugins-message-container invalid-feedback d-none">
                                      <div data-field="tax" data-validator="notEmpty">{lang.labels.required}</div>
                                    </div>
                                  </Legend>
                                </td>
                                <td valign="top">
                                  <div className="d-flex justify-content-end">
                                    <label className="badge badge-lg badge-light-primary d-flex align-items-center justify-content-end text-nowrap text-gray-800 w-100 ps-0">
                                      {currency.symbol} {moneyFormat(calculatorAmount().requirement_content_prices[index][sub_index])}
                                    </label>
                                    <button className="btn btn-icon btn-flex btn-primary btn-light-primary ms-2" type="button" onClick={() => executeRemoveContentToRequirement(index, sub_index)}>
                                      <span className="svg-icon svg-icon-3 p-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.removeContent} onClick={removeTooltip}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                          <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                          <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )}))}
                            <tr>
                              <td colSpan={6}>
                                <Legend property={requirements.valid[index].content.toString()}>
                                  <div id={"container-validate-requirement-contents-required-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                                    <div data-field="contents" data-validator="notEmpty">{lang.labels.contentsToRequirementIsRequired}</div>
                                  </div>
                                </Legend>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={6}>
                                <span className="d-flex justify-content-end">
                                  <div className="text-uppercase text-gray-700 fw-bold fs-8">{lang.labels.priceTotal}:</div>
                                  <div className="text-end fw-bolder ms-10">{currency.symbol} {item.contents.length > 0 ? moneyFormat(calculatorAmount().requirement_prices[index]) : moneyFormat(0)}</div>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={6}></td>
                            </tr>
                          </tbody>
                        )}))}
                      </>
                      :
                      <tbody>
                        <tr>
                          <td valign="top" colSpan={6}>
                            <div className="form-control form-control-sm form-control-solid text-center text-uppercase">{lang.labels.noRecords}</div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    <tfoot>
                      <tr>
                        <td colSpan={6}>
                          <Legend property={requirements.list.toString()}>
                            <div id="container-validate-requirements-required" className="fv-plugins-message-container invalid-feedback d-none">
                              <div data-field="requirements" data-validator="notEmpty">{lang.labels.requirementsIsRequired}</div>
                            </div>
                          </Legend>
                        </td>
                      </tr>
                      <tr className="border-top">
                        <td colSpan={6}>
                          <div className="d-flex justify-content-end">
                            <div className="mw-500px">
                              <div className="border-bottom mt-3">
                                <div className="d-flex align-items-center justify-content-end">
                                  <span className="text-dark text-uppercase fw-bolder">{lang.labels.calculator}</span>
                                  <span className="svg-icon svg-icon-1 ms-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.calculatedValuesAreReferential}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="black"/>
                                      <path d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z" fill="black"/>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.subTotal}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().sub_total)}</div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.taxTotal}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().tax_total)}</div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.total}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().total)}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
            </div>
            
          </div>
        </div>
      }
      <AppModalSettingCreateTax companyForUser={companyForUser} newTax={newTax} requirements={requirements} setSettingsForCompany={setSettingsForCompany} setNewTax={setNewTax} setRequirements={setRequirements}></AppModalSettingCreateTax>
      <AppModalSettingCreateUnit companyForUser={companyForUser} newUnit={newUnit} requirements={requirements} setSettingsForCompany={setSettingsForCompany} setNewUnit={setNewUnit} setRequirements={setRequirements}></AppModalSettingCreateUnit>
    </>
  )
};

export default PurchaseFormRequirements;
