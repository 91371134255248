import React, { useState } from "react";
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from "sweetalert2";
import autoTable from 'jspdf-autotable';

import { jsPDFWithSvg } from "../../jsPDFWithSvg";
import { StateLanguage } from '../../languages/config/StateLanguage';
import { Company } from "../../models/company.model";
import { Settings } from "../../models/settings.model";
import { Order } from "../../models/order.model";
import { Legend } from "../../styles/legend.style";
import { modalHide, modalShow } from "../../tools/modal.tool";
import { legendInvalid, legendValidInvalidRestart, legendValid } from "../../tools/legend.data.entry.tool";

interface AppButtonOrderExportProps {
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | undefined | null,
  order: Order
};

const AppButtonOrderExport: React.FunctionComponent<AppButtonOrderExportProps> = ({companyForUser, settingsForCompany, order}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [typeToExport, setTypeToExport] = useState({value: '', valid: false})

  const submitOrderExport = async () => {
    if (companyForUser && settingsForCompany && typeToExport.valid) {
      setLoadIndicator('on')

      setTimeout( () => {
        switch (typeToExport.value) {
          case 'file':
            getDataUri(companyForUser.logo, function(dataUri: any) {
              let doc: jsPDFWithSvg = new jsPDFWithSvg('portrait', 'mm', "A4") as jsPDFWithSvg

              if (companyForUser && order.company.id === companyForUser.id) {
                loadScript(settingsForCompany.template_current.order, false).then( (ev:any) => {
                  if (ev.status) {
                    // @ts-ignore
                    generatePDF(doc, autoTable, companyForUser, dataUri, order, settingsForCompany.colors)
                  }
                }).catch( error => {
                  console.error(error)
                })
              }
            })
            break;
          case  'note':
            getDataUri(companyForUser.logo, function(dataUri: any) {
              let doc: jsPDFWithSvg = new jsPDFWithSvg('portrait', 'mm', "A4") as jsPDFWithSvg

              if (companyForUser && order && order.company.id === companyForUser.id) {
                loadScript(settingsForCompany.template_current.delivery_note, false).then( (ev:any) => {
                  if (ev.status) {
                    // @ts-ignore
                    generatePDF(doc, autoTable, companyForUser, dataUri, order, settingsForCompany.colors)
                  }
                }).catch( error => {
                  console.error(error)
                })
              }
            })
            break;
        }
        setLoadIndicator('off')
        executeHideModalOrderExport()
      }, 1000)
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        legendInvalid('modal-order-download-container-validate-type-required')
        setLoadIndicator('off')
      })
    }
  }

  const loadScript = (url: string, async = true, type = "text/javascript") => {
    return new Promise((resolve, reject) => {
      try {
        const scriptElement = document.createElement("script")
        scriptElement.type = type
        scriptElement.async = async
        scriptElement.src = url

        scriptElement.addEventListener("load", () => {
          resolve({ status: true })
        })

        scriptElement.addEventListener("error", () => {
          reject({
            status: false,
            message: `${lang.labels.failedToLoadTheScript} ＄{url}`
          })
        })

        document.body.appendChild(scriptElement)
      } catch (error) {
        reject(error)
      }
    })
  }

  const handleChangeType = (item: string) => {
    setTypeToExport({...typeToExport, value: item, valid: true})
    legendValid('modal-order-download-container-validate-type-required')
  }

  const executeShowModalOrderExport = () => {
    modalShow('modal-order-download')
  }

  const executeHideModalOrderExport = () => {
    modalHide('modal-order-download')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function getDataUri(url: any, cb: any) {
    let image = new Image()

    image.setAttribute('crossOrigin', 'anonymous')

    image.onload = function () {
      let canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      let ctx = canvas.getContext('2d')
      ctx!!.fillStyle = '#FFFFFF'
      ctx!!.fillRect(0, 0, canvas.width, canvas.height)
      canvas.getContext('2d')!!.drawImage(image, 0, 0)
      cb(canvas.toDataURL('image/jpeg'))
    }

    image.src = url
  }

  function restartModal() {
    legendValidInvalidRestart('modal-order-download-container-validate-type-required')
    setTypeToExport({value: '', valid: false})
  }

  return (
    <>
      <button className="btn btn-light-primary mb-1 me-2" type="button" onClick={executeShowModalOrderExport}>
        <div className="d-flex align-items-center">
          <span className="svg-icon svg-icon-2 ms-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
              <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
              <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
            </svg>
          </span>
          {lang.labels.export}
        </div>
      </button>
      <div id="modal-order-download" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog mw-500px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="text-capitalize">{lang.labels.exportOrder}</h2>
            </div>
            <div className="modal-body">
              <div className="form">
                <div className="fv-row">
                  <label className="form-label required">{lang.labels.selectExportType}:</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              { typeToExport.valid
                                ?
                                <>
                                  {typeToExport.value === "file" && "Order File"}
                                  {typeToExport.value === "note" && "Delivery Note"}
                                </>
                                :
                                <>
                                  {lang.labels.selectOption}
                                </>
                              }
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            <li className={`select2-results__option select2-results__option--selectable ${typeToExport.value === "file" && "select2-results__option--selected"}`} role="option" aria-selected={typeToExport.value === "file"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => handleChangeType("file")}>{lang.labels.orderFile}</Dropdown.Item>
                            </li>
                            <li className={`select2-results__option select2-results__option--selectable ${typeToExport.value === "note" && "select2-results__option--selected"}`}  role="option" aria-selected={typeToExport.value === "note"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text fs-7 py-2" onClick={() => handleChangeType("note")}>{lang.labels.deliveryNote}</Dropdown.Item>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Legend property={typeToExport.valid.toString()}>
                    <div id="modal-order-download-container-validate-type-required" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="type" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                    </div>
                  </Legend>
                </div>
              </div>
            </div>
            <div className="modal-footer flex-center">
              <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalOrderExport}>{lang.labels.discard}</button>
              <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitOrderExport}>
                <span className="indicator-label">{lang.labels.export}</span>
                <span className="indicator-progress">
                  {lang.labels.pleaseWait}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppButtonOrderExport;
