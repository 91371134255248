import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCategory } from './Category';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { CategoryDA } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { modalShow } from '../../../../tools/modal.tool';
import { imageProduct } from '../../../../scripts/image.value.script';
import { languageDownloadValue } from '../../../../libraries/language.download.library';

import AppModalCategoryUpdate from '../../../../components/modal/ModalCategoryUpdate';
import AppModalCategoryUpdateProducts from '../../../../components/modal/ModalCategoryUpdateProducts';

import imageSearch from '../../../../assets/images/themes/image-search.png';

export interface CategoryViewPageProps {};

let errorResponse: Error, messageResponse: Message, categoryResponse: Category;

const CategoryViewPage: React.FunctionComponent<CategoryViewPageProps> = props => {
  const {setRoute, companyForUser, productsForCompany} = UseOutletContextCategory()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [category, setCategory] = useState<Category | undefined | null>(null)
  const [categoryValue, setCategoryValue] = useState({name: '', description: '', names: {en: '', fr: '', it: '', de: ''}})
  const [categoryValid, setCategoryValid] = useState({name: false, description: false, names: {en: true, fr: true, it: true, de: true}})
  const [categoryProducts, setCategoryProducts] = useState<string[] | undefined | null>([])

  const loadCategory = async (id_company: string, id_category: string) => {
    await CategoryDA.getCategory(id_company, id_category).then( (response) => {
      if (response.status === 200) {
        categoryResponse = response.data
        setCategory(categoryResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitCategoryDelete = async () => {
    if (companyForUser && category) {
      Swal.fire({
        title: 'You want to delete this category?',
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await CategoryDA.deleteCategories(companyForUser.id, [category.id]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              Swal.fire({
                title: `${messageResponse.message}!`,
                text: 'Updating company category list, redirecting to category list page.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/inventory/category/list')
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const executeShowModalCategoryUpdate = () => {
    if (category) {
      setCategoryValue({name: category.display_name, description: category.description, names: category.names})
      setCategoryValid({name: true, description: true, names: {en: true, fr: true, it: true, de: true}})

      modalShow('modal-category-update')
    }
  }

  const executeShowModalCategoryUpdateProducts = () => {
    if (category) {
      let tempCategoryProducts = category.products.map((item) => { return item.id })

      setCategoryProducts(tempCategoryProducts)
      modalShow('modal-category-update-products')
    }
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadCategory(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: 'Categories', branch: 'View Category'}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { category
        ?
        <div className="d-flex flex-column flex-lg-row">
          <div className="w-100 flex-lg-row-auto w-lg-300px mb-7 me-7 me-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2 className="text-uppercase">
                    {category.display_name}
                  </h2>
                </div>
                <div className="card-toolbar">
                  <Dropdown>
                    <Dropdown.Toggle variant="btn btn-sm btn-light-primary btn-icon">
                      <span className="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect x="10" y="10" width="4" height="4" rx="2" fill="black"></rect>
                          <rect x="17" y="10" width="4" height="4" rx="2" fill="black"></rect>
                          <rect x="3" y="10" width="4" height="4" rx="2" fill="black"></rect>
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary w-200px py-4 fs-6 fw-bold">
                      <Dropdown.Item bsPrefix="menu-item px-5" onClick={executeShowModalCategoryUpdate}>
                        <p className="menu-link fw-bold px-5 m-0">Edit Category</p>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-5" onClick={submitCategoryDelete}>
                        <p className="menu-link fw-bold px-5 m-0">Delete Category</p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="card-body pt-4">
                <div className="d-flex fs-4 fw-bold">Description</div>
                <div className="separator separator-dashed my-2"></div>
                <div className="mt-3 mb-10 fs-6">
                  <div className="fw-bold text-gray-800 text-justify">{category.description}</div>
                </div>
                <div className="d-flex fs-4 fw-bold">Names</div>
                <div className="separator separator-dashed my-2"></div>
                <div className="mt-3 fs-6">
                  { Object.keys(category.names).map (( (item, index) => { return (
                    <div key={index} className={`pb-3 ${category.names[item as keyof typeof category.names].length === 0 && "d-none"}`}>
                      <div className="fw-bold">{category.names[item as keyof typeof category.names]}</div>
                      <div className="fs-7 text-gray-600">{languageDownloadValue(lang, item.toUpperCase())}</div>
                    </div>
                  )}))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header mt-6">
                <div className="card-title flex-column">
                  <h3 className="mb-1">Products of the category</h3>
                  <div className="fs-6 fw-bold text-muted">
                    Total
                    <span className="mx-1 fw-bolder text-gray-700">{category.products.length}</span>
                    products in this category
                  </div>
                </div>
                <div className="card-toolbar">
                  <button type="button" className="btn btn-light-primary" onClick={executeShowModalCategoryUpdateProducts}>
                    <span className="svg-icon svg-icon-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black"></path>
                        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                      </svg>
                    </span>
                    Edit product listing
                    </button>
                </div>
              </div>
              <div className="card-body d-flex flex-column">
                { category.products.length > 0
                  ?
                  <div id="container-category-products" className="flex-column-fluid scroll-y">
                    { category.products.map (( (item, index) => { return (
                      <div key={index} className="d-flex align-items-center position-relative mb-5 me-1">
                        <div className="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
                        <div className="symbol symbol-35px ms-5">
                          <span className="symbol-label">
                            <img src={imageProduct(item.photos[0])} alt="photography" className="w-100 h-100" />
                          </span>
                        </div>
                        <div className="fw-bold ms-5">
                          <div className="fs-5 fw-bolder text-dark">{item.display_name}</div>
                          <div className="fs-7 text-muted">
                            Variants:
                            <span className="ms-1">{item.variants.length}</span>
                          </div>
                        </div>
                        <Link to={"/app/inventory/product/update/" + item.id} className="btn btn-light bnt-active-light-primary btn-sm ms-auto">Edit</Link>
                      </div>
                    )}))}
                  </div>
                  :
                  <div className="d-flex flex-column flex-center">
                    <img src={imageSearch} className="mw-250px"  alt="null" />
                    <div className="fs-1 fw-bolder text-dark mb-4">No items found.</div>
                    <div className="fs-6">Start adding new products to this category!</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>                                                                       
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalCategoryUpdate companyForUser={companyForUser} category={category} categoryValue={categoryValue} categoryValid={categoryValid} categories={null} setCategory={setCategory} setCategoryValue={setCategoryValue} setCategoryValid={setCategoryValid} setCategories={null}></AppModalCategoryUpdate>
      <AppModalCategoryUpdateProducts companyForUser={companyForUser} productsForCompany={productsForCompany} category={category} categoryProducts={categoryProducts} setCategoryProducts={setCategoryProducts} setCategory={setCategory}></AppModalCategoryUpdateProducts>
    </div>
  )
};

export default CategoryViewPage;
