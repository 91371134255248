import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { User } from '../models/user.model';
import { Company } from '../models/company.model';
import { Currency } from '../models/currency.model';
import { Token } from '../services/token.service';

export class CompanyDL {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getCompany(api_key: string, id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/companies/${id_company}`
    let company: Company | null = {
      id: '',
      uuid: '',
      name: '',
      logo: '',
      email: '',
      phone: '',
      website: '',
      vat_number: '',
      register_number: '',
      language: '',
      qr_type: '',
      address: {
        street: '',
        number: '',
        additional: '',
        administrative_area: '',
        city: '',
        zip_code: '',
        country: ''
      },
      currency: {
        code: '',
        name: '',
        symbol: ''
      },
      users: []
    }
    let token = Token.getToken()
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.get(url, token).then(response => { company = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (company && company!!.id === id_company) {
      this.dataResponse = company

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {

      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getCompanyNonUsers(api_key: string, id_company: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/users/invitation/${id_company}`
    let token = Token.getToken()
    let nonUsers: any | null = {
      id: '',
      users: []
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse  = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { nonUsers = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (nonUsers && nonUsers!!.id === id_company) {
      this.dataResponse = nonUsers

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {

      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postCompany(api_key: string, name: string, logo: string, street: string, number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, email: string, phone: string, website: string, vat_number: string, register_number: string, currency: Currency, language: string, qr_type: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/companies`
    let token = Token.getToken()
    let company: any | null | {
      authorization: '',
      data: {}
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    const dataCompany = new FormData();
    dataCompany.append('logo', logo)
    dataCompany.append('name', name)
    dataCompany.append('phone', phone)
    dataCompany.append('email', email)
    dataCompany.append('website', website)
    dataCompany.append('vat_number', vat_number)
    dataCompany.append('register_number', register_number)
    dataCompany.append('street', street)
    dataCompany.append('additional', additional)
    dataCompany.append('number', number)
    dataCompany.append('administrative_area', administrative_area)
    dataCompany.append('city', city)
    dataCompany.append('zip_code', zip_code)
    dataCompany.append('country', country)
    dataCompany.append('currency_name', currency.name)
    dataCompany.append('currency_code', currency.code)
    dataCompany.append('currency_symbol', currency.symbol)
    dataCompany.append('language', language)
    dataCompany.append('qr_type', qr_type)

    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataCompany, token)
    .then(response => { company = response.data.company})
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (company && company.authorization.length > 0) {
      this.dataResponse = company

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putCompany(api_key: string, id_company: string, name: string, logo: any, street: string, number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, email: string, phone: string, website: string, vat_number: string, register_number: string, currency: Currency, language: string, qr_type: string): Promise<ApiResponse>  {
    let url = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/companies/${id_company}`
    let responseResponse: Message | null = {
      message: ''
    }
    let token = Token.getToken()
    let error: Error | null = {
      code: 0,
      message: ''
    }

    const dataCompany = new FormData();
    dataCompany.append('logo', logo)
    dataCompany.append('name', name)
    dataCompany.append('phone', phone)
    dataCompany.append('email', email)
    dataCompany.append('website', website)
    dataCompany.append('vat_number', vat_number)
    dataCompany.append('register_number', register_number)
    dataCompany.append('street', street)
    dataCompany.append('additional', additional)
    dataCompany.append('number', number)
    dataCompany.append('administrative_area', administrative_area)
    dataCompany.append('city', city)
    dataCompany.append('zip_code', zip_code)
    dataCompany.append('country', country)
    dataCompany.append('currency_name', currency.name)
    dataCompany.append('currency_code', currency.code)
    dataCompany.append('currency_symbol', currency.symbol)
    dataCompany.append('language', language)
    dataCompany.append('qr_type', qr_type)

    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataCompany, token).then(response => { responseResponse= response.data  })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (responseResponse) {
      this.dataResponse = responseResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putCompanyInvitation(api_key: string, id_company: string, id_user: string, comment: string): Promise<ApiResponse> {
    let url = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/companies/${id_company}/invitation/${id_user}`
    let userInvitationResponse: any | null = null
    let user: User | null = null
    let token = Token.getToken()
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let dataInvitation = {
      comment
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataInvitation, token).then(response => { userInvitationResponse = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (userInvitationResponse) {
      userInvitationResponse.users.map((item: any) => {
        if(item.id === id_user){
          user = item
        }
      })

      this.dataResponse = user

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putCompanyInvitationReply(api_key: string, id_company: string, id_user: string, id_notification: string, reply: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/notifications/${id_notification}`
    let token = Token.getToken()
    let messageResponse: any | null = {
      authorization: '',
      data: ''
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }
    let messagesResponse = ['Successfully accepted company','Successfully rejected company']

    const dataUpdateCompany = {
      id_company: id_company,
      id_user: id_user,
      reply: reply
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataUpdateCompany, token).then(response => { messageResponse = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messagesResponse.includes(messageResponse.data)) {
      this.dataResponse = messageResponse
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
