import axios from 'axios';
import { format } from 'date-fns';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { Invoice } from '../models/invoice.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { Unit } from '../models/unit.model';
import { Reminder } from '../models/reminder.model';
import { Token } from '../services/token.service';

export class InvoiceDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getInvoices(id_company: string, start_date: string, end_date: string, contact_id: string, status: string, expiration: string, validity: string, payment: string, order_direction: string, order_by: string, group_by: string): Promise<ApiResponse> {
    const currentDate = new Date()

    const startOfDay = new Date(currentDate)
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999);
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices?start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&order_direction=${order_direction}&group_by=${group_by}&order_by=${order_by}&status=${status}&expiration=${expiration}&validity=${validity}&payment=${payment}&startOfDay=${startOfDay}&endOfDay=${endOfDay}`
    let token = Token.getToken()
    let invoicesForCompany

    if (group_by !== '') {
      invoicesForCompany = {
        id_company: '',
        filter: {
          start_date: '',
          end_date: '',
          contact_id: '',
          validity: '',
          status: '',
          order_direction: '',
          order_by: '',
          group_by: ''
        },
        groups: []
      }
    }
    else {
      invoicesForCompany = {
        id_company: '',
        filter: {
          start_date: '',
          end_date: '',
          contact_id: '',
          validity: '',
          status: '',
          order_direction: '',
          order_by: '',
          group_by: ''
        },
        invoices: []
      }
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { invoicesForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoicesForCompany.id_company === id_company) {
      this.dataResponse = invoicesForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getInvoice(id_company: string, id_invoice: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}`
    let token = Token.getToken()
    let invoice: Invoice | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {
      this.dataResponse = invoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postInvoice(id_company: string, id_customer: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, qr_type: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: Unit }[] }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }

    const dataInvoice = {
      concerning,
      signatures,
      company: company_data,
      valid_until,
      currency,
      bank_account: id_treasury_account,
      customer: id_customer,
      requirements,
      created_date: date,
      language,
      qr_type,
      reference
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataInvoice, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully created invoice') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postInvoiceReference(id_company: string, id_order: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_order}`
    let token = Token.getToken()
    let invoice: Invoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, undefined, token).then(response => { invoice = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice!!.order.id === id_order) {
      this.dataResponse = invoice

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putInvoice(id_company: string, id_invoice: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, qr_type: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: Unit }[] }[], signatures: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataUpdateInvoice = {
      concerning: concerning,
      signatures: signatures,
      requirements: requirements,
      valid_until,
      language: language,
      qr_type: qr_type,
      currency,
      bank_account: id_treasury_account,
      created_date: date
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataUpdateInvoice, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated invoice') {
      this.dataResponse = messageResponse
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putInvoiceValidated(id_company: string, id_invoice: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/status`
    let token = Token.getToken()
    let invoice: Invoice | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, undefined, token).then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {
      this.dataResponse = invoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }


  public static async postInvoiceReminder(id_company: string, id_invoice: string, description: string, amount: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/reminders`
    let token = Token.getToken()
    let invoice: Invoice | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataReminder = {
      description,
      amount
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataReminder, token)
      .then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {

      this.dataResponse = invoice

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putInvoiceReminder(id_company: string, id_invoice: string, reminder_created_at: string, description: string, amount: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/reminders`
    let token = Token.getToken()
    let invoice: Invoice | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataReminder = {
      reminder_created_at,
      description,
      amount
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataReminder, token)
      .then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {
      this.dataResponse = invoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deleteInvoiceReminder(id_company: string, id_invoice: string, reminder_created_at: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/invoices/${id_invoice}/reminders`
    let token = Token.getTokenDataReminder(reminder_created_at)
    let invoice: Invoice | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.delete(url, token)
      .then(response => { invoice = response.data.invoice })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (invoice) {

      this.dataResponse = invoice

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
