import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Quote } from '../models/quote.model';
import { Company } from '../models/company.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { Unit } from '../models/unit.model';
import { Token } from '../services/token.service';

export class QuoteDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getQuotes(id_company: string, start_date: string, end_date: string, contact_id: string, status: string, expiration: string, order_direction: string, order_by: string, group_by: string): Promise<ApiResponse> {


    let currentDate = new Date()
    const startOfDay = new Date(currentDate)
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999);
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/quotes?start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&order_by=${order_by}&order_direction=${order_direction}&page&limit&group_by=${group_by}&status=${status}&expiration=${expiration}&startOfDay=${startOfDay}&endOfDay=${endOfDay}`
    let token = Token.getToken()
    let quotesForCompany
    if (group_by !== '') {
      quotesForCompany = {
        id_company: id_company,
        filter: {
          start_date: start_date,
          end_date: end_date,
          contact_id: contact_id,
          status: status,
          validity: expiration,
          order_direction: order_direction,
          order_by: order_by,
          group_by: group_by
        },
        groups: []
      }
    }
    else {
      quotesForCompany = {
        id_company: id_company,
        filter: {
          start_date: start_date,
          end_date: end_date,
          contact_id: contact_id,
          status: status,
          validity: expiration,
          order_direction: order_direction,
          order_by: order_by,
          group_by: group_by
        },
        quotes: []
      }
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { quotesForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (quotesForCompany.id_company === id_company) {
      this.dataResponse = quotesForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getQuote(id_company: string, id_quote: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/quotes/${id_quote}`
    let token = Token.getToken()
    let quote: Quote | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { quote = response.data.quote })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (quote!!.id === id_quote) {
      this.dataResponse = quote

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postQuote(id_company: string, id_customer: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: Unit }[] }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/quotes/`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }

    const dataQuote = {
      concerning,
      signatures,
      valid_until,
      company: company_data,
      customer: id_customer,
      requirements,
      reference,
      bank_account: id_treasury_account,
      currency,
      language,
      created_date: date,
    }

    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.post(url, dataQuote, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === "Successfully created quote") {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putQuote(id_company: string, id_quote: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], signatures: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/quotes/${id_quote}`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataUpdateQuote = {
      concerning: concerning,
      signatures: signatures,
      valid_until: valid_until,
      requirements: requirements,
      currency,
      created_date: date,
      bank_account: id_treasury_account,
      language
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.put(url, dataUpdateQuote, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated quote') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putQuoteStatus(id_company: string, id_quote: string, new_status: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/quotes/${id_quote}/status`
    let token = Token.getToken()
    let quote: Quote | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    const dataStatus = { status: new_status }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.put(url, dataStatus, token).then(response => { quote = response.data.quote })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (quote!!.id === id_quote) {
      this.dataResponse = quote
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async sendQuote(id_company: string, id_quote: string): Promise<ApiResponse> {
    let quote: Quote | null = null

    let error: Error | null = {
      code: 1030,
      message: 'Quote no found'
    }

    await axios.get('../../../../../database/quote.data.json').then(response => { quote = response.data }).catch(error => { alert(error) })

    if (quote!!.id === id_quote) {
      quote!!.status = 'pending'

      this.dataResponse = quote

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
