import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Contact } from '../models/contact.model';
import { Currency } from '../models/currency.model';
import { Token } from '../services/token.service';

export class ContactDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getContacts(id_company: string, name_contact: string, order_direction: string, country: string, type: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/contacts?type=${type}&country=${country}&order_direction=${order_direction}&name_contact=${name_contact}`
    let token = Token.getToken()
    let contactsForCompany: any | null = {
      id_company: '',
      filter: {
        name_contact: '',
        order_direction: '',
        country: '',
        type: ''
      },
      contacts: []
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token).then(response => { contactsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (contactsForCompany.id_company === id_company) {
      this.dataResponse = contactsForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getContact(id_company: string, id_contact: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/contacts/${id_contact}`
    let token = Token.getToken()
    let contactResponse: any | {
      type: '',
      data: {}
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { contactResponse = response.data.contact })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (contactResponse!!.data.id === id_contact) {
      this.dataResponse = contactResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postContact(id_company: string, type: string, uuid: string, name: string, first_name: string, last_name: string, logo: any, email: string, phone: string, website: string, vat_number: string, register_number: string, street: string, number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, currency: Currency, financial_entity: string, iban: string, bban: string, bic: string, language: string, qr_type: string, companies: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/contacts`
    let token = Token.getToken()
    let contact: Contact | null = null
    let error: Error | null = {
      code: 0,
      message: ""
    }

    iban = iban.trim()

    const dataContact = new FormData();
    dataContact.append('uuid', uuid)
    dataContact.append('type', type)
    dataContact.append('phone', phone)
    dataContact.append('email', email)
    dataContact.append('website', website)
    dataContact.append('street', street)
    dataContact.append('additional', additional)
    dataContact.append('number', number)
    dataContact.append('administrative_area', administrative_area)
    dataContact.append('city', city)
    dataContact.append('zip_code', zip_code)
    dataContact.append('country', country)
    dataContact.append('language', language)
    dataContact.append('qr_type', qr_type)
    dataContact.append('company', id_company)
    dataContact.append('account_name', financial_entity)
    dataContact.append('bic', bic)
    dataContact.append('bban', bban)
    dataContact.append('iban', iban)
    companies.forEach(id_company => {
      dataContact.append('other_companies', JSON.stringify(id_company))
    })

    if (type === 'person') {
      dataContact.append('first_name', first_name)
      dataContact.append('last_name', last_name)

    } else {
      dataContact.append('vat_number', vat_number)
      dataContact.append('register_number', register_number)
      dataContact.append('logo', logo)
      dataContact.append('name', name)
      dataContact.append('currency_name', currency.name)
      dataContact.append('currency_code', currency.code)
      dataContact.append('currency_symbol', currency.symbol)

    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataContact, token)
      .then(response => { contact = response.data.contact })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (contact) {
      this.dataResponse = contact
      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putContact(id_company: string, id_contact: string, type: string, name: string, first_name: string, last_name: string, logo: any, email: string, phone: string, website: string, vat_number: string, register_number: string, street: string, number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, currency: Currency, financial_entity: string, iban: string, bban: string, bic: string, language: string, qr_type: string, id_companies: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/contacts/${id_contact}`
    let token = Token.getToken()
    let contact: Contact | null = null
    iban = iban.trim()
    const dataContact = new FormData();
    dataContact.append('type', type)
    dataContact.append('phone', phone)
    dataContact.append('email', email)
    dataContact.append('website', website)
    dataContact.append('street', street)
    dataContact.append('additional', additional)
    dataContact.append('number', number)
    dataContact.append('administrative_area', administrative_area)
    dataContact.append('city', city)
    dataContact.append('zip_code', zip_code)
    dataContact.append('country', country)
    dataContact.append('language', language)
    dataContact.append('qr_type', qr_type)
    dataContact.append('account_name', financial_entity)
    dataContact.append('bic', bic)
    dataContact.append('bban', bban)
    dataContact.append('iban', iban)

    if (type === 'person') {
      dataContact.append('first_name', first_name)
      dataContact.append('last_name', last_name)

    } else {
      dataContact.append('vat_number', vat_number)
      dataContact.append('register_number', register_number)
      dataContact.append('logo', logo)
      dataContact.append('name', name)
      dataContact.append('currency_name', currency.name)
      dataContact.append('currency_code', currency.code)
      dataContact.append('currency_symbol', currency.symbol)
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.put(url, dataContact, token).then(response => { contact = response.data.contact })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (contact!!.data.id === id_contact) {
      this.dataResponse = contact

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putContactExtend(id_company: string, id_contact: string, companies: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/contacts/${id_contact}/changes/companies`
    let config = Token.getToken()
    let contact: Contact | null = null
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }


    let data = {
      other_companies: companies
    }
    await axios.put(url, data, config)
      .then(response => { contact = response.data.contact })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (contact) {
      this.dataResponse = contact
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deleteContacts(id_company: string, id_contacts: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/contacts`
    let config = Token.getTokenDataContact(id_contacts)
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.delete(url, config).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully deleted contact') {
      this.dataResponse = messageResponse
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error
      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
