import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { format } from 'date-fns';

import { UseOutletContextQuote } from './Quote';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { QuoteDA } from '../../../../services/quote.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Unit } from '../../../../models/unit.model';
import { Address } from '../../../../models/address.model';
import { legendInvalid } from '../../../../tools/legend.data.entry.tool';
import { validateAttributes } from '../../../../scripts/validate.attributes.script';

import SaleRecordCreate from '../card/SaleRecordCreate';
import SaleFormRequirements from '../card/SaleFormRequirements';
import SaleFormSignatures from '../card/SaleFormSignatures';

export interface QuoteCreatePageProps {};

let errorResponse: Error, messageResponse: Message;

const QuoteCreatePage: React.FunctionComponent<QuoteCreatePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, contactsForCompany, treasuryAccountsForCompany, productsForCompany} = UseOutletContextQuote()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [customer, setCustomer] = useState({value: '', object: null as any, valid: false})
  const [concerning, setConcerning] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false})
  const [validUntil, setValidUntil] = useState({value: '', valid: false})
  const [paymentDeadline, setPaymentDeadline] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [treasuryAccount, setTreasuryAccount] = useState({value: '', valid: false})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [discountType, setDiscountType] = useState({value: '', valid: false})
  const [discountValue, setDiscountValue] = useState({value: '', valid: false})
  const [requirements, setRequirements] = useState({list: false, value: [] as {type: string, description: string, contents: {detail: string, detail_reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: Unit}[]}[], valid: [] as {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean}[]}[]})
  const [signatures, setSignatures] = useState({list: true, value: [] as string[], valid: [] as boolean[]})

  const submitQuoteCreate = async () => {
    setLoadIndicator('on')

    let requerimentsIsValid: boolean = requirements.valid.every((item) => validateAttributes(item))
    let signaturesIsValid: boolean = signatures.valid.every((item) => (item === true))

    if (companyForUser && customer.valid && concerning.valid && date.valid && validUntil.valid && currency.valid && treasuryAccount.valid && language.valid && requirements.list && signatures.list && requerimentsIsValid && signaturesIsValid) {
      let validUntilDate: string = format((new Date(`${validUntil.value}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)
      let companyData: {uuid: string, name: string, logo: string, address: Address} = {uuid: companyForUser.uuid, name: companyForUser.name, logo: companyForUser.logo, address: companyForUser.address}
      let requirementList: {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: Unit}[]}[] = requirements.value.map((item) => { return {
        type: item.type,
        description: item.description,
        contents: item.contents.map((sub_item) => { return {
          detail: sub_item.detail,
          quantity: sub_item.quantity,
          price_unit: sub_item.price_unit,
          tax_included: sub_item.tax_included,
          tax_rate: sub_item.tax_rate,
          rebate_type: sub_item.rebate_type,
          rebate_value: sub_item.rebate_value,
          rebate_quantity: sub_item.rebate_quantity,
          unit: sub_item.unit
        }})
      }})

      await QuoteDA.postQuote(companyForUser.id, customer.value, concerning.value, date.value, validUntilDate, currency.object, treasuryAccount.value, language.value, requirementList, signatures.value, companyData, '').then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/sale/quote/list', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (customer.value.length === 0) {
          legendInvalid('container-validate-customer-required')
        }
        if (concerning.value.length === 0) {
          legendInvalid('container-validate-concerning-required')
        }
        if (date.value.length === 0) {
          legendInvalid('container-validate-date-required')
        }
        if (validUntil.value.length === 0) {
          legendInvalid('container-validate-valid-until-required')
        }
        if (currency.value.length === 0) {
          legendInvalid('container-validate-currency-required')
        }
        if (treasuryAccount.value.length === 0) {
          legendInvalid('container-validate-treasury-account-required')
        }
        if (language.value.length === 0) {
          legendInvalid('container-validate-language-required')
        }
        if (language.value.length === 0) {
          legendInvalid('container-validate-discount-required')
        }
        if (requirements.value.length === 0) {
          legendInvalid('container-validate-requirements-required')
        }
        if (!requerimentsIsValid) {
          for (let i = 0; i < requirements.value.length; i++) {
            if (requirements.value[i].contents.length === 0) {
              legendInvalid('container-validate-requirement-contents-required-' + i)
            } else {
              for (let j = 0; j < requirements.value[i].contents.length; j++) {
                if (requirements.value[i].contents[j].detail.length === 0) {
                  legendInvalid('container-validate-requirement-content-detail-required-' + i + j)
                }
                if (requirements.value[i].contents[j].unit.id.length === 0) {
                  legendInvalid('container-validate-requirement-content-unit-required-' + i + j)
                }
                if (requirements.value[i].contents[j].quantity.length === 0) {
                  legendInvalid('container-validate-requirement-content-quantity-required-' + i + j)
                }
                if (requirements.value[i].contents[j].price_unit.length === 0) {
                  legendInvalid('container-validate-requirement-content-price-required-' + i + j)
                }
                if (requirements.value[i].contents[j].tax_rate.length === 0) {
                  legendInvalid('container-validate-requirement-content-tax-required-' + i + j)
                }
                if ((requirements.value[i].contents[j].rebate_type === 'percentage' || requirements.value[i].contents[j].rebate_type === 'fixed') && (requirements.value[i].contents[j].rebate_value.length === 0 || requirements.value[i].contents[j].rebate_quantity.length === 0)) {
                  legendInvalid('container-validate-requirement-content-rebate-required-' + i + j)
                }
              }
            }
          }
        }
        if (!signaturesIsValid) {
          for (let i = 0; i < signatures.value.length; i++) {
            if (signatures.value[i].length === 0) {
              legendInvalid('container-validate-signature-required-' + i)
            }
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.quotes, branch: lang.labels.createQuote}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && settingsForCompany && contactsForCompany && treasuryAccountsForCompany && productsForCompany
        ?
        <div className="form">
          <SaleRecordCreate sale="quote" customer={customer} concerning={concerning} date={date} validUntil={validUntil} paymentDeadline={paymentDeadline} currency={currency} treasuryAccount={treasuryAccount} language={language} qrType={null} discountType={discountType} discountValue={discountValue} setCustomer={setCustomer} setConcerning={setConcerning} setDate={setDate} setValidUntil={setValidUntil} setPaymentDeadline={setPaymentDeadline} setCurrency={setCurrency} setTreasuryAccount={setTreasuryAccount} setLanguage={setLanguage} setQrTtype={null} setDiscountType={setDiscountType} setDiscountValue={setDiscountValue}></SaleRecordCreate>
          <SaleFormRequirements currency={currency.object} requirements={requirements} setRequirements={setRequirements}></SaleFormRequirements>
          <SaleFormSignatures signatures={signatures} setSignatures={setSignatures}></SaleFormSignatures>
          <div className="d-flex flex-stack flex-wrap flex-md-nowrap bg-light-primary border border-primary border-dashed rounded h-100 p-5">
            <div className="notice d-flex align-items-center mb-5 mb-md-0 me-0 me-md-5">
              <span className="svg-icon svg-icon-3tx svg-icon-primary me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black" />
                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                </svg>
              </span>    
              <div className="d-block">
                <span className="d-block text-start text-dark fw-bolder fs-5">{lang.labels.processOfCreatingQuoteCompleted}</span>
                <span className="text-muted text-justify text-gray-600 fw-bold">
                  {lang.labels.clickOn}
                  <span className="fw-bolder text-primary mx-1 text-lowercase">{lang.labels.createQuote}</span>
                  {lang.labels.ifYouWantToSaveTheNewQuote}
                </span>
              </div>
            </div>
            <button className="btn btn-lg btn-primary fw-bolder w-100 w-md-300px" type="button" data-kt-indicator={loadIndicator} onClick={submitQuoteCreate}>
              <span className="indicator-label text-uppercase text-nowrap">{lang.labels.createQuote}</span>
              <span className="indicator-progress">
              {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      
    </div>
  )
}

export default QuoteCreatePage;
