import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { QuoteDA } from '../../services/quote.service';
import { NotificationDL } from '../../services/notification.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { Service } from '../../models/service.model';
import { Company } from '../../models/company.model';
import { Quote } from '../../models/quote.model';
import { Legend } from '../../styles/legend.style';
import { legendInvalid, legendValidInvalidRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { toastError, toastSuccess } from '../../tools/toast.tool';
import { evaluateLegendValidateRequired } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

interface AppModalQuoteNotificationProps {
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  quote: Quote | undefined | null,
  setQuote: Dispatch<SetStateAction<Quote | undefined | null>>
};

let errorResponse: Error, messageResponse: Message, quoteResponse: Quote;

const AppModalQuoteNotification: React.FunctionComponent<AppModalQuoteNotificationProps> = ({serviceForUser, companyForUser, quote, setQuote}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [comment, setComment] = useState({value: '', valid: false})

  const submitQuoteStatus = async () => {
    setLoadIndicator('on')

    if (serviceForUser && companyForUser && quote && comment.valid) {
      let statusQuote: string = 'rejected'

      await QuoteDA.putQuoteStatus(companyForUser.id, quote.id, statusQuote).then( async (response) => {
        if (response.status === 200) {
          quoteResponse = response.data

          if (quote.customer.is_user) {
            let typeNotification: string = 'activity'
            let descriptionNotification: string = 'quote evaluation'
            let responseNotification: string = 'reject'

            await NotificationDL.postNotification(serviceForUser.api_key, typeNotification, descriptionNotification, quote.customer.data.uuid, comment.value, responseNotification, quoteResponse, null).then( (response) => {              
              setQuote(quoteResponse)

              if (response.status === 201) {
                messageResponse = response.data
                toastSuccess(getMessage(messageResponse.message, lang.code))
              } else {
                errorResponse = response.data
                toastError(getError(errorResponse.code, lang.code))
              }

              Swal.fire({
                title: lang.labels.successfullyUpdatedStatus,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
                executeHideModalQuoteNotification()
              })
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          } else {
            setQuote(quoteResponse)

            Swal.fire({
              title: lang.labels.successfullyUpdatedStatus,
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalQuoteNotification()
            })
          }
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (comment.value.length === 0) {
          legendInvalid('modal-quote-notification-container-validate-comment-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeComment = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setComment({...comment, value: event.target.value})
  }

  const validateComment = () => {
    evaluateLegendValidateRequired(expressions.text, comment, setComment, 'modal-quote-notification-container-validate-comment-valid', 'modal-quote-notification-container-validate-comment-required')
  }

  const executeHideModalQuoteNotification = () => {
    modalHide('modal-quote-notification')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidRestart('modal-quote-notification-container-validate-comment-valid')
    legendValidInvalidRestart('modal-quote-notification-container-validate-comment-required')
    setComment({value: '', valid: false})
  }

  return (
    <div id="modal-quote-notification" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.quoteNotification}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="text-center fs-6 mb-5">
                <h5>{lang.labels.youWantToChangeQuoteStatusRejected}</h5>
                <label>{lang.labels.youWillNotBeAbleToUndo}</label>
              </div>
              <div className="fv-row">
                <label className="form-label required">{lang.labels.message}</label>
                <TextareaAutosize className="form-control form-control-solid" name="comment" minRows={2} maxRows={3} value={comment.value} onChange={handleChangeComment} onKeyUp={validateComment} onBlur={validateComment} />
                <Legend property={comment.valid.toString()}>
                  <div id="modal-quote-notification-container-validate-comment-valid" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="comment" data-validator="regexp">{lang.labels.theValueIsNotAValid}</div>
                  </div>
                  <div id="modal-quote-notification-container-validate-comment-required" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="comment" data-validator="notEmpty">{lang.labels.theValueIsRequired}</div>
                  </div>
                </Legend>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitQuoteStatus}>
              <span className="indicator-label">{lang.labels.yesReject}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalQuoteNotification}>{lang.labels.noCancel}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalQuoteNotification;
