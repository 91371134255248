import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { User } from '../models/user.model';
import { Quote } from '../models/quote.model';
import { Order } from '../models/order.model';
import { Token } from '../services/token.service';


export class NotificationDL {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getNotifications(api_key: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/notifications`
    let token = Token.getToken()
    let notificationsForUser: any | null = {
      id_user: '',
      notifications: []
    }
    let error: Error = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }

    await axios.get(url, token)
    .then(response => { notificationsForUser = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (notificationsForUser && notificationsForUser.id_user.length > 0) {
      this.dataResponse = notificationsForUser
      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postNotification(api_key: string, type: string, description: string, uuid_user: string, comment: string, response: string, quote: Quote | null, order: Order | null): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/notifications`
    let token = Token.getToken()
    let users: User[] | null = []

    let message: Message | null = {
      message: 'Successfully created notification'
    }

    let error: Error | null = {
      code: 1026,
      message: 'Invalid id of identificador'
    }

    const notificationData = {
      type: type,
      description: description,
      user: uuid_user,
      comment: comment,
      response: response,
      quote: quote
    }
    //await axios.post(url, notificationData, token).then(response => { message = response.data }).catch(error => { alert(error) })

    if (message) {
      this.dataResponse = message

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putNotification(api_key: string, id_notification: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_LOGIN_PORT}${api_key}/notification/${id_notification}/view`
    let token = Token.getToken()
    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse  = {
      id: 0,
      message: ""
    }
    await axios.put(url, undefined, token).then(response => { messageResponse = response.data })
    .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated notification') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
