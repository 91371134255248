import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { ContactDA } from '../../services/contact.service';
import { Error } from '../../models/error.model';
import { User } from '../../models/user.model';
import { Company } from '../../models/company.model';
import { Contact } from '../../models/contact.model';
import { modalHide } from '../../tools/modal.tool';
import { imageCompany } from '../../scripts/image.value.script';

interface AppModalContactAddProps {
  userLoggedIn: User | undefined | null,
  companyForUser: Company | undefined | null,
  contact: Contact | undefined | null,
  contacts: Contact[] | undefined | null,
  selectedCompanies: string[] | undefined | null,
  setContact: Dispatch<SetStateAction<Contact | undefined | null>>,
  setContacts: Dispatch<SetStateAction<Contact[] | undefined | null>> | null,
  setCompaniesOfContact: Dispatch<SetStateAction<Company[] | undefined | null>> | null,
  setSelectedCompanies: Dispatch<SetStateAction<string[]>>,
  loadContactsForCompany: Function | null
};

let errorResponse: Error, contactResponse: Contact;

const AppModalContactAdd: React.FunctionComponent<AppModalContactAddProps> = ({userLoggedIn, companyForUser, contact, contacts, selectedCompanies, setContact, setContacts, setCompaniesOfContact, setSelectedCompanies, loadContactsForCompany}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitContactUpdate = async () => {
    setLoadIndicator('on')

    if (userLoggedIn && companyForUser && contact && selectedCompanies) {
      await ContactDA.putContactExtend(companyForUser.id, contact.data.id, selectedCompanies).then( (response) => {
        if (response.status === 200) {
          contactResponse = response.data

          if (typeof loadContactsForCompany === 'function' && contacts && setContacts) {
            let tempContacts = contacts.map((temp_item) => {
              if (temp_item.data.id === contact.data.id) {
                temp_item = contactResponse
              }
              return temp_item
            })

            setContacts(tempContacts)
            loadContactsForCompany(companyForUser.id)
          } else {
            setContact(contactResponse)

            if(setCompaniesOfContact) {
              let tempCompanies: Company[] = userLoggedIn.companies.filter(temp_item => selectedCompanies.includes(temp_item.id))
              setCompaniesOfContact(tempCompanies)
            }
          }

          Swal.fire({
            title: lang.labels.successfullyUpdatedContact,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalContactAdd()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeCompanies = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (selectedCompanies) {
      if (selectedCompanies.includes(event.target.value)) {
        setSelectedCompanies(selectedCompanies.filter((item) => (item !== event.target.value)))
      } else {
        setSelectedCompanies([...selectedCompanies, event.target.value])
      }
    }
  }

  const executeHideModalContactAdd = () => {
    modalHide('modal-contact-update-companies')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    setSelectedCompanies([])
  }

  return (
    <div id="modal-contact-update-companies" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-600px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addOrRemoveContactCompanies}</h2>
          </div>
          <div className="modal-body">
            { userLoggedIn && companyForUser && selectedCompanies
              ?
              <>
                <div className="text-justify text-gray-700 mb-5">
                  {lang.labels.theCurrentCompanyIs}
                  <strong className="mx-1">{companyForUser.name}</strong>
                  {lang.labels.youWillNotBeAbleToRemoveItFromThisCompany}
                </div>
                <div className="mh-350px hover-scroll-y px-3 border-top border-bottom">
                  { userLoggedIn.companies.map (( (item, index) => { return (
                    <div key={index}>
                      { companyForUser.id !== item.id &&
                        <label className="d-flex flex-stack cursor-pointer my-3">
                          <span className="d-flex align-items-center me-3">
                            <span className="symbol symbol-50px me-5">
                              <span className="symbol-label bg-light">
                                <img src={imageCompany(item.logo)} alt={lang.labels.logo} className="w-75 h-75" />
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fs-6 fw-bolder">{item.name}</span>
                              <span className="text-muted">{item.website}</span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" name="list-company" value={item.id} checked={selectedCompanies.includes(item.id)} onChange={handleChangeCompanies} />
                          </span>
                        </label>
                      }
                    </div>
                  )}))}
                </div>
              </>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalContactAdd}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitContactUpdate}>
              <span className="indicator-label">{lang.labels.update}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalContactAdd;
