import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { Expense } from '../models/expense.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { Unit } from '../models/unit.model';
import { Token } from '../services/token.service';
import Cookies from 'universal-cookie';

export class ExpenseDA {
  static apiResponse: ApiResponse
  static dataResponse: any
  static cookies = new Cookies()

  public static async getExpenses(id_company: string, start_date: string, end_date: string, payment_method: string, validity: string, order_direction: string, order_by: string, group_by: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/expenses?start_date=${start_date}&payment_method=${payment_method}&end_date=${end_date}&order_direction=${order_direction}&group_by=${group_by}&order_by=${order_by}&validity=${validity}`
    let token = Token.getToken()
    let expensesForCompany
    if (group_by !== '') {
      expensesForCompany = {
        id_company: '',
        filter: {
          start_date: '',
          end_date: '',
          contact_id: '',
          validity: '',
          status: '',
          order_direction: '',
          order_by: '',
          group_by: ''
        },
        groups: []
      }
    }
    else {
      expensesForCompany = {
        id_company: '',
        filter: {
          start_date: '',
          end_date: '',
          contact_id: '',
          validity: '',
          status: '',
          order_direction: '',
          order_by: '',
          group_by: ''
        },
        expenses: []
      }
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { expensesForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })
    if (expensesForCompany.id_company === id_company) {
      this.dataResponse = expensesForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getExpense(id_company: string, id_expense: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/expenses/${id_expense}`
    let token = Token.getToken()
    let expense: Expense | null = null

    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }
    await axios.get(url, token)
      .then(response => { expense = response.data.expense })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (expense) {
      this.dataResponse = expense

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postExpense(id_company: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, payment_method: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], vouchers: { name: string, file: any }[], company_data: { uuid: string, name: string, logo: string, address: Address }): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/expenses`
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append("concerning", concerning)
    data.append("bank_account", id_treasury_account)
    data.append("payment_method", payment_method)
    data.append("created_date", date)
    data.append('currency', JSON.stringify(currency))
    data.append('company', JSON.stringify(company_data))
    vouchers.map((item) => {
      data.append("vouchers", item.file, item.name)
    })
    requirements.forEach(requirement => {
      data.append('requirements', JSON.stringify(requirement))
    })

    await axios.post(url, data, token)
      .then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully created expense') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putExpense(id_company: string, id_expense: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, payment_method: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: Unit }[] }[], vouchers: { name: string, file: any }[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/expenses/${id_expense}`
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    const data = new FormData();
    data.append("concerning", concerning)
    data.append("bank_account", id_treasury_account)
    data.append("created_date", date)
    data.append("payment_method", payment_method)
    data.append('currency', JSON.stringify(currency))
    vouchers.map((item) => {
      if (item.file instanceof File)
        data.append("vouchers", item.file, item.name)
      else
        data.append("array_url", item.file)
      data.append("array_names", item.name)
    })
    requirements.forEach(requirement => {
      data.append('requirements', JSON.stringify(requirement))
    })

    await axios.put(url, data, token)
      .then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully updated expense') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putExpenseValidated(id_company: string, id_expense: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/expenses/${id_expense}/status`
    let token = Token.getToken()
    let expense: Expense | null = null
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, undefined, token).then(response => { expense = response.data.expense })
      .catch(errorR => { errorResponse = errorR.response.data.error })
    if (expense) {
      this.dataResponse = expense

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deleteExpense(id_company: string, id_expense: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/expenses/${id_expense}`
    let token = Token.getToken()

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }

    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.delete(url, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === 'Successfully deleted expense') {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
