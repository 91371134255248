import Cookies from 'universal-cookie';

export class Token {
  static cookies = new Cookies()

  public static getToken() {
    return { headers: { Authorization: 'Bearer ' + this.cookies.get('token') } }
  }

  public static getTokenDataContact(contacts: string[]) {
    return { headers: { Authorization: 'Bearer ' + this.cookies.get('token') }, data: { contact: contacts } }
  }

  public static getTokenDataCategories(categories: string[]) {
    return { headers: { Authorization: 'Bearer ' + this.cookies.get('token') }, data: { categories } }
  }

  public static getTokenDataProducts(ids_products: string[]) {
    return { headers: { Authorization: 'Bearer ' + this.cookies.get('token') }, data: { ids_products } }
  }

  public static getTokenDataReminder(reminder_created_at: string) {
    return { headers: { Authorization: 'Bearer ' + this.cookies.get('token') }, data: { reminder_created_at } }
  }
}
