import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { User } from '../../../models/user.model';
import { Service} from '../../../models/service.model';
import { Company}  from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';

export interface ContactPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  serviceForUser: Service | undefined | null,
  companyForUser: Company | undefined | null,
  contactsForCompany: Contact[] | undefined | null,
  loadCompanyForUser: Function,
  loadContactsForCompany: Function
};

const ContactPage: React.FunctionComponent<ContactPageProps> = props => {
  const {setRoute, userLoggedIn, serviceForUser, companyForUser, contactsForCompany, loadCompanyForUser, loadContactsForCompany} = UseOutletContext()

  return (
    <Outlet context={{setRoute, userLoggedIn, serviceForUser, companyForUser, contactsForCompany, loadCompanyForUser, loadContactsForCompany}}></Outlet>
  );
}

export function UseOutletContextContact() {
  return useOutletContext<ContexType>()
};

export default ContactPage;
