import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Product } from '../models/product.model';
import { Contact } from '../models/contact.model';
import { Currency } from '../models/currency.model';
import { Token } from '../services/token.service';

import { extractBase64 } from '../scripts/extract.base.64.script';
import { Names } from '../models/names.model';
import { Unit } from '../models/unit.model';

export class ProductDA {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getProducts(id_company: string, name_product: string, category_id: string, order_direction: string, order_by: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/products?name=${name_product}&order_direction=${order_direction}&order_by=${order_by}&id_category=${category_id}`
    let token = Token.getToken()

    let productsForCompany: any | null = {
      id_company: id_company,
      filter: {
        name_product: "",
        category_id: "",
        order_direction: "",
        order_by: "",
      },
      products: []
    }

    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token)
      .then(response => { productsForCompany = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (productsForCompany.id_company === id_company) {
      this.dataResponse = productsForCompany

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async getProduct(id_company: string, id_product: string): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/products/${id_product}`
    let token = Token.getToken()

    let productResponse = ''
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ''
    }

    await axios.get(url, token).then(response => { productResponse = response.data.product })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (productResponse) {
      this.dataResponse = productResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async postProduct(id_company: string, display_name: string, names: Names, tags: string[], id_category: string, variants: { description: string, product_variant_code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: Unit, discount: { type: string, value: string, quantity: string }, photos: any[] }[]): Promise<ApiResponse> {

    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/products`
    let token = Token.getToken()

    let error: Error | null = {
      code: 0,
      message: ""
    }

    const dataProduct = new FormData();
    dataProduct.append('display_name', display_name)
    dataProduct.append('names[en]', names.en)
    dataProduct.append('names[fr]', names.fr)
    dataProduct.append('names[de]', names.de)
    dataProduct.append('names[it]', names.it)
    dataProduct.append('category_id', id_category)

    variants.forEach((variant, index) => {
      dataProduct.append(`variants[${index}][code]`, variant.product_variant_code);
      dataProduct.append(`variants[${index}][description]`, variant.description);
      dataProduct.append(`variants[${index}][size]`, variant.size);
      dataProduct.append(`variants[${index}][sku]`, variant.sku);
      dataProduct.append(`variants[${index}][codebar]`, variant.codebar);
      dataProduct.append(`variants[${index}][color]`, variant.color);
      dataProduct.append(`variants[${index}][price]`, variant.price);
      dataProduct.append(`variants[${index}][tax_rate]`, variant.tax_rate);
      dataProduct.append(`variants[${index}][unit][id]`, variant.unit.id);
      dataProduct.append(`variants[${index}][unit][type]`, variant.unit.type);
      dataProduct.append(`variants[${index}][unit][name]`, variant.unit.name);
      dataProduct.append(`variants[${index}][unit][symbol]`, variant.unit.symbol);
      dataProduct.append(`variants[${index}][discount][type]`, variant.discount.type);
      dataProduct.append(`variants[${index}][discount][value]`, variant.discount.value);
      dataProduct.append(`variants[${index}][discount][quantity]`, variant.discount.quantity);

      variant.photos.forEach((image, subIndex) => {
        if (image instanceof File) {
          dataProduct.append(`variants_photos`, image, `${variant.description}`);
        } else {
          dataProduct.append(`variants_string_url[${variant.description}][${subIndex}]`, image)
        }
      });
    });

    tags.forEach(tag => {
      dataProduct.append('tags[]', JSON.stringify(tag))
    })

    let messageResponse: Message | null = {
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.post(url, dataProduct, token)
      .then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === "Successfully created product") {

      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 201,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async putProduct(id_company: string, id_product: string, display_name: string, names: Names, tags: string[], id_category: string, variants: { description: string, product_variant_code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: Unit, discount: { type: string, value: string, quantity: string }, photos: any[] }[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/products/${id_product}`
    let token = Token.getToken()
    let error: Error | null = {
      code: 0,
      message: ""
    }

    const dataProduct = new FormData();
    dataProduct.append('display_name', display_name)
    dataProduct.append('names[en]', names.en)
    dataProduct.append('names[fr]', names.fr)
    dataProduct.append('names[de]', names.de)
    dataProduct.append('names[it]', names.it)
    dataProduct.append('category_id', id_category)

    variants.forEach((variant, index) => {

      dataProduct.append(`variants[${index}][code]`, variant.product_variant_code);
      dataProduct.append(`variants[${index}][description]`, variant.description);
      dataProduct.append(`variants[${index}][size]`, variant.size);
      dataProduct.append(`variants[${index}][sku]`, variant.sku);
      dataProduct.append(`variants[${index}][codebar]`, variant.codebar);
      dataProduct.append(`variants[${index}][color]`, variant.color);
      dataProduct.append(`variants[${index}][price]`, variant.price);
      dataProduct.append(`variants[${index}][tax_rate]`, variant.tax_rate);
      dataProduct.append(`variants[${index}][unit][id]`, variant.unit.id);
      dataProduct.append(`variants[${index}][unit][type]`, variant.unit.type);
      dataProduct.append(`variants[${index}][unit][name]`, variant.unit.name);
      dataProduct.append(`variants[${index}][unit][symbol]`, variant.unit.symbol);
      dataProduct.append(`variants[${index}][discount][type]`, variant.discount.type);
      dataProduct.append(`variants[${index}][discount][value]`, variant.discount.value);
      dataProduct.append(`variants[${index}][discount][quantity]`, variant.discount.quantity);

      variant.photos.forEach((image, subIndex) => {

        if (image instanceof File) {
          dataProduct.append(`variants_photos`, image, `${variant.description}`);
        } else {
          dataProduct.append(`variants_string_url[${variant.description}][${subIndex}]`, image)
        }
      });
    });

    tags.forEach(tag => {
      dataProduct.append('tags[]', JSON.stringify(tag))
    })

    let messageResponse: Message | null = {
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.put(url, dataProduct, token)
      .then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })

    if (messageResponse && messageResponse.message === "Successfully updated product") {

      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

  public static async deleteProducts(id_company: string, id_products: string[]): Promise<ApiResponse> {
    let url: string = `${process.env.REACT_APP_BACKEND_PORT}${id_company}/products`
    let token = Token.getTokenDataProducts(id_products)
    let productsForCompany: Product[] | null = []

    let messageResponse: Message | null = {
      message: ''
    }
    let error: Error | null = {
      code: 0,
      message: ''
    }
    let errorResponse = {
      id: 0,
      message: ""
    }

    await axios.delete(url, token).then(response => { messageResponse = response.data })
      .catch(errorR => { errorResponse = errorR.response.data.error })


    if (messageResponse && messageResponse.message === "Successfully deleted products") {
      this.dataResponse = messageResponse

      this.apiResponse = {
        status: 200,
        message: 'Success',
        data: this.dataResponse
      }
    } else {
      error = {
        code: errorResponse.id,
        message: errorResponse.message
      }
      this.dataResponse = error

      this.apiResponse = {
        status: 400,
        message: 'Bad request',
        data: this.dataResponse
      }
    }

    return this.apiResponse
  }

}
